import { useEffect, useState } from 'react';
import { getTransitioningSessionStatus } from 'App/Activity/ResourceActionHandler';
import {
	Session,
	UNKNOWN_STATE,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { getLaunchProgressOperationId } from 'App/Activity/Utility';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export const useTransitioningStatus = (session: Session, resource?: Resource) => {
	const { value: resourceContext } = useLoadableResourceContext();
	const context = useResourceManagerContext();
	const [status, setStatus] = useState<string>(UNKNOWN_STATE);
	useEffect(() => {
		const activeAction =
			context.activeSessionActions.find(
				action => action?.session.sessionId === session?.sessionId
			) ||
			context.activeMachineActions.find(
				action => action?.machine.machineId === session?.machineData?.machineId
			) ||
			context.activeLaunchActions.find(
				action => action?.session.sessionId === session?.sessionId
			);
		if (activeAction) {
			setStatus(getTransitioningSessionStatus(activeAction.action));
		} else if (resource) {
			const operationId = getLaunchProgressOperationId(
				resource.id,
				resourceContext.launchProgress
			);
			setStatus(
				operationId ? resourceContext.launchProgress[operationId].status : UNKNOWN_STATE
			);
		} else {
			setStatus(UNKNOWN_STATE);
		}
	}, [
		context.activeSessionActions,
		context.activeMachineActions,
		session,
		context.activeLaunchActions,
		resource,
		resourceContext.launchProgress,
	]);

	return status;
};
