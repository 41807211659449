import { useCallback, useEffect } from 'react';
import { AnalyticsEvent } from 'analytics';
import { ResourceType } from 'App/Tile/ResourceDetails';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { getAndResetShieldStateInformationForAnalytics } from 'Environment/BrowserExtension/browserExtensionBridge';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isCitrixChromeApp } from 'Environment/launchResource/device';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { isAdvancedWorkspaceResiliencyEnabled } from 'Workspace/advancedWorkspaceResiliency';
import { useBrowserExtension } from 'Workspace/BrowserExtension/useBrowserExtension';
import { useCasTelemetryAdapter } from 'Workspace/CAS/useCasTelemetryAdapter';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { getStoreUrl } from 'Workspace/SchemeCallHandler/schemeCallHandler';
import {
	IShieldHeartBeatMetadata,
	ShieldHealthCheckPayload,
} from 'Workspace/TelemetryEvents/shieldHealthCheck/ShieldHealthCheckPayload';
import { useUserContext } from 'Workspace/UserContext';

const LAST_CAS_HEARTBEAT_EVENT_TIMESTAMP_STORAGE_KEY = 'lastTelemetryHeartBeatEventsTime';
const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export const useCASHeartBeatTelemetry = () => {
	const resourceContext = useLoadableResourceContext();
	const browserExtensionContext = useBrowserExtension();
	const userContext = useUserContext();
	const { workspaceConfiguration } = useConfigurationContext();
	const CASEventPublisher = useCasTelemetryAdapter();
	const { userDetails, hasLoggedIn } = userContext;
	const isShieldPRDEnabled = useFeatureCanary(FeatureFlag.ShieldPRD);

	const createAndSendShieldHeartBeatEvent = useCallback(async () => {
		let isExtensionActive = false;
		try {
			isExtensionActive = (await browserExtensionContext.getExtensionConfiguration())
				.isActive;
		} catch (_e) {}
		const { leasingProperties } = browserExtensionContext;
		const resourcesInfo = getResourcesMetadataForShieldTelemetry(
			resourceContext.value.resources
		);
		const leasesInfo = await getLeasesStateForTelemetry();
		const metadata: IShieldHeartBeatMetadata = {
			extensionAvailable: isExtensionActive,
			storeConfigUrl: getStoreUrl(),
			userName: userDetails?.userDisplayName,
			shieldEnabled:
				isAdvancedWorkspaceResiliencyEnabled(workspaceConfiguration) &&
				!!leasingProperties?.resourceLeasingEnabled,
			...resourcesInfo,
			...leasesInfo,
		};
		const event: AnalyticsEvent =
			ShieldHealthCheckPayload.createHeartBeatPayload(metadata);
		CASEventPublisher.publishEvent(event);
	}, [
		CASEventPublisher,
		browserExtensionContext,
		resourceContext?.value?.resources,
		userDetails?.userDisplayName,
		workspaceConfiguration,
	]);

	useEffect(() => {
		if (
			!isShieldPRDEnabled ||
			resourceContext.loading ||
			userContext.loading ||
			IS_ON_PREM
		) {
			return undefined;
		}
		const checkAndSendTelemetry = async () => {
			const isLoggedIn = await hasLoggedIn();
			//checking userDisplayName for null because even if the user is logged in, userDisplayName can be null till updated context is received
			if (!isLoggedIn || userDetails?.userDisplayName === null) {
				return;
			}
			const lastEventTimestamp = getFromLocalStorage<number>(
				LAST_CAS_HEARTBEAT_EVENT_TIMESTAMP_STORAGE_KEY
			);
			const currentTime = Date.now();
			if (
				!lastEventTimestamp ||
				currentTime - lastEventTimestamp >= ONE_DAY_IN_MILLISECONDS
			) {
				setInLocalStorage(LAST_CAS_HEARTBEAT_EVENT_TIMESTAMP_STORAGE_KEY, currentTime);
				!environment.isNative &&
					!isCitrixChromeApp() &&
					(await createAndSendShieldHeartBeatEvent()); //Shield Hearbeat event for browser extension flow
			}
		};
		checkAndSendTelemetry();
		const intervalId = setInterval(checkAndSendTelemetry, ONE_DAY_IN_MILLISECONDS);
		return () => clearInterval(intervalId);
	}, [
		isShieldPRDEnabled,
		resourceContext.loading,
		userContext.loading,
		userDetails?.userDisplayName,
		hasLoggedIn,
		createAndSendShieldHeartBeatEvent,
	]);
};

interface IResourcesMetadata {
	totalApps: number;
	totalDesktops: number;
	leasableApps: number;
	leasableDesktops: number;
}

/**
 * Computes metadata for shield telemetry from a list of resources.
 *
 * @param resources - An array of Resource objects to be analyzed.
 * @returns An object containing the total number of apps, total number of desktops,
 * and the number of leasable apps.
 *
 * @property totalApps - The total number of applications in the resources.
 * @property totalDesktops - The total number of desktops in the resources.
 * @property leasableApps - The number of applications that support leasing.
 * @property leasableDesktops - The number of desktops that support leasing.
 */
function getResourcesMetadataForShieldTelemetry(
	resources: Resource[]
): IResourcesMetadata {
	return resources.reduce(
		(accumulatedResult, resource) => {
			const { type, clmetadata } = resource;
			if (type === ResourceType.Desktop) {
				accumulatedResult.totalDesktops++;
				if (clmetadata?.leasesupported) {
					accumulatedResult.leasableDesktops++;
				}
			} else {
				accumulatedResult.totalApps++;
				if (clmetadata?.leasesupported) {
					accumulatedResult.leasableApps++;
				}
			}
			return accumulatedResult;
		},
		{ totalApps: 0, totalDesktops: 0, leasableApps: 0, leasableDesktops: 0 }
	);
}
interface LeasesStateMetadata {
	clSyncTriggers: number;
	clSyncFailures: number;
	clSyncLastSuccess: number;
	totalLeaseFiles: number;
	totalExpiredFiles: number;
}

async function getLeasesStateForTelemetry(): Promise<LeasesStateMetadata> {
	try {
		const { result } = await getAndResetShieldStateInformationForAnalytics();
		return {
			clSyncTriggers: result?.clSyncTriggersCount ?? 0,
			clSyncFailures: result?.clSyncFailuresCount ?? 0,
			clSyncLastSuccess: result?.clSyncLastSuccessTime ?? 0,
			totalExpiredFiles: result?.totalExpiredFiles ?? 0,
			totalLeaseFiles: result?.totalLeaseFiles ?? 0,
		};
	} catch (_e) {
		// In case of any error, return default values.
	}
	return {
		clSyncTriggers: 0,
		clSyncFailures: 0,
		clSyncLastSuccess: 0,
		totalExpiredFiles: 0,
		totalLeaseFiles: 0,
	};
}
