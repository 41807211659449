import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, Checkbox, Link, MainRegion, Tooltip } from '@citrite/web-ui-component';
import {
	StyledBlock,
	StyledH1,
	StyledJustifiedBlock,
} from 'Components/Detection/Detection.styled';
import { CustomDownloadAppButton } from 'Components/Detection/DownloadAppButton';
import { platform } from 'Environment/launchResource/constants';
import {
	getCurrentPlatform,
	isMac,
	isMobileOrTabletDevice,
	isSafari,
} from 'Environment/launchResource/device';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { IpadPlatformSwitchButton } from './IpadPlatformSwitchButton';

interface Props {
	detect(): void;
	useHtml5(): void;
	alreadyInstalled(): void;
	showDownload(): void;
	isHtml5Enabled: boolean;
	disallowAlreadyInstalled: boolean;
	canDownloadWorkspace: boolean;
	downloadUrl?: string;
}

interface State {
	licenseAgreementAccepted: boolean;
	isUsingIpad: boolean;
	currentPlatform: platform;
}

export class Detect extends React.Component<Props, State> {
	public state: State = {
		licenseAgreementAccepted: false,
		isUsingIpad: !!getFromLocalStorage('userIsUsingAnIpad'),
		currentPlatform: getCurrentPlatform(),
	};

	private toggleLicenseAgreement = () => {
		this.setState(state => ({
			licenseAgreementAccepted: !state.licenseAgreementAccepted,
		}));
	};

	public componentDidMount() {
		this.props.detect();
	}

	private popover = () => {
		return (
			<Tooltip
				hoverTrigger={
					<Link.Primary>{t('Workspace:citrix_license_agreement')}</Link.Primary>
				}
			>
				<p>{t('Workspace:license_agreement_content_title')}</p>
				<p>{t('Workspace:license_agreement_content_text')}</p>
				<p>{t('Workspace:license_agreement_content_code')}</p>
			</Tooltip>
		);
	};

	private shouldShowPlatformChangeOption = () => {
		return isSafari() && (isMac() || this.state.isUsingIpad);
	};

	private shouldShowCustomDownloadLogo = () =>
		(this.state.isUsingIpad || isMobileOrTabletDevice()) && this.props.downloadUrl;

	public render() {
		return (
			<MainRegion>
				<StyledH1>{t('Workspace:detect_workspace_installed')}</StyledH1>
				<StyledBlock>
					<p>
						{t('Workspace:if_already_installed', {
							open: <strong>{t('Workspace:open')}</strong>,
							allow: <strong>{t('Workspace:allow')}</strong>,
							downloadMsg:
								this.props.canDownloadWorkspace &&
								t('Workspace:no_window_download', {
									download: <strong>{t('Workspace:download')}</strong>,
								}),
						})}
					</p>
				</StyledBlock>

				{this.props.canDownloadWorkspace && (
					<>
						<StyledJustifiedBlock>
							<Checkbox
								label={t('Workspace:i_agree_with_the', {
									popover: <this.popover />,
								})}
								checked={this.state.licenseAgreementAccepted}
								onChange={this.toggleLicenseAgreement}
							/>
							<Tooltip
								hoverTrigger={
									<div
										style={{
											cursor: this.state.licenseAgreementAccepted
												? 'pointer'
												: 'not-allowed',
										}}
									>
										{this.shouldShowCustomDownloadLogo() ? (
											<CustomDownloadAppButton
												href={this.props.downloadUrl}
												disabled={!this.state.licenseAgreementAccepted}
											/>
										) : (
											<Button.Primary
												style={{
													width: '100%',
													pointerEvents: this.state.licenseAgreementAccepted
														? 'inherit'
														: 'none',
												}}
												onClick={this.props.showDownload}
												disabled={!this.state.licenseAgreementAccepted}
											>
												{t('Workspace:download')}
											</Button.Primary>
										)}
									</div>
								}
								isDisabled={this.state.licenseAgreementAccepted}
							>
								<p>{t('Workspace:accept_terms_message')}</p>
							</Tooltip>
						</StyledJustifiedBlock>
					</>
				)}

				<StyledJustifiedBlock>
					<Button.TextPrimary onClick={this.props.detect}>
						{t('Workspace:detect_again')}
					</Button.TextPrimary>
					{this.props.isHtml5Enabled && (
						<Button.TextPrimary onClick={this.props.useHtml5}>
							{t('Workspace:use_browser')}
						</Button.TextPrimary>
					)}
					{!this.props.disallowAlreadyInstalled && (
						<Button.TextPrimary onClick={this.props.alreadyInstalled}>
							{t('Workspace:already_installed')}
						</Button.TextPrimary>
					)}
					{this.shouldShowPlatformChangeOption() && (
						<IpadPlatformSwitchButton isUsingIpad={this.state.isUsingIpad} />
					)}
					<Tooltip
						hoverTrigger={
							<Button.TextPrimary>{t('Workspace:security_details')}</Button.TextPrimary>
						}
					>
						<p>{t('Workspace:security_information_title')}</p>
						<p>{t('Workspace:security_information_text')}</p>
					</Tooltip>
				</StyledJustifiedBlock>
			</MainRegion>
		);
	}
}
