import * as React from 'react';
import { css } from 'aphrodite';
import { post } from '@citrite/http';
import { t } from '@citrite/translate';
import {
	hasEndpointService,
	hasFeatureCanary,
	PlatformDependencies,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { getErrorPageUrlForLanguage } from 'App/Common/routing';
import { parseConfiguration } from 'Components/parseXML';
import { ConfigurationContext } from 'Configuration/Context';
import { handleConfigurationDefaults } from 'Configuration/defaultConfigurationsHandler';
import { installFeatureFlagPackageProvider } from 'Configuration/featureFlagPackageProvider';
import {
	applyInteractiveConfigurationOverrides,
	installInteractiveConfigurationTooling,
} from 'Configuration/interactiveConfiguration.devtools';
import { hasUIProperty } from 'Environment/hasUIProperty';
import { isTrusted } from 'Environment/launchResource/chromeApp';
import { installLazyGetter } from 'javascript/interactiveTools';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { useAuthentication } from 'Login/useAuthentication';
import { container } from 'Workspace/DependencyManagement';
import { InitialLoading } from 'Workspace/InitialLoading';
import { styles } from './styles';

interface Props {
	authenticationHandler: ReturnType<typeof useAuthentication>;
}

type State = {
	loading: boolean;
	error?: any;
	configuration?: WorkspaceConfiguration;
};

const configurationEndpoint = 'Home/Configuration';
const embeddedConfigurationKey = 'ClientSettings';
const lastWSPConfigurationKey = 'WSUI-LastKnownConfiguration';
export const lastCachedWSPConfig: WorkspaceConfiguration =
	getFromLocalStorage<WorkspaceConfiguration>(lastWSPConfigurationKey);

class _ConfigurationProvider extends React.Component<Props, State> {
	public state: State = {
		loading: !lastCachedWSPConfig,
		configuration: lastCachedWSPConfig,
	};

	public constructor(props: Props) {
		super(props);
		container.registerSingleton(PlatformDependencies.WorkspaceConfiguration, {
			get: () => this.state.configuration,
			refresh: this.refresh,
			hasUIProperty: uiPropertyName =>
				hasUIProperty(this.state.configuration, uiPropertyName),
			hasFeatureCanary: featureCanary =>
				hasFeatureCanary(this.state.configuration, featureCanary),
			hasEndpointService: serviceName =>
				hasEndpointService(this.state.configuration, serviceName),
		});
		container.registerSingleton(PlatformDependencies._UNSTABLE_AuthenticationHandler, {
			handleAuthChallengeIfNecessary: header =>
				this.props.authenticationHandler.handleAuthChallengeIfNecessary(header),
			_UNSTABLE_ensureInitializedSession: () =>
				this.props.authenticationHandler._UNSTABLE_ensureInitializedSession(
					this.state.configuration
				),
		});
	}

	public render() {
		if (this.state.loading) {
			return <InitialLoading />;
		}

		if (this.state.error && this.state.error.name === 'not_trusted') {
			return (
				<div className={css(styles.notTrusted)}>
					<span>{t('Workspace:not_trusted')}</span>
				</div>
			);
		}

		return (
			<ConfigurationContext.Provider
				value={{
					workspaceConfiguration: this.state.configuration,
					refreshWorkspaceConfiguration: this.refresh,
				}}
			>
				{this.props.children}
			</ConfigurationContext.Provider>
		);
	}

	public componentDidMount = () => {
		this.addInteractiveOverrideSupport();
		this.initialize();
	};

	public componentWillUnmount = () => {
		container.unregister(PlatformDependencies.WorkspaceConfiguration);
		container.unregister(PlatformDependencies._UNSTABLE_AuthenticationHandler);
	};

	private initialize = () => {
		this.tryExtractEmbeddedConfiguration()
			.then(xml => this.parseConfiguration(xml))
			.then(config => handleConfigurationDefaults(config))
			.then(config => this.validateConfiguration(config))
			.catch(error => this.networkFallback(error))
			.then(config => applyInteractiveOverrides(config))
			.then(config => this.installFeatureFlagProvider(config))
			.then(config => this.handleConfigurationResult(config))
			.catch(error => this.redirectOnConfigurationError(error));
	};

	private refresh = () => {
		return this.requestConfigurationFromNetwork()
			.then(xml => this.parseConfiguration(xml))
			.then(config => handleConfigurationDefaults(config))
			.then(config => this.validateConfiguration(config))
			.catch(error => this.networkFallback(error))
			.then(config => applyInteractiveOverrides(config))
			.then(config => this.handleConfigurationResult(config))
			.catch(error => this.fallbackToLastValue(error))
			.catch(error => this.redirectOnConfigurationError(error));
	};

	private tryExtractEmbeddedConfiguration = () => {
		return window[embeddedConfigurationKey]
			? Promise.resolve(window[embeddedConfigurationKey] as string)
			: this.requestConfigurationFromNetwork();
	};

	private requestConfigurationFromNetwork = () => {
		return post<string>(configurationEndpoint);
	};

	private parseConfiguration = (configurationXML: string) =>
		parseConfiguration(configurationXML).clientSettings;

	private validateConfiguration = (configuration: WorkspaceConfiguration) => {
		if (!isTrusted(configuration)) {
			const error = new Error();
			error.name = 'not_trusted';
			throw error;
		}
		if (configuration) {
			setInLocalStorage(lastWSPConfigurationKey, configuration);
		}
		return configuration;
	};

	private installFeatureFlagProvider = (config: WorkspaceConfiguration) => {
		installFeatureFlagPackageProvider(() => this.state.configuration);
		return config;
	};

	private handleConfigurationResult = (configuration: WorkspaceConfiguration) => {
		this.setState(state => ({
			configuration: {
				...state.configuration,
				...configuration,
			},
			error: null,
			loading: false,
		}));
	};

	private redirectOnConfigurationError = (error: Error) => {
		logError(error);
		if (error.name !== 'not_trusted') {
			location.assign(getErrorPageUrlForLanguage('jserror'));
		}
		this.setState(state => ({
			...state,
			error,
			loading: false,
		}));
	};

	private fallbackToLastValue = (error: Error) => {
		if (this.state.configuration) {
			return;
		} else {
			throw error;
		}
	};

	private networkFallback = (error: Error) => {
		const configuration = getEmbeddedConfiguration();
		if (error.name === 'not_trusted' || !configuration) {
			throw error;
		}
		return configuration;
	};

	private addInteractiveOverrideSupport = () => {
		installLazyGetter('workspaceConfiguration', () => this.state.configuration);

		if (!IS_RELEASE) {
			installInteractiveConfigurationTooling(
				() => this.refresh(),
				() => this.state.configuration
			);
		}
	};
}

function applyInteractiveOverrides(configuration: WorkspaceConfiguration) {
	if (IS_RELEASE) {
		return configuration;
	}

	let overriddenConfig = configuration;
	try {
		overriddenConfig = applyInteractiveConfigurationOverrides(configuration);
	} catch (error) {
		console.error(error);
	}
	return overriddenConfig;
}

/**
 * Accessing configuration from context is preferred. This should only be used in edge cases.
 */
export function getEmbeddedConfiguration() {
	const windowConfiguration = window[embeddedConfigurationKey];
	const embeddedConfig = windowConfiguration
		? parseConfiguration(windowConfiguration).clientSettings
		: getFromLocalStorage<WorkspaceConfiguration>(lastWSPConfigurationKey);

	// note: in production, we should always have either embedded configuration (when served from WSP)
	// or the last configuration in local storage (when served from the service worker). local
	// builds may not have either and may see null here.
	return embeddedConfig ? applyInteractiveOverrides(embeddedConfig) : null;
}

export const ConfigurationProvider = (props: { children?: React.ReactNode }) => {
	const authenticationHandler = useAuthentication();
	return (
		<_ConfigurationProvider authenticationHandler={authenticationHandler}>
			{props.children}
		</_ConfigurationProvider>
	);
};
