import { Endpoint, UnencryptedCacheBucket } from '@citrite/workspace-ui-platform';
import { ParseXMLFunction } from 'Environment/EndpointSupplier';
import { fetchEndpoints } from 'Environment/EndpointSupplier/fetchEndpoints';

export class _EndpointSupplier<T extends string> {
	private serviceEndpointsDiscoveryUrl: string;
	private endpointsResolver: Promise<Endpoint[]>;
	private readonly endpointCacheBucket: UnencryptedCacheBucket;
	private readonly parseXML: ParseXMLFunction;

	public constructor(
		serviceEndpointsDiscoveryUrl: string,
		endpointCacheBucket: UnencryptedCacheBucket,
		parseXML: ParseXMLFunction
	) {
		this.serviceEndpointsDiscoveryUrl = serviceEndpointsDiscoveryUrl;
		this.endpointCacheBucket = endpointCacheBucket;
		this.parseXML = parseXML;
	}

	public getEndpoint(endpointId: T, { skipCache }: { skipCache?: boolean } = {}) {
		if (!skipCache) {
			const endpoints = this.endpointCacheBucket.getUnencrypted<Endpoint[]>(
				this.serviceEndpointsDiscoveryUrl
			);
			const endpoint = endpoints?.find(e => e.id === endpointId);
			if (endpoint) {
				this.getEndpoints();
				return Promise.resolve(endpoint.url as T);
			}
		}
		return this.getEndpointsFromNetwork().then(
			fetchedEndpoints => fetchedEndpoints.find(e => e.id === endpointId)?.url as T
		);
	}

	private getEndpointsFromNetwork() {
		this.endpointsResolver = undefined;
		return this.getEndpoints();
	}

	private getEndpoints() {
		this.endpointsResolver =
			this.endpointsResolver ||
			fetchEndpoints(this.serviceEndpointsDiscoveryUrl, this.parseXML).then(
				fetchedEndpoints => {
					this.endpointCacheBucket.setUnencrypted(
						this.serviceEndpointsDiscoveryUrl,
						fetchedEndpoints
					);
					return fetchedEndpoints;
				}
			);
		return this.endpointsResolver;
	}
}
