import React from 'react';
import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useFormFactor } from 'App/Header/useFormFactor';
import { getMobileAppLogo } from 'Components/MandateNativeAppSupport/utils';

const StyledAnchor = styled.a<{ smallFormFactor: boolean; disabled: boolean }>`
	margin-left: ${layout.tinySpace};
	color: #05758a;
	${props =>
		props.smallFormFactor &&
		`
		margin: ${layout.mediumSpace} 0;
	`}
	${props =>
		props.disabled &&
		`
		pointer-events: none;
		opacity: 0.5;
	`}
`;

interface Props {
	href: string;
	displayDescription?: boolean;
	disabled: boolean;
	onClick?(): void;
}

export const CustomDownloadAppButton = ({
	href,
	disabled,
	onClick = () => {},
}: Props) => {
	const { isSmallFormFactor } = useFormFactor();

	return (
		<StyledAnchor
			href={href}
			smallFormFactor={isSmallFormFactor}
			onClick={onClick}
			disabled={disabled}
			target="_blank"
			data-testid="download-citrix-href"
		>
			{getMobileAppLogo()}
		</StyledAnchor>
	);
};
