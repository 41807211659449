import React, { useEffect, useState } from 'react';
import { palette, SubTitle, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { last } from 'lodash';
import { useFormFactor } from 'App/Header/useFormFactor';
import {
	filteredResources,
	getSubcategories,
	ResourceFilter,
	sortedResources,
} from 'App/Screen/util';
import { CategoryTileRenderer } from 'App/Tile/CategoryTileRenderer';
import { ResourceTileRenderer } from 'App/Tile/ResourceTileRenderer';
import { ConditionalRenderer } from 'Components/ConditionalRenderer';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

export interface Props {
	title?: string;
	filter?: ResourceFilter;
	category?: string;
	setSubCategoryPath?: React.Dispatch<React.SetStateAction<any[]>>;
	subCategoryPath?: any[];
}

const StyledSubTitle = styled(SubTitle)`
	padding-bottom: 16px;
	font-size: ${typography.sizes.sml};
	font-weight: ${typography.weights.semiBold};
	color: ${palette.grey800};
`;

const StyledResourceContainer = styled.div<{ isLargeFormFactor: boolean }>`
	display: grid;
	margin: 0 auto;
	grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
	grid-auto-rows: 1fr;
	align-items: stretch;
	grid-gap: 16px;
`;

export const ResourceList = ({
	title,
	filter,
	category,
	setSubCategoryPath,
	subCategoryPath,
}: Props) => {
	const { value: resourceContext } = useLoadableResourceContext();
	const [renderableResources, setRenderableResources] = useState([]);
	const [subcategories, setSubcategories] = useState([]);
	const { isLargeFormFactor } = useFormFactor();

	useEffect(() => {
		setRenderableResources(
			sortedResources(
				filteredResources(
					resourceContext.resources || [],
					filter,
					last(subCategoryPath),
					subCategoryPath
				)
			)
		);
		if (filter === ResourceFilter.Applications) {
			const subcat = getSubcategories(
				resourceContext.resources || [],
				last(subCategoryPath),
				subCategoryPath
			);
			setSubcategories(subcat);
		}
	}, [resourceContext.resources, filter, category, subCategoryPath]);

	return (
		<>
			{title && <StyledSubTitle>{title}</StyledSubTitle>}
			<ConditionalRenderer flag={isLargeFormFactor} OnComponent={StyledResourceContainer}>
				{subcategories.map(folder => (
					<CategoryTileRenderer
						key={subCategoryPath.toString().concat(folder)}
						subCategory={folder}
						subCategoryPath={subCategoryPath}
						setSubCategoryPath={setSubCategoryPath}
					/>
				))}
				{renderableResources.map(resource => (
					<ResourceTileRenderer resource={resource} key={resource.id} />
				))}
			</ConditionalRenderer>
		</>
	);
};
