import { fetchMachineList } from 'App/Activity/Network/MachineList';
import { Machine, MachineError } from 'App/Activity/ResourceManagerContext';

export const MACHINE_FETCH_CONFIG = {
	debounceTime: 5000,
	pollingInterval: 120000,
};
const BUFFER_TIME = 100;

let lastMachineDataFetchTime = 0;

export const getLastFetchTime = (): number => lastMachineDataFetchTime;
export const setLastFetchTime = (time: number): void => {
	lastMachineDataFetchTime = time;
};

export const canFetchMachinesNow = (lastFetchTime: number, interval: number): boolean => {
	const now = Date.now();
	return now - lastFetchTime > interval - BUFFER_TIME;
};

/**
 *
 * @param url
 * @param desktopIds
 * @param hasLoggedIn
 * @returns List of machines from the network for the given desktopIds
 */
export const fetchMachinesFromNetwork = async (
	url: string,
	desktopIds: string[],
	hasLoggedIn: () => Promise<boolean>
): Promise<(Machine | MachineError)[]> => {
	const isUserLoggedIn = await hasLoggedIn();
	if (!isUserLoggedIn) {
		return [];
	}
	try {
		const machinesStatus = await fetchMachineList(url, desktopIds);
		return machinesStatus?.machines || [];
	} catch (error) {
		return [];
	}
};

/**
 *
 * @param resources
 * @returns List of machineIds which can be queried for machine state
 */
export const getQueryableMachineIds = (resources?: any[]): string[] =>
	resources
		?.filter(({ canquerymachinestate }) => canquerymachinestate)
		.map(({ id }) => id) || [];
