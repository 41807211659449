import { createClient, get } from '@citrite/http';
import { resilientPromise, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { environment } from 'Environment';
import { sendEventHubData } from 'Environment/ChromeApp/ChromeAppBridge';
import { shieldToggleFromChromeapp } from 'Environment/launchResource/chromeApp';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { isHTML5ShieldEnabled } from 'LeaseWorkflow';
import { setEventHubData } from 'LeaseWorkflow/ConnectionLeaseManagerService';
import { createCasEvent } from 'Workspace/CAS/createCasEvent';
import { isChromeAppCompatible } from 'Workspace/ChromeApp/ChromeAppHelperMethods';
import {
	TelemetryEvent,
	TelemetryEventPublisher,
} from 'Workspace/TelemetryEvents/TelemetryEventTypes';
import { UserDetails } from 'Workspace/UserContext';
import { CasEvent } from './CasEventTypes';
export interface CasTicket {
	customerId: string;
	eventHubEndpoint: string;
	eventHubToken: string;
	expiry: number;
	status: string;
}

const CAS_TICKET_STORAGE_KEY = 'cas-request-ticket';
export function createCasPublisher(
	workspaceConfiguration: WorkspaceConfiguration,
	userDetails: UserDetails
): TelemetryEventPublisher {
	let casTicket: CasTicket = null;
	let newCasTicketPromise: Promise<CasTicket> = null;
	async function getNewCasTicket(tryCacheFirst = true): Promise<CasTicket> {
		const requestCasTicketUrl = workspaceConfiguration.storeProxy.requestCasTicket;
		if (IS_ON_PREM || !requestCasTicketUrl || !environment.citrixCloudConnected) {
			return null;
		}
		try {
			let newCasTicket: CasTicket =
				tryCacheFirst && (await getFromLocalStorage(CAS_TICKET_STORAGE_KEY));
			if (!newCasTicket || hasCasTicketExpired(newCasTicket?.expiry) || !tryCacheFirst) {
				newCasTicketPromise =
					newCasTicketPromise ||
					resilientPromise(() => get<CasTicket>(requestCasTicketUrl), {
						delayMS: 1500,
					});
				newCasTicket = await newCasTicketPromise;
				setInLocalStorage(CAS_TICKET_STORAGE_KEY, newCasTicket);
			}
			if (newCasTicket.status !== 'success') {
				logError(new Error('Getting CAS Ticket failed with error'), {
					additionalContext: {
						status: newCasTicket.status,
					},
				});
				return null;
			}
			if (isChromeAppCompatible(workspaceConfiguration) && shieldToggleFromChromeapp()) {
				await sendEventHubData({
					customerId: newCasTicket.customerId,
					eventHubToken: newCasTicket.eventHubToken,
					eventHubEndpoint: newCasTicket.eventHubEndpoint,
					expiry: newCasTicket.expiry.toString(),
				});
			}
			if (isHTML5ShieldEnabled(workspaceConfiguration)) {
				setEventHubData?.({
					customerId: newCasTicket.customerId,
					eventHubToken: newCasTicket.eventHubToken,
					eventHubEndpoint: newCasTicket.eventHubEndpoint,
					expiry: newCasTicket.expiry.toString(),
				});
			}
			return newCasTicket;
		} catch (error) {
			logError(error, { customMessage: 'Getting CAS Ticket failed with exception' });
			return null;
		}
	}

	function hasCasTicketExpired(expiryTimestamp: number): boolean {
		return !expiryTimestamp ? !expiryTimestamp : Date.now() / 1000 >= expiryTimestamp;
	}

	async function publishCasEvent<T>(event: CasEvent<T>) {
		if (!event?.tenant?.id || IS_ON_PREM) {
			return;
		}
		if (!casTicket) {
			casTicket = await getNewCasTicket();
			if (!casTicket) {
				return;
			}
		}
		const executePost = async () =>
			await createClient().post<void>(casTicket.eventHubEndpoint, event, {
				headers: {
					Authorization: casTicket.eventHubToken,
				},
				passthroughDSAuthChallenge: true,
			});
		await resilientPromise(executePost, {
			retryPredicate: async () => {
				// TODO: https://issues.citrite.net/browse/WSUI-1828
				// Add a condition to retry only if the http status code is 401
				// _and_ the response body indicates that this is caused
				// by an expired token. This is to prevent calling WSP for a new token
				// which would be useless and could impact WSP performance.
				// Implementation is blocked by improper CORS hanndling
				casTicket = await getNewCasTicket(false);
				return !!casTicket;
			},
			delayMS: 2000,
		}).catch(logError);
	}
	return {
		publishEvent: async <T extends TelemetryEvent>(telemetryEvent: T): Promise<void> => {
			const casEvent = createCasEvent(
				telemetryEvent,
				workspaceConfiguration,
				userDetails
			);
			await publishCasEvent(casEvent);
		},
	};
}
