import { post } from '@citrite/http';
import { machineListCache } from 'App/Activity/MachineStateManager';
import { Machine, MachineError } from 'App/Activity/ResourceManagerContext';
import { getTimestamp } from 'App/Activity/Utility';
export interface MachineListRequestData {
	resourceIds: string[];
}

export interface MachineListResponseData {
	machines: (Machine | MachineError)[];
}

export const MACHINE_LIST_CACHE_KEY = 'machineList';
export async function fetchMachineList(
	endpoint: string,
	resourceIds: string[]
): Promise<MachineListResponseData> {
	const machineListFromCache: MachineListResponseData = machineListCache.getAllMachines();

	try {
		const machineListFromNetwork = await post<MachineListResponseData>(endpoint, {
			resourceIds,
		});

		const validatedMachineList: MachineListResponseData = {
			machines: [],
		};
		machineListFromNetwork.machines.forEach(networkMachine => {
			const cachedMachine = machineListFromCache?.machines.find(
				({ resourceId }) => resourceId === networkMachine.resourceId
			);
			const latestMachineData = isCachedDataNewerThanNetworkData(
				cachedMachine,
				networkMachine
			)
				? cachedMachine
				: networkMachine;
			validatedMachineList.machines.push(latestMachineData);
		});

		machineListCache.updateMachineList(validatedMachineList);

		return validatedMachineList;
	} catch (error) {
		return machineListFromCache;
	}
}

function isCachedDataNewerThanNetworkData(
	cachedMachine: Machine | MachineError,
	networkMachine: Machine | MachineError
): boolean {
	const cachedMachineLastUpdatedTime = getTimestamp(
		cachedMachine?.machineData?.lastUpdatedTime
	);
	const networkMachineLastUpdatedTime = getTimestamp(
		networkMachine?.machineData?.lastUpdatedTime
	);

	return cachedMachineLastUpdatedTime > networkMachineLastUpdatedTime;
}
