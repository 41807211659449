import * as React from 'react';
import { css } from 'aphrodite';
import { Button, useCitrixUIContext } from '@citrite/web-ui-component';
import { matchPath, NavLink } from 'react-router-dom';
import { appsPagePath } from 'App/Apps';
import { LeftNavLink } from 'App/LeftNavigation/LeftNavLink';
import { useUserAppsPath } from 'App/Navigation/useUserAppsPath';
import { performance } from 'Components/performance';
import { LinkIcon } from './LinkIcon';
import { styles } from './styles';

export interface Props {
	link: LeftNavLink;
	currentPath?: string;
}

export function BottomNavItem(props: Props) {
	const { theme } = useCitrixUIContext();
	const active = !!matchPath(props.currentPath, { path: props.link.href });
	const icon = active ? props.link.selectedIcon : props.link.icon;
	const userAppsPath = useUserAppsPath();
	const onClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		props.link.onClick(e);
	};
	const recordPerformanceMark = () => {
		if (props.link.performanceMark) {
			performance.mark(props.link.performanceMark);
		}
	};

	const getNavigationUrl = () =>
		props.link.href === appsPagePath ? userAppsPath : props.link.href;

	return (
		<>
			{props.link.href ? (
				<NavLink
					to={getNavigationUrl()}
					className={css(styles.linkActive)}
					onClick={recordPerformanceMark}
					aria-label={props.link.title}
					role="link" // Role is explicitly defined for the aria-label to be visible in iOS automation
					data-testid={props.link.analyticsID}
					id={props.link.analyticsID}
				>
					<LinkIcon icon={icon} color={theme.themeColors.primary} />
				</NavLink>
			) : (
				<Button.Wrapper className={css(styles.linkActive)} onClick={onClick}>
					<LinkIcon icon={icon} color={theme.themeColors.primary} />
				</Button.Wrapper>
			)}
		</>
	);
}
