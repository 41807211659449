function xmlToJson(xml: any): any {
	let obj: any = {};

	if (xml.nodeType === Node.ELEMENT_NODE) {
		if (xml.attributes && xml.attributes.length > 0) {
			obj = {};
			for (let j = 0; j < xml.attributes.length; j++) {
				const attribute = xml.attributes.item(j);
				if (attribute) {
					obj[attribute.nodeName] = attribute.nodeValue;
				}
			}
		}
	} else if (xml.nodeType === Node.TEXT_NODE) {
		obj = xml.nodeValue?.trim() || '';
	}

	// parse children
	if (xml.hasChildNodes()) {
		for (let i = 0; i < xml.childNodes.length; i++) {
			const item = xml.childNodes.item(i);
			if (item) {
				const nodeName = item.nodeName;
				if (obj[nodeName] === undefined) {
					obj[nodeName] = xmlToJson(item);
				} else {
					if (!Array.isArray(obj[nodeName])) {
						obj[nodeName] = [obj[nodeName]];
					}
					obj[nodeName].push(xmlToJson(item));
				}
			}
		}
	}
	return obj;
}

/*
   JQuery does not make the XML compact and creates extra #text nodes for white spaces. 
   So we compact the XML and then parse it.

    <root>
		<child>
			<grandchild>value</grandchild>
		</child>
	</root>

	will convert to <root><child><grandchild>value</grandchild></child></root>
*/
function makeCompactXML(xml: string) {
	return xml.replace(/>\s+</g, '><');
}

function inlineText(obj: object, rootKey?: string) {
	const rootObj = rootKey ? obj[rootKey] : obj;
	Object.keys(rootObj).forEach(key => {
		const child = rootObj[key];
		const [childKey, ...rest] = Object.keys(child);
		if (childKey === '#text' && rest.length === 0) {
			rootObj[key] = child[childKey];
		} else if (typeof rootObj[key] === 'object') {
			rootObj[key] = inlineText(rootObj, key);
		}
	});
	return rootObj;
}

export function parseXMLWithJQuery(configurationXML: string, $: JQueryStatic) {
	const xmlData = $.parseXML(makeCompactXML(configurationXML));
	let result = xmlToJson(xmlData);
	result = inlineText(result);
	return result;
}
