import {
	PlatformDependencies,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { AnalyticsEvent, trackAnalyticsEvent } from 'analytics';
import { logError } from 'remoteLogging';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { Dependencies } from 'GlobalAppConfigService/types';
import { LogErrorOptions } from 'Loggers/LoggingProvider';
import { container } from 'Workspace/DependencyManagement';

export const wsuiDependencies: Dependencies = {
	logError(payload: any, options?: LogErrorOptions, silent?: boolean): void {
		logError(payload, options, silent);
	},
	trackAnalyticsEvent(event: AnalyticsEvent): void {
		trackAnalyticsEvent(event);
	},
	getWSConfig(): WorkspaceConfiguration {
		return container.resolve(PlatformDependencies.WorkspaceConfiguration).get();
	},
	createEndpointSupplier,
};
