import * as React from 'react';
import { defaultClient } from 'clients';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { getEndpointsService } from 'Environment/configSelectors';
import { createEndpointSupplier } from 'Environment/EndpointSupplier';
import { getStoreIdentifiers } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';
import { BucketManifest, useCacheContext } from 'Workspace/Cache';
import { createAdminNotificationClient } from './createAdminNotificationClient';

export const ANS_SERVICE_NAME = 'adminNotification';

export const useAdminNotificationClient = () => {
	const { workspaceConfiguration } = useConfigurationContext();
	const { cacheFactory } = useCacheContext();

	return React.useMemo(() => {
		const endpointService = getEndpointsService(workspaceConfiguration, ANS_SERVICE_NAME);
		const storeIdentifiers = getStoreIdentifiers(workspaceConfiguration);

		let endpointSupplier;
		let ssoProxyClient;
		if (endpointService?.discoveryUrl) {
			endpointSupplier = createEndpointSupplier(endpointService.discoveryUrl);
			ssoProxyClient = environment.createSSOProxyClient(
				workspaceConfiguration.authManager.proxyUrl
			);
		} else if (workspaceConfiguration.userNotifications && !endpointService) {
			const notificationUrl = workspaceConfiguration.userNotifications.url;
			if (!notificationUrl) {
				return null;
			}
			endpointSupplier = {
				getEndpoint: async () => notificationUrl,
			};
			ssoProxyClient = defaultClient;
		} else {
			return null;
		}
		return createAdminNotificationClient(
			endpointSupplier,
			ssoProxyClient,
			cacheFactory.createEncryptedCacheBucket(BucketManifest.adminNotification),
			storeIdentifiers?.customerId,
			storeIdentifiers?.storeGuid
		);
	}, [workspaceConfiguration, cacheFactory]);
};
