import * as React from 'react';
import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { makeAsync } from '@citrite/workspace-ui-platform-react';
import * as Cookie from 'js-cookie';
import { environment } from 'Environment';
import {
	getLeasingProperties,
	getStoreHealth,
	initiateStore,
	logOff,
	notifyAuthenticationTimer,
	sendCustomerConfiguration,
	SendLogoffMessage,
} from 'Environment/BrowserExtension/browserExtensionBridge';
import {
	configureContentScriptTransportListener,
	subscribeToAADEventsFromBrowserExtension,
} from 'Environment/BrowserExtension/BrowserExtensionContentScriptTransport';
import {
	isLeaseFallbackAvailable,
	launchViaBrowserExtension,
} from 'Environment/BrowserExtension/browserExtensionLaunch';
import {
	isReadyForTelemetryLaunch,
	parseLeasingPropertiesFromResponse,
	startTelemetryLaunchTimer,
} from 'Environment/BrowserExtension/browserExtensionTelemetryLaunch';
import {
	AuthenticationTimerOperationType,
	BrowserExtensionErrorReason,
} from 'Environment/BrowserExtension/types';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { clientType, getClientType, setClientType } from 'Environment/getClientType';
import { isFirefox, isSafari } from 'Environment/launchResource/device';
import { initMSALSdkForHybridMode } from 'Environment/msal/hybridMsalAuth';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { isPreviousLogoutExplicit } from 'Logout/previousLogoutConfig';
import {
	getStoreIdentifiers,
	isHybridAADSSOSupported,
	logBrowserExtensionError,
} from 'Workspace/BrowserExtension/browserExtensionHelperMethods';
import {
	defaultContext,
	ExtensionConfigurationState,
	ResiliencyBrowserExtensionContext,
} from 'Workspace/BrowserExtension/Context';
import {
	LeasingProperties,
	parseExtensionConfiguration,
} from 'Workspace/BrowserExtension/customerConfiguration';
import { fallbackToPreviousClientType } from 'Workspace/BrowserExtension/fallbackToPreviousClientType';
import { shouldLoadInOfflineMode } from 'Workspace/BrowserExtension/ResiliencyBrowserExtensionSupport';
import { sendCustomerConfigurationToBxtn } from 'Workspace/BrowserExtension/sendCustomerConfigurationUtil';
import { InitialLoading } from 'Workspace/InitialLoading';
import { isSaasApp, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { trackBrowserExtensionError } from 'Workspace/TelemetryEvents/browserExtension/createBrowserExtensionAnalyticsReporter';
import { UserContext } from 'Workspace/UserContext';
import { lastUserDetailsKey } from 'Workspace/UserContext/constants';
import { onInstallEventListener } from './browerExtensionInstallEventHandlers';

interface Props {
	userContext: UserContext;
	workspaceConfiguration: WorkspaceConfiguration;
	children?: React.ReactNode;
}

interface State {
	storeInfoSent?: boolean;
	storeHealthyForLaunch?: boolean;
	isTelemetryLaunchTimerRunning?: boolean;
	leasingProperties?: LeasingProperties;
}

export const EmptyProvider: React.FC = props => (
	<ResiliencyBrowserExtensionContext.Provider value={defaultContext}>
		{props.children}
	</ResiliencyBrowserExtensionContext.Provider>
);

export const AsyncResiliencyBrowserExtensionContextProvider = makeAsync(
	() =>
		import(
			/* webpackChunkName: "BrowserExtension" */ 'Workspace/BrowserExtension/Provider'
		).then(x => x.ResiliencyBrowserExtensionContextProvider),
	<InitialLoading />,
	EmptyProvider
);

export class ResiliencyBrowserExtensionContextProvider extends React.Component<
	Props,
	State
> {
	public state: State = { storeHealthyForLaunch: false };

	public get shouldBlockRenderingForExtensionSetup() {
		return !getClientType();
	}

	/**
	 * A promise that resolves to an active ExtensionConfigurationState once WSUI has established communication with the extension
	 * with native client(s) for the provided customer configuration.
	 */
	private extensionConfigurationResolver: Promise<ExtensionConfigurationState>;

	public constructor(props: Props) {
		super(props);
		const { workspaceConfiguration } = props;

		if (isSafari() || isFirefox()) {
			configureContentScriptTransportListener();
		}

		if (isHybridAADSSOSupported(workspaceConfiguration)) {
			initMSALSdkForHybridMode();
			subscribeToAADEventsFromBrowserExtension();
		}

		const storeIdentifiers = getStoreIdentifiers(workspaceConfiguration);

		this.extensionConfigurationResolver = sendCustomerConfiguration({
			storeId: storeIdentifiers.storeGuid,
			customerId: storeIdentifiers.customerId,
			endpoints: workspaceConfiguration?.endpointsServices?.endpointsService || [],
			isPreviousLogoutExplicit: isPreviousLogoutExplicit(),
			hasLastUserDetails: !!getFromLocalStorage(lastUserDetailsKey),
			idpConfigId: workspaceConfiguration.authManager?.idpConfigId,
			isHtml5Enabled: getClientType() === clientType.html5,
			isModernOfflineFlowEnabledForStore: hasFeatureCanary(
				workspaceConfiguration,
				FeatureFlag.UseModernOfflineFlow
			),
		})
			.then(response => {
				if (!getClientType()) {
					setClientType(clientType.browserextension);
				}
				this.setState({
					storeInfoSent: true,
					leasingProperties: parseLeasingPropertiesFromResponse(response),
				});

				return parseExtensionConfiguration(response.result);
			})
			.catch(error => {
				this.setState({ storeInfoSent: true });
				logBrowserExtensionError(error);
				const result: ExtensionConfigurationState = {
					isActive: false,
					allowAccessToOfflineResources: false,
					useAuthenticationTimer: false,
				};
				return result;
			});

		onInstallEventListener(async () => await this.updateExtensionConfigResolver());
	}

	public render() {
		if (!this.state.storeInfoSent && this.shouldBlockRenderingForExtensionSetup) {
			return <InitialLoading />;
		}

		return (
			<ResiliencyBrowserExtensionContext.Provider
				value={{
					isSupportedCustomerRuntime: true,
					getExtensionConfiguration: () => this.extensionConfigurationResolver,
					noteLogout: this.noteLogout,
					launch: options =>
						launchViaBrowserExtension({
							...options,
							onIcaDownload: this.handleTelemetryLaunch,
							workspaceConfiguration: this.props.workspaceConfiguration,
						}),
					shouldBrowserExtensionLaunchResource: this.shouldbrowserExtensionLaunchResource,
					notifyAuthenticationTimer: this.notifyAuthenticationTimer,
					leasingProperties: this.state.leasingProperties,
				}}
			>
				{this.props.children}
			</ResiliencyBrowserExtensionContext.Provider>
		);
	}

	public componentDidMount() {
		if (this.canInitiateStoreFromProps(this.props)) {
			this.runInitiateStore();
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			!this.canInitiateStoreFromProps(prevProps) &&
			(this.canInitiateStoreFromProps(this.props) || this.needsOfflineStoreInitiation())
		) {
			this.runInitiateStore();
		}
	}

	private needsOfflineStoreInitiation() {
		const shouldInitiateStoreInOfflineMode = hasFeatureCanary(
			this.props.workspaceConfiguration,
			FeatureFlag.InitiateStoreInOfflineMode
		);
		return (
			shouldInitiateStoreInOfflineMode &&
			!!this.props.userContext.userDetails?.userId &&
			(shouldLoadInOfflineMode() || !environment.citrixCloudConnected)
		);
	}

	private canInitiateStoreFromProps(props: Props) {
		return (
			!!props.userContext.userDetails?.userId &&
			!!props.userContext.userDetails?.userThumbprint
		);
	}

	private updateExtensionConfigResolver() {
		const { workspaceConfiguration } = this.props;
		this.extensionConfigurationResolver = sendCustomerConfigurationToBxtn(
			workspaceConfiguration
		).then(async response => {
			if (
				response?.extensionConfig?.isActive &&
				response?.browserExtensionResponse &&
				this.canInitiateStoreFromProps(this.props)
			) {
				await initiateStore({
					isCloudOnline: environment.citrixCloudConnected,
					csrfToken: environment.citrixCloudConnected
						? Cookie.get('CsrfToken')
						: undefined,
					userId: this.props.userContext.userDetails?.userId,
				})
					.then(async initiatedStoreResponse => {
						if (initiatedStoreResponse?.status === 'successful') {
							this.setState({
								storeInfoSent: true,
								storeHealthyForLaunch: true,
								leasingProperties: parseLeasingPropertiesFromResponse(
									response?.browserExtensionResponse
								),
							});
						}
					})
					.catch(error => {
						this.setState({ storeInfoSent: true });
						logBrowserExtensionError(error);
					});
			} else {
				this.setState({ storeInfoSent: true });
			}
			return response.extensionConfig;
		});
		return this.extensionConfigurationResolver;
	}

	private runInitiateStore = async () => {
		const extensionConfig = await this.extensionConfigurationResolver;
		if (extensionConfig.isActive) {
			initiateStore({
				isCloudOnline: environment.citrixCloudConnected,
				csrfToken: environment.citrixCloudConnected ? Cookie.get('CsrfToken') : undefined,
				userId: this.props.userContext.userDetails?.userId,
			}).catch(logBrowserExtensionError);
		}
	};

	private shouldbrowserExtensionLaunchResource = async (resource: Resource) => {
		return !isSaasApp(resource) && (await this.isStoreHealthyForLaunch());
	};

	private isStoreHealthyForLaunch = async () => {
		if (this.state.storeHealthyForLaunch) {
			return true;
		}
		try {
			await getStoreHealth();
			this.setState({ storeHealthyForLaunch: true });
			return true;
		} catch (error) {
			logBrowserExtensionError(error);
			if (getClientType() === clientType.browserextension) {
				fallbackToPreviousClientType();
			}
			trackBrowserExtensionError(error);
			return false;
		}
	};

	private noteLogout = async (workload: SendLogoffMessage) => {
		try {
			await logOff(workload);
		} catch (error) {
			if (error?.name === BrowserExtensionErrorReason.Unavailable) {
				return;
			}
			if (error?.name === BrowserExtensionErrorReason.Chrome_Runtime_Not_Available) {
				return;
			}
			throw error;
		}
	};

	private handleTelemetryLaunch = async (resource: Resource) => {
		if (!isLeaseFallbackAvailable(resource) || this.state.isTelemetryLaunchTimerRunning) {
			return;
		}
		let leasingProperties = this.state.leasingProperties;
		if (!leasingProperties) {
			try {
				const response = await getLeasingProperties();
				leasingProperties = parseLeasingPropertiesFromResponse(response);
				this.setState({
					leasingProperties,
				});
			} catch (error) {
				logBrowserExtensionError(error);
				return;
			}
		}

		if (
			leasingProperties?.telemetryHeadlessLaunchEnabled &&
			isReadyForTelemetryLaunch(leasingProperties)
		) {
			startTelemetryLaunchTimer(resource, leasingProperties, () =>
				this.setState({ isTelemetryLaunchTimerRunning: false })
			);
			this.setState({ isTelemetryLaunchTimerRunning: true });
		}
	};

	private notifyAuthenticationTimer = async (
		timerOperation: AuthenticationTimerOperationType
	) => {
		if (
			getClientType() === clientType.browserextension &&
			(await this.extensionConfigurationResolver).useAuthenticationTimer
		) {
			try {
				await notifyAuthenticationTimer({
					storeId: this.props.workspaceConfiguration.storeIdentifiers.storeGuid,
					customerId: this.props.workspaceConfiguration.storeIdentifiers.customerId,
					timerOperation,
				});
			} catch (error) {
				// ignore if extension unavailable error, log otherwise
				if (error?.name !== BrowserExtensionErrorReason.Unavailable) {
					logBrowserExtensionError(error);
				}
			}
		}
	};
}
