import { GlobalAppConfigResponse } from 'GlobalAppConfigService/types';

export class InMemoryGacsTicket {
	// While storing the ticket in memory, we will consider the ticket to be expired 20 seconds
	// before the actual expiry time. This is to ensure that the ticket is not expired
	// when it is being used by native.
	public static readonly GACS_TICKET_EXPIRY_OFFSET_MILLISEC = 20 * 1000;

	private readonly gacsTicket: GlobalAppConfigResponse;
	private readonly expiryTimestamp: number; //this is in milliseconds with current timestamp as reference

	public constructor(ticket: GlobalAppConfigResponse) {
		if (!ticket) {
			throw new Error('Invalid GACS ticket');
		}

		this.gacsTicket = ticket;
		this.expiryTimestamp =
			Date.now() +
			ticket.accessExpiresIn * 1000 -
			InMemoryGacsTicket.GACS_TICKET_EXPIRY_OFFSET_MILLISEC;
	}

	public get(): GlobalAppConfigResponse | undefined {
		if (Date.now() > this.expiryTimestamp) {
			return undefined;
		}
		return this.gacsTicket;
	}
}
