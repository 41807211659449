import { machineListCache } from 'App/Activity/MachineStateManager';
import { MachineRegistrationState, PowerState } from 'App/Activity/Network/ActionsUtil';
import { isLinux } from 'Environment/launchResource/device';

export const nativeWorkspaceScheme = 'receiver:';

export function urlSafeBase64Encode(value: string) {
	// Replace certain characters in the base64 alphabet with ones that are safe to use in URL paths
	const encodedValue = window.btoa(value);
	if (isLinux()) {
		// TODO: Remove this check when all Linux Clients are on 2411 or later version
		return encodedValue;
	}
	return encodedValue.replace(/\+/g, '_').replace(/\//g, '!').replace(/=/g, '-');
}

// This must be calculated on demand as it may change within the lifetime of a page.
// We parse location.href rather than using location.hash because the latter behaves
// inconsistently (with regard to URL encoding/decoding) in Firefox.
function getUrlHash() {
	return window?.location?.href?.split('#')[1] || '';
}

export function isAppShortcutLaunch() {
	const urlHashComponents = getUrlHash().split('/');
	return urlHashComponents.length === 4 && urlHashComponents[1] === 'launch';
}

export const updateMachinePowerStatus = (resourceId: string) => {
	machineListCache.updateMachine(
		{ resourceId },
		{
			powerState: PowerState.ON,
			registrationState: MachineRegistrationState.REGISTERED,
			lastUpdatedTime: new Date().toISOString(),
		}
	);
};
