import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { isSafari } from 'Environment/launchResource/device';
import { isBrowserExtensionCompatible } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';

export const shouldDetectClientBeforeAuth = (
	workspaceConfiguration: WorkspaceConfiguration
) => {
	if (!IS_ON_PREM) {
		return false;
	}

	const { showAfterLogin, html5 } = workspaceConfiguration?.pluginAssistant;
	const html5EnabledAlways = html5.enabled === 'Always';

	const configEnabledShowClientDetect =
		!JSON.parse(showAfterLogin) && !html5EnabledAlways;

	if (!configEnabledShowClientDetect) {
		return false;
	}

	// On Safari we don't have a way to detect if BXTN is enabled,
	// so isBrowserExtensionCompatible returns true always for Safari
	// so we will make client detection happen before auth in Safari for both cases
	// so that app protection works as expected
	return isSafari() || !isBrowserExtensionCompatible(workspaceConfiguration);
};
