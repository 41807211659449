import React from 'react';
import { t } from '@citrite/translate';
import {
	layout,
	NotificationBadgeHalfIcon,
	NotificationBadgeIcon,
	palette,
	Tooltip,
	typography,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import moment from 'moment';
import { PowerState } from 'App/Activity/Network/ActionsUtil';
import { useFormFactor } from 'App/Header/useFormFactor';
import { getResourceSubText } from 'App/Screen/util';
import { detectLanguage } from 'javascript/sf/detectLanguage';

interface PowerStateIndicatorProps {
	resourceType: string;
	powerState: string;
	lastUpdatedTime: number;
	isSmallFormFactor?: boolean;
}

interface StateConfig {
	icon?: React.ReactNode;
	text: string;
}

const StyledLightText = styled.div<{ isSmallFormFactor: boolean }>`
	color: ${palette.black};
	padding: 6px 0;
	font-weight: ${typography.weights.regular};
	line-height: 11px;
	font-size: ${typography.sizes.xsml2};
	min-width: fit-content;
	display: flex;
	align-items: center;
	${({ isSmallFormFactor }) =>
		isSmallFormFactor ? `gap: ${layout.tinySpace};` : `gap: ${layout.smallSpace};`}
`;

const powerStatusIconSize = 11;
const StyledSpanText = styled.span`
	opacity: 0.6;
`;

export const PowerStateIndicator: React.FC<PowerStateIndicatorProps> = ({
	resourceType,
	powerState,
	lastUpdatedTime,
}) => {
	const { isSmallFormFactor } = useFormFactor();
	const [powerStateTimeStamp, setpowerStateTimeStamp] = React.useState<string>('');
	const getPowerStatusAge = () => {
		if (powerState === PowerState.UNKNOWN || lastUpdatedTime === 0) {
			return t('Workspace:activity_manager.tooltip_state_unavailable');
		}

		if (powerState === PowerState.UNMANAGED) {
			return t('Workspace:activity_manager.tooltip_state_unmanaged');
		}

		const lastUpdatedTimeStamp = moment(lastUpdatedTime)
			.locale(detectLanguage())
			.fromNow();
		return `${lastUpdatedTimeStamp}`;
	};

	const handlePowerStatusAge = () => {
		const machineStatusAge = getPowerStatusAge();
		setpowerStateTimeStamp(machineStatusAge);
	};

	const powerStateConfig: Record<string, StateConfig> = {
		[PowerState.ON]: {
			icon: <NotificationBadgeIcon color={palette.green600} size={powerStatusIconSize} />,
			text: t('Workspace:activity_manager.powered_on'),
		},
		[PowerState.OFF]: {
			icon: <NotificationBadgeIcon color={palette.grey600} size={powerStatusIconSize} />,
			text: t('Workspace:activity_manager.powered_off'),
		},
		[PowerState.SUSPENDED]: {
			icon: (
				<NotificationBadgeHalfIcon color={palette.yellow800} size={powerStatusIconSize} />
			),
			text: t('Workspace:activity_manager.powerestate_in_Hibernation'),
		},
		[PowerState.SUSPENDING]: {
			text: t('Workspace:activity_manager.hibernating'),
		},
		[PowerState.RESUMING]: {
			text: t('Workspace:activity_manager.resuming'),
		},
		[PowerState.TURNING_OFF]: {
			text: t('Workspace:activity_manager.shutting_down'),
		},
		[PowerState.TURNING_ON]: {
			text: t('Workspace:launch_status.powering-on'),
		},
		[PowerState.UNKNOWN]: {
			text: getResourceSubText(resourceType),
		},
		[PowerState.STARTING]: {
			text: t('Workspace:activity_manager.starting'),
		},
	};

	const currentMachineStatus =
		powerStateConfig[powerState] || powerStateConfig[PowerState.UNKNOWN];

	return (
		<Tooltip
			hoverTrigger={
				<StyledLightText
					isSmallFormFactor={isSmallFormFactor}
					id="power-state-indicator"
					onMouseEnter={handlePowerStatusAge}
				>
					{currentMachineStatus.icon}
					<StyledSpanText>{currentMachineStatus.text}</StyledSpanText>
				</StyledLightText>
			}
		>
			{powerStateTimeStamp}
		</Tooltip>
	);
};
