import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { InMemoryGacsTicket } from 'GlobalAppConfigService/inMemoryGacsTicket';
import { Dependencies, GlobalAppConfigResponse } from 'GlobalAppConfigService/types';
import { ErrorLevel } from 'Loggers/LoggingProvider';
import { GlobalAppConfigV2Payload } from 'Workspace/TelemetryEvents/globalAppConfigV2/createGlobalAppConfigCASReporter';

export abstract class GacsTicketFetcherBase {
	protected GACS_TOKEN_ENDPOINT_SERVICE = 'TokenService';
	protected MAX_GACS_TIMEOUT_MS = 5000;
	protected GACS_TIMEOUT_ERROR = new Error('GACS_TIMEOUT');

	private inMemoryGacsTicket: InMemoryGacsTicket;

	public abstract isGacsV2Enabled(workspaceConfig: WorkspaceConfiguration): boolean;

	protected readonly dependencies: Dependencies;

	protected constructor(dependencies: Dependencies) {
		this.dependencies = dependencies;
	}

	protected abstract fetchGacsTicketFromNetwork(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<GlobalAppConfigResponse>;

	public async getGacsParams(): Promise<string> {
		const workspaceConfig = this.dependencies.getWSConfig();

		if (!this.isGacsV2Enabled(workspaceConfig)) {
			return null;
		}

		const gacsTicket = await this.getGacsTicket(workspaceConfig);
		if (!gacsTicket?.access || !gacsTicket?.metadata) {
			return null;
		}
		this.dependencies.trackAnalyticsEvent(
			GlobalAppConfigV2Payload.isRequestSuccessful(true)
		);
		return `&gacsToken=${gacsTicket.access}&gacsMeta=${gacsTicket.metadata}`;
	}

	private async getGacsTicket(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<GlobalAppConfigResponse> {
		try {
			//Prefer to return the in-memory ticket if it is not expired
			const inMemoryGacsToken = this.inMemoryGacsTicket?.get();
			if (inMemoryGacsToken) {
				return inMemoryGacsToken;
			}

			const startTime = Date.now();

			// Return the result of the network fetch or null if it takes longer than max timeout
			const timeoutPromise = new Promise<GlobalAppConfigResponse>((_, reject) => {
				setTimeout(() => {
					reject(this.GACS_TIMEOUT_ERROR);
				}, this.MAX_GACS_TIMEOUT_MS);
			});

			const gacsTicket = await Promise.race([
				timeoutPromise,
				this.fetchGacsTicketFromNetwork(workspaceConfiguration),
			]);

			// Store the ticket in memory
			this.inMemoryGacsTicket = gacsTicket
				? new InMemoryGacsTicket(gacsTicket)
				: undefined;

			const endTime = Date.now();

			const latency = endTime - startTime;
			this.dependencies.trackAnalyticsEvent(GlobalAppConfigV2Payload.getLatency(latency));
			return gacsTicket;
		} catch (error) {
			if (error === this.GACS_TIMEOUT_ERROR) {
				this.dependencies.trackAnalyticsEvent(GlobalAppConfigV2Payload.isTimedOut(true));
			} else {
				this.dependencies.trackAnalyticsEvent(
					GlobalAppConfigV2Payload.isRequestSuccessful(false)
				);
				this.dependencies.logError(error, {
					additionalContext: { level: ErrorLevel.CRITICAL },
				});
			}
			return null;
		}
	}
}
