/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { t } from '@citrite/translate';
import { notifyError } from '@citrite/web-ui-component';
import { BackoffStrategy, resilientPromise } from '@citrite/workspace-ui-platform';
import _ from 'lodash';
import { logError } from 'remoteLogging';
import { getConfirmationDetails } from 'App/Activity/components/ConfirmationUtility';
import { MessageComponent } from 'App/Activity/components/MessageComponent';
import {
	checkMachineOperationStatus,
	isInEnum,
	mergeNativeAndLocalSessions,
} from 'App/Activity/components/Utility';
import { handleMachineActionCompletion } from 'App/Activity/HandleMachineActionCompletion';
import { fetchAndUpdateNativeSessions } from 'App/Activity/LocalSessionAdapter';
import {
	ActionStatus,
	LaunchOperationResponse,
	MachineOperationResponse,
	SessionOperationResponse,
} from 'App/Activity/Network/ActionsUtil';
import { fetchAndCategorizeRemoteSessions } from 'App/Activity/RemoteSessionAdapter';
import {
	getRemoteEndpoint,
	getValidatedActions,
	makeApplicationAction,
	needPeriodicRefresh,
	performDesktopAction,
} from 'App/Activity/ResourceActionHandler';
import {
	ActiveLaunchAction,
	ActiveMachineAction,
	ActiveSessionAction,
	ApplicationAction,
	DesktopAction,
	IResourceManagerContext,
	LaunchAction,
	Machine,
	MachineAction,
	ResourceDetails,
	ResourceManagerContext,
	Session,
	SessionAction,
	SessionConnectionState,
	Sessions,
} from 'App/Activity/ResourceManagerContext';
import { useEventBusSubscription } from 'App/Activity/useEventBusSubscription';
import { useMachineStatus } from 'App/Activity/useMachineStatus';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import {
	ResourceContextProvider,
	useLoadableResourceContext,
} from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';

const MAX_ATTEMPTS = 8;
const DELAY = 15000;

const resiliencyOptions = {
	maxAttempts: MAX_ATTEMPTS,
	delayMS: DELAY,
	backoffStrategy: BackoffStrategy.LINEAR,
};

const loadingSessions = {
	localSessions: { loading: true, sessions: [] as Session[] },
	remoteSessions: { loading: true, sessions: [] as Session[] },
	hibernatedSessions: { loading: true, sessions: [] as Session[] },
};

const initialContext: IResourceManagerContext = {
	localSessions: { loading: false, sessions: [] as Session[] },
	remoteSessions: { loading: false, sessions: [] as Session[] },
	hibernatedSessions: { loading: false, sessions: [] as Session[] },
	machineStatus: {
		loading: false,
		machineData: [] as Machine[],
		refreshMachines: () => false,
		refreshMachinesFromCache: () => false,
	},
	deviceId: '',
	activeSessionActions: [],
	activeMachineActions: [],
	activeLaunchActions: [],
	refresh: () => {},
	onDesktopAction: async () => Promise.resolve(),
	onApplicationAction: async () => Promise.resolve(),
};
const _ResourceManagerProvider: React.FC = ({ children }) => {
	const [resourceManagerContext, setResourceManagerContext] =
		useState<IResourceManagerContext>(initialContext);

	const [hasResilientRefresh, setHasResilientRefresh] = useState(false);
	const [resilientMachineStates, setResilientMachineStates] = useState<string[]>([]);

	const workspaceConfiguration = useConfigurationContext().workspaceConfiguration;
	const { loading: resourcesLoading, value: resourceContext }: ResourceContextProvider =
		useLoadableResourceContext();
	const userContext = useUserContext();
	const shouldInitiateOnLoad = useFeatureCanary(
		FeatureFlag.InitiateResourceManagerOnLoad
	);
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);

	const sessionRefreshFlag = useRef(false);
	const { machineData, loading, refreshMachines, refreshMachinesFromCache } =
		useMachineStatus();
	useEffect(() => {
		updateContext({
			machineStatus: {
				loading,
				machineData,
				refreshMachines,
				refreshMachinesFromCache,
			},
		});
	}, [loading, machineData, refreshMachinesFromCache]);

	const [hasInitiated, setHasInitiated] = useState(false);

	const updateContext = useCallback(
		(partialContext: Partial<IResourceManagerContext>) => {
			setResourceManagerContext(prevContext => ({
				...prevContext,
				...partialContext,
				activeSessionActions:
					partialContext.activeSessionActions ||
					getValidatedActions(prevContext.activeSessionActions, partialContext),
			}));
		},
		[]
	);

	const updateContextWithCallback = useCallback(
		(contextDispatchFunction: React.SetStateAction<IResourceManagerContext>) =>
			setResourceManagerContext(contextDispatchFunction),
		[]
	);

	useEventBusSubscription(updateContextWithCallback);

	useEffect(() => {
		if (
			!hasResilientRefresh &&
			needPeriodicRefresh(resourceManagerContext.activeSessionActions)
		) {
			setHasResilientRefresh(true);
		} else {
			setHasResilientRefresh(false);
		}
	}, [resourceManagerContext.activeSessionActions]);

	const updateMachineOperationStatus = useCallback(
		(machineAction: ActiveMachineAction) => {
			const shouldContinueStatusCheck =
				machineAction.response &&
				machineAction.response.actionStatus !== ActionStatus.COMPLETED &&
				machineAction.response.actionStatus !== ActionStatus.FAILED;
			updateContextWithCallback(prevContext => {
				const newMachineActions = prevContext.activeMachineActions.filter(
					({ machine }) => machine.machineId !== machineAction.machine.machineId
				);

				if (shouldContinueStatusCheck) {
					newMachineActions.push(machineAction);
				} else {
					handleMachineActionCompletion(machineAction, {
						isMachinePowerStateEnabled,
						onRefresh: prevContext.refresh,
						refreshMachinesFromCache,
						sessionRefreshFlag,
					});
				}
				return {
					...prevContext,
					activeMachineActions: newMachineActions,
				};
			});
			return shouldContinueStatusCheck;
		},
		[refreshMachinesFromCache]
	);

	useEffect(() => {
		resourceManagerContext.activeMachineActions.forEach(
			({ action, machine, response }: ActiveMachineAction) => {
				if (response && !resilientMachineStates.includes(response.continuationId)) {
					resilientPromise(
						async () =>
							checkMachineOperationStatus(
								getRemoteEndpoint(action, workspaceConfiguration),
								response.continuationId
							),
						{
							...resiliencyOptions,
							repeatPredicate: result =>
								updateMachineOperationStatus({ response: result, action, machine }),
						}
					);
					setResilientMachineStates(prevStates => [
						...prevStates,
						response.continuationId,
					]);
				}
			}
		);
	}, [resourceManagerContext.activeMachineActions]);

	useEffect(() => {
		let hasActionsListModified = false;
		const newActiveLaunchActions = resourceManagerContext.activeLaunchActions.filter(
			({ session }) => {
				const isActiveSession = resourceContext?.launchInProgressIds.includes(
					session?.applications[0]?.resource?.id
				);
				if (!isActiveSession) {
					hasActionsListModified = true;
				}
				return isActiveSession;
			}
		);

		if (hasActionsListModified) {
			updateContextWithCallback(prevContext => ({
				...prevContext,
				activeLaunchActions: newActiveLaunchActions,
			}));
		}
	}, [resourceContext?.launchInProgressIds, resourceManagerContext.activeLaunchActions]);

	useEffect(() => {
		if (hasResilientRefresh) {
			setTimeout(() => {
				fetchData(true);
				setHasResilientRefresh(false);
			}, DELAY);
		}
	}, [hasResilientRefresh]);

	useEffect(() => {
		const activeSessions =
			resourceManagerContext.activeSessionActions?.reduce(
				(actions, { action, session, response }) => {
					const allSessions = [
						...resourceManagerContext.localSessions.sessions,
						...resourceManagerContext.remoteSessions.sessions,
						...resourceManagerContext.hibernatedSessions.sessions,
					];
					const activeSession = allSessions.find(
						({ sessionId }) => sessionId === session.sessionId
					);
					if (
						activeSession &&
						activeSession.connectionState === SessionConnectionState.CONNECTED
					) {
						actions.push({ action, session: activeSession, response });
					}
					return actions;
				},
				[]
			) || [];
		updateContext({
			activeSessionActions: activeSessions,
		});
		// Add the session list machine data in the resource session local storage cache

		if (
			!resourceManagerContext.localSessions.loading &&
			!resourceManagerContext.remoteSessions.loading &&
			!resourceManagerContext.hibernatedSessions.loading &&
			(resourceManagerContext.hibernatedSessions.sessions?.length > 0 ||
				resourceManagerContext.remoteSessions.sessions?.length > 0 ||
				resourceManagerContext.localSessions.sessions?.length > 0)
		) {
			setInLocalStorage('resourceSessions', {
				localSessions: resourceManagerContext.localSessions,
				remoteSessions: resourceManagerContext.remoteSessions,
				hibernatedSessions: resourceManagerContext.hibernatedSessions,
			});
		}
	}, [
		resourceManagerContext.localSessions,
		resourceManagerContext.remoteSessions,
		resourceManagerContext.hibernatedSessions,
		updateContext,
	]);

	// get session list machine data from local storage cache and update the context with the machine data
	useEffect(() => {
		const cachedSessions = getFromLocalStorage<{
			localSessions: Sessions;
			remoteSessions: Sessions;
			hibernatedSessions: Sessions;
		}>('resourceSessions');
		if (cachedSessions) {
			const { localSessions, remoteSessions, hibernatedSessions } = cachedSessions;
			updateContext({
				localSessions,
				remoteSessions,
				hibernatedSessions,
			});
		}
	}, []);

	const captureActionResponse = useCallback(
		(
			action: DesktopAction,
			session: Session,
			response:
				| SessionOperationResponse
				| MachineOperationResponse
				| LaunchOperationResponse
				| void
		) => {
			const initiatedAction = {
				session,
				action,
				response,
				machine: session.machineData,
			};

			if (isInEnum(action, SessionAction)) {
				updateContext({
					activeSessionActions: [
						...resourceManagerContext.activeSessionActions.filter(
							({ session: prevSession }) =>
								prevSession.sessionId !== initiatedAction.session.sessionId
						),
						initiatedAction as ActiveSessionAction,
					],
				});
			} else if (isInEnum(action, MachineAction)) {
				updateContext({
					activeMachineActions: [
						...resourceManagerContext.activeMachineActions.filter(
							({ machine }) => machine.machineId !== initiatedAction.machine.machineId
						),
						initiatedAction as ActiveMachineAction,
					],
				});
			} else if (isInEnum(action, LaunchAction)) {
				updateContext({
					activeLaunchActions: [
						...resourceManagerContext.activeLaunchActions.filter(
							({ session: prevSession }) =>
								prevSession.sessionId !== initiatedAction.session.sessionId
						),
						initiatedAction as ActiveLaunchAction,
					],
				});
			}
		},
		[
			updateContext,
			resourceManagerContext?.activeSessionActions,
			resourceManagerContext.activeMachineActions,
			resourceManagerContext.activeLaunchActions,
		]
	);

	const onDesktopActionAfterConfirmation = useCallback(
		async (action: DesktopAction, session: Session) => {
			let response = null;
			try {
				captureActionResponse(action, session, response);
				response = await performDesktopAction({
					action,
					session,
					machine: session.machineData,
					workspaceConfiguration,
					resourceContext,
				});
				captureActionResponse(action, session, response);
			} catch (error) {
				notifyError(
					t(`Workspace:activity_manager.failure_operations.${action}`, {
						resourceName: session.applications[0].name,
					})
				);
			}
		},
		[workspaceConfiguration, resourceContext, captureActionResponse]
	);

	const onDesktopAction = useCallback(
		async (action: DesktopAction, session: Session) => {
			const confirmationDetails = getConfirmationDetails(action, session);
			if (confirmationDetails) {
				const { confirmButtonText, title, description, note, confirmModal } =
					confirmationDetails;
				confirmModal({
					message: <MessageComponent description={description} note={note} />,
					confirmButtonText,
					title,
					onCloseSuccess: () => onDesktopActionAfterConfirmation(action, session),
				});
			} else {
				onDesktopActionAfterConfirmation(action, session);
			}
		},
		[onDesktopActionAfterConfirmation]
	);

	const onApplicationAction = useCallback(
		async (action: ApplicationAction, application: ResourceDetails, session: Session) => {
			await makeApplicationAction({
				action,
				session,
				workspaceConfiguration,
				application,
			});
		},
		[workspaceConfiguration]
	);

	const initializeLoading = useCallback(
		isSilentLoad => {
			if (!isSilentLoad) {
				updateContext({ ...loadingSessions });
			}
		},
		[updateContext]
	);

	const fetchData = useCallback(
		async (isSilentLoad = false) => {
			try {
				initializeLoading(isSilentLoad);
				const nativeSessions = await fetchAndUpdateNativeSessions();
				const { deviceId, localSessions, remoteSessions, hibernateSessions } =
					await fetchAndCategorizeRemoteSessions(
						workspaceConfiguration,
						resourceContext?.resources || []
					);
				const updatedNativeSessions = mergeNativeAndLocalSessions(
					nativeSessions,
					localSessions
				);
				updateContext({
					deviceId,
					localSessions: {
						loading: false,
						sessions: updatedNativeSessions,
					}, // TODO: should we keep both local and native sessions?
					remoteSessions: { loading: false, sessions: remoteSessions },
					hibernatedSessions: { loading: false, sessions: hibernateSessions },
				});
			} catch (error) {
				logError('Error fetching sessions:', error);
				updateContext({
					localSessions: { loading: false, sessions: [] },
					remoteSessions: { loading: false, sessions: [] },
					hibernatedSessions: { loading: false, sessions: [] },
				});
			}
		},
		[workspaceConfiguration, resourceContext?.resources, updateContext]
	);

	const onRefresh = useCallback(
		(isSilentLoad = false) => {
			fetchData(isSilentLoad);
		},
		[resourceContext?.resources, workspaceConfiguration, fetchData]
	);

	const initiateResourceManagerContext = useCallback(async () => {
		const hasUserLoggedIn = await userContext.hasLoggedIn();
		if (hasUserLoggedIn) {
			setHasInitiated(() => {
				fetchData(true);
				return true;
			});
		}
	}, [userContext]);

	useEffect(() => {
		if (!resourcesLoading && shouldInitiateOnLoad && !hasInitiated) {
			initiateResourceManagerContext();
		}
	}, [resourceContext, workspaceConfiguration]);

	useEffect(() => {
		updateContext({
			refresh: onRefresh,
			onDesktopAction,
			onApplicationAction,
		});
	}, [onRefresh, onDesktopAction, onApplicationAction]);

	return (
		<ResourceManagerContext.Provider value={resourceManagerContext}>
			{children}
		</ResourceManagerContext.Provider>
	);
};

export const ResourceManagerProvider: React.FC = props => {
	const flag = useFeatureCanary(FeatureFlag.ActivityManagerRefactoring);

	return flag ? <_ResourceManagerProvider {...props} /> : <>{props.children}</>;
};
