import {
	_delete,
	addRequestInterceptor,
	addResponseInterceptor,
	ClientInstance,
	get,
	getWithFullResponse,
	head,
	patch,
	patchWithFullResponse,
	post,
	postAsFormData,
	postWithFullResponse,
	put,
	putWithFullResponse,
	request,
	requestWithFullResponse,
} from '@citrite/http';

export const defaultClient: ClientInstance = {
	get,
	getWithFullResponse,
	post,
	postAsFormData,
	postWithFullResponse,
	put,
	putWithFullResponse,
	patch,
	patchWithFullResponse,
	request,
	requestWithFullResponse,
	head,
	delete: _delete,
	deleteWithFullResponse: _delete,
	addRequestInterceptor,
	addResponseInterceptor,
};
