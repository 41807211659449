import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	clientType,
	getClientType,
	getClientVersion,
	getSaasLaunchCapability,
} from 'Environment/getClientType';
import { isAppShortcutLaunch } from 'Environment/launchResource/launchUtil';
import { getFromSessionStorage } from 'javascript/sf/Storage';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { launchMethod } from './constants';
import {
	isCitrixChromeApp,
	isMac,
	isMobileOrTabletDevice,
	isProtocolHandlerSupported,
	isUserAgentMatchedByRegex,
	isWindowsPlatform,
} from './device';

export function getLaunchMethod(config: WorkspaceConfiguration): launchMethod {
	if (isCurrentClientHtml5(config)) {
		return launchMethod.html5;
	} else if (isNativeClientDetected(config)) {
		// The protocol handler is used for launch only when it has successfully detected the client.
		// When the user has indicated a client is installed (but none is detected) then ICA file download is used.
		return launchMethod.protocolHandler;
	}

	return launchMethod.icaFile;
}

function isNativeClientDetected(config: WorkspaceConfiguration): boolean {
	return (
		isProtocolHandlerSupported(config) &&
		isProtocolHandlerEnabled(config) &&
		!!getClientVersion()
	);
}

function isProtocolHandlerEnabled(config: WorkspaceConfiguration) {
	return config.pluginAssistant.protocolHandler.enabled === 'true';
}

function isCurrentClientHtml5(config: WorkspaceConfiguration) {
	if (isHTML5Mandatory(config)) {
		return true;
	}

	if (!isHTML5Available(config)) {
		return false;
	}

	return (
		isHTML5ClientPreferred() ||
		(!isNativeClientDetected(config) && !isNativeClientPreferred())
	);
}

export function isHTML5Mandatory(config: WorkspaceConfiguration) {
	const forcedByConfig = getSupportedPlatforms(config) === 'Force';
	const useHtml5ClientAlways =
		getEnabled(config) === 'Always' && isSupportedPlatform(config);

	return forcedByConfig || useHtml5ClientAlways || isCitrixChromeApp();
}

export function isHTML5Available(config: WorkspaceConfiguration) {
	return (
		isHTML5Mandatory(config) ||
		(getEnabled(config) === 'Fallback' && isSupportedPlatform(config))
	);
}

function getSupportedPlatforms(config: WorkspaceConfiguration) {
	return config.pluginAssistant.html5.platforms || '';
}

function getEnabled(config: WorkspaceConfiguration) {
	return config.pluginAssistant.html5.enabled;
}

function isSupportedPlatform(config: WorkspaceConfiguration) {
	const supportedPlatforms = getSupportedPlatforms(config);
	return !supportedPlatforms || isUserAgentMatchedByRegex(supportedPlatforms);
}

function isHTML5ClientPreferred() {
	return getClientType() === clientType.html5;
}

function isNativeClientPreferred() {
	return getClientType() === clientType.native;
}

export function canUseProtocolHandler(config: WorkspaceConfiguration) {
	return isProtocolHandlerEnabled(config) && isProtocolHandlerSupported(config);
}

export function canDownloadWorkspace(config: WorkspaceConfiguration) {
	// Note unless the admin has explicitly set config.pluginAssistant.enabled
	// to "false", we allow the clients to download the workspace
	return (
		config.pluginAssistant.enabled?.toLowerCase() !== 'false' &&
		(isWindowsPlatform() || isMac() || isMobileOrTabletDevice())
	);
}

export const autoLaunchedDoneKey = 'autoLaunchedDoneKey';

export function canAutoLaunchSingleDesktop(
	config: WorkspaceConfiguration,
	resources: Resource[]
) {
	const autoLaunchEnabledInConfig = config.userInterface.autoLaunchDesktop === 'true';
	const enabledDesktops = resources.filter(r => r.isdesktop && !r.disabled);
	const singleDesktopAvailable = enabledDesktops.length === 1;

	return autoLaunchEnabledInConfig && singleDesktopAvailable && !isAppShortcutLaunch();
}

export function getLaunchSingleDesktop(
	config: WorkspaceConfiguration,
	resources: Resource[]
) {
	const enabledDesktops = resources.filter(r => r.isdesktop && !r.disabled);
	const hasAlreadyLaunched = getFromSessionStorage<boolean>(autoLaunchedDoneKey);
	if (
		!environment.isNative &&
		canAutoLaunchSingleDesktop(config, resources) &&
		!hasAlreadyLaunched
	) {
		return enabledDesktops[0];
	}

	return undefined;
}

export function shouldUseNativeForSaasLaunch(config: WorkspaceConfiguration) {
	return (
		hasFeatureCanary(config, FeatureFlag.SaasAppLaunchUsingNative) &&
		(isWindowsPlatform() || isMac()) &&
		!isHTML5Mandatory(config) &&
		(getEnabled(config) === 'Off' || isNativeClientPreferred())
	);
}

export function doesNativeSupportSaasLaunch() {
	return getSaasLaunchCapability() != null;
}
