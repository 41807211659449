import { ActivityDesktopSolidIcon, AppsIcon } from '@citrite/web-ui-component';
import { machineListCache } from 'App/Activity/MachineStateManager';
import {
	ActionStatus,
	MachineRegistrationState,
	PowerState,
} from 'App/Activity/Network/ActionsUtil';
import { ActiveMachineAction, MachineAction } from 'App/Activity/ResourceManagerContext';
import { ResourceType as ResourceType2 } from 'App/Tile/ResourceDetails';
import { ResourceLaunchProgressMap } from 'Workspace/ResourceProvider/Context';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export const getDefaultSessionIcon = (resource: Resource) => {
	return resource?.type === ResourceType2.Desktop ? ActivityDesktopSolidIcon : AppsIcon;
};

export const getTimestamp = (isoTime: string) => {
	let timestamp = new Date(isoTime).getTime();
	if (isNaN(timestamp)) {
		timestamp = 0;
	}
	return timestamp;
};

export const isStaleMachineData = (lastUpdatedTime: string) => {
	const beforeFifteenMinsFromNow = Date.now() - 15 * 60 * 1000;
	return getTimestamp(lastUpdatedTime) < beforeFifteenMinsFromNow;
};

const getPowerMachineActionCompletionStatus = (machineAction: ActiveMachineAction) => {
	if (machineAction.action === MachineAction.HIBERNATE) {
		return PowerState.SUSPENDED;
	} else {
		return PowerState.OFF;
	}
};

export const updateMachineListCache = (machineAction: ActiveMachineAction) => {
	const shouldRefreshMachineList =
		machineAction &&
		machineAction.response?.isSuccess === true &&
		machineAction.response.actionStatus === ActionStatus.COMPLETED;
	if (!shouldRefreshMachineList) {
		return false;
	}
	const isMachineListCacheUpdated = machineListCache.updateMachine(
		{ machineId: machineAction.machine?.machineId },
		{
			powerState: getPowerMachineActionCompletionStatus(machineAction),
			registrationState: MachineRegistrationState.UNREGISTERED,
			lastUpdatedTime: new Date().toISOString(),
		}
	);

	return isMachineListCacheUpdated;
};

export const getLaunchProgressOperationId = (
	resourceId: string,
	launchProgress: ResourceLaunchProgressMap
) => {
	return Object.keys(launchProgress).find(id => {
		const progress = launchProgress[id];
		return progress.resourceId === resourceId;
	});
};
