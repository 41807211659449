import { useEffect, useState } from 'react';
import { first } from 'lodash';
import { machineListCache } from 'App/Activity/MachineStateManager';
import { PowerState } from 'App/Activity/Network/ActionsUtil';
import {
	Machine,
	MachineData,
	MachineError,
	Session,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { isStaleMachineData } from 'App/Activity/Utility';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const validPowerStates = [
	PowerState.UNKNOWN,
	PowerState.UNMANAGED,
	PowerState.UNAVAILABLE,
	PowerState.OFF,
	PowerState.ON,
	PowerState.SUSPENDED,
	PowerState.TURNING_ON,
	PowerState.TURNING_OFF,
	PowerState.SUSPENDING,
	PowerState.RESUMING,
	PowerState.NOT_SUPPORTED,
	PowerState.VIRTUAL_MACHINE_NOT_FOUND,

	PowerState.LOADING,
	PowerState.STARTING,
];

const getLatestMachineData = (
	machineDataFromMachineApi: MachineData,
	machineDataFromSessionApi: MachineData
) => {
	let validMachineData = machineDataFromMachineApi;

	if (
		!validMachineData ||
		(machineDataFromSessionApi?.lastUpdatedTime &&
			machineDataFromMachineApi?.lastUpdatedTime <
				machineDataFromSessionApi.lastUpdatedTime)
	) {
		validMachineData = machineDataFromSessionApi;
	}

	if (isStaleMachineData(validMachineData?.lastUpdatedTime)) {
		validMachineData = null;
	}
	return validMachineData;
};

const getResourceState = (
	resource: Resource,
	machines: (Machine | MachineError)[],
	sessions: Session[]
) => {
	const machineDataFromMachineApi = (
		machines?.find(({ resourceId }) => resourceId === resource.id) as Machine
	)?.machineData;
	const machineDataFromSessionApi = sessions.find(
		session => first(session.applications)?.resource?.id === resource.id
	)?.machineData;

	const latestMachineData = getLatestMachineData(
		machineDataFromMachineApi,
		machineDataFromSessionApi
	);

	return {
		powerState: latestMachineData?.powerState || PowerState.UNKNOWN,
		latestTimeStamp: latestMachineData?.lastUpdatedTime || null,
	};
};

export const useResourceState = (resource: Resource) => {
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);
	const context = useResourceManagerContext();
	const [state, setState] = useState<PowerState>(PowerState.NOT_SUPPORTED);
	const [lastUpdatedTime, setLastUpdatedTime] = useState<string | null>(null);

	useEffect(() => {
		let newState: PowerState;
		if (
			!resource.canquerymachinestate ||
			!resource.isdesktop ||
			!isMachinePowerStateEnabled
		) {
			newState = PowerState.NOT_SUPPORTED;
			setLastUpdatedTime(null);
		} else if (
			context?.machineStatus?.loading ||
			context?.localSessions?.loading ||
			context?.remoteSessions?.loading ||
			context?.hibernatedSessions?.loading
		) {
			newState = PowerState.LOADING;
			setLastUpdatedTime(null);
		} else {
			//context data is not updated as per the latest data, so we are using cache data
			const machineData = getResourceState(
				resource,
				machineListCache.getAllMachines()?.machines,
				[
					...context?.localSessions?.sessions,
					...context?.remoteSessions?.sessions,
					...context?.hibernatedSessions?.sessions,
				]
			);
			newState = machineData?.powerState;
			setLastUpdatedTime(machineData?.latestTimeStamp);
		}
		if (!validPowerStates.includes(newState)) {
			newState = PowerState.NOT_SUPPORTED;
		}
		setState(newState);
	}, [
		context?.machineStatus,
		context?.localSessions,
		context?.remoteSessions,
		context?.hibernatedSessions,
		resource,
		isMachinePowerStateEnabled,
	]);

	return { state, lastUpdatedTime };
};
