import { post } from '@citrite/http';
import { t } from '@citrite/translate';
import { notifyError } from '@citrite/web-ui-component';
import {
	hasFeatureCanary,
	WorkspaceConfiguration,
	workspaceUser as workspaceUserAccessor,
} from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent, trackEvent } from 'analytics';
import { logError } from 'remoteLogging';
import { v4 } from 'uuid';
import { _aot, AotStrings } from 'App/AotTrace';
import {
	getLocalAppsFromStorage,
	removeLocalApp,
	updateResourceContextWithLocalApps,
} from 'App/LocalSystemApps';
import { environment } from 'Environment';
import { isBrowserExtensionError } from 'Environment/BrowserExtension/browserExtensionMessageHelper';
import { isChromeAppError } from 'Environment/ChromeApp/ChromeAppMessageHelper';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { clientType, getClientType } from 'Environment/getClientType';
import {
	checkLaunchPreference,
	leaseLaunchForHTML5,
} from 'Environment/Html5/Html5LeaseLaunch';
import { showAppProtectionErrorModal } from 'Environment/launchResource/AppProtectionError';
import { ChromeAppLaunchOptions } from 'Environment/launchResource/chromeApp';
import { errorWithCopyTransactionIdButton } from 'Environment/launchResource/launchResourceErrors';
import { updateMachinePowerStatus } from 'Environment/launchResource/launchUtil';
import { isStoreFrontFallbackEnabled } from 'ErrorPage/StoreFrontFallback';
import { isHTML5ShieldEnabled } from 'LeaseWorkflow';
import { isCitrixBrowserApp } from 'Workspace/AppContext/citrixBrowserResource';
import { fallbackToPreviousClientType } from 'Workspace/BrowserExtension/fallbackToPreviousClientType';
import { createCasPublisher } from 'Workspace/CAS/createCasPublisher';
import { ResourceContext } from 'Workspace/ResourceProvider';
import {
	LaunchPreference,
	localAppResourceType,
	Resource,
} from 'Workspace/ResourceProvider/resourceTypes';
import {
	browserExtensionAnalyticsReporter,
	trackBrowserExtensionError,
} from 'Workspace/TelemetryEvents/browserExtension/createBrowserExtensionAnalyticsReporter';
import { createResourceSessionCasReporter } from 'Workspace/TelemetryEvents/cvadLaunch/createResourceSessionCasReporter';

export interface LaunchOptions {
	isAutoLaunch?: boolean;
	chromeAppOptions?: ChromeAppLaunchOptions;
}

export interface LaunchParams {
	resource: Resource;
	resourceContext: ResourceContext;
	workspaceConfiguration: WorkspaceConfiguration;
	launchOptions?: LaunchOptions;
	params?: any;
	targetWindow?: Window;
}

export enum LaunchStatus {
	CheckingAvailability = 'checking-availability',
	PerformingLaunch = 'performing-launch',
	PoweringOn = 'powering-on',
	Cancelling = 'cancelling',
	Completed = 'completed',
	AwaitingRegistration = 'awaiting-registration',
}

export enum CancellationReason {
	UserCancellation = 'user-cancellation',
	NetworkGateway = 'network-gateway',
	NetworkVDA = 'network-vda',
	HDXHandshake = 'hdx-handshake',
	LaunchCancellationOther = 'launch-cancellation-other',
}

export async function handleLaunchFailures(
	options: LaunchParams,
	workspaceConfiguration: WorkspaceConfiguration,
	error?: Error,
	transactionId?: string
) {
	const shouldUseStoreFrontFallback = isStoreFrontFallbackEnabled(workspaceConfiguration);

	switch (error?.message) {
		case 'CouldNotConnectToWorkstation':
			errorWithCopyTransactionIdButton(
				t('Workspace:desktop_launch_failures.CouldNotConnectToWorkstation'),
				transactionId
			);
			break;
		case 'NoMoreActiveSessions':
			errorWithCopyTransactionIdButton(
				t('Workspace:desktop_launch_failures.NoMoreActiveSessions'),
				transactionId
			);
			break;
		case 'UnavailableDesktop':
			errorWithCopyTransactionIdButton(
				t('Workspace:desktop_launch_failures.UnavailableDesktop'),
				transactionId
			);
			break;
		case 'UnavailableAppProtectedDesktop':
			//this will be received only in case of app protected resource launch failure.
			if (hasFeatureCanary(workspaceConfiguration, FeatureFlag.BrowserAppProtection)) {
				//if client type is html5, user is shown with modal for referring to help page
				if (getClientType() === clientType.html5) {
					showAppProtectionErrorModal({
						title: t('Workspace:app_Protection_launch_failures.Session_Failed_To_Launch'),
						message: t('Workspace:app_Protection_launch_failures.Refer_Help_Page'),
						linkMessage: t('Workspace:app_Protection_launch_failures.Help'),
						linkUrl: 'https://support.citrix.com/article/CTX327882',
					});
				} else {
					//if client type is native , then user is shown with modal to use browser extension.
					showAppProtectionErrorModal({
						title: t('Workspace:app_Protection_launch_failures.Web_Extension_Required'),
						message: t('Workspace:app_Protection_launch_failures.Use_Browser_Extension'),
						linkMessage: t('Workspace:app_Protection_launch_failures.Help'),
						linkUrl: 'https://support.citrix.com/article/CTX327881',
					});
				}
			} else {
				errorWithCopyTransactionIdButton(
					t('Workspace:desktop_launch_failures.UnavailableDesktop'),
					transactionId
				);
			}
			break;
		case 'WorkstationInMaintenance':
			errorWithCopyTransactionIdButton(
				t('Workspace:desktop_launch_failures.WorkstationInMaintenance'),
				transactionId
			);
			break;
		case 'GeneralAppLaunchError':
			if (shouldUseStoreFrontFallback) {
				await showStoreFrontFallbackError(workspaceConfiguration);
			} else {
				errorWithCopyTransactionIdButton(t('Workspace:app_failed_launch'), transactionId);
			}
			break;
		default:
			logError(error, {
				tags: {
					feature: 'apps-and-desktops',
				},
			});
			if (shouldUseStoreFrontFallback) {
				await showStoreFrontFallbackError(workspaceConfiguration);
			} else if (options.launchOptions?.isAutoLaunch) {
				errorWithCopyTransactionIdButton(
					t('Workspace:resource_autolaunch_failure'),
					transactionId
				);
			} else {
				errorWithCopyTransactionIdButton(t('Workspace:app_failed_launch'), transactionId);
			}
	}
}

async function showStoreFrontFallbackError(workspaceConfig: WorkspaceConfiguration) {
	const { showStoreFrontFallbackErrorModal } = await import('./StorefrontFallbackError');
	showStoreFrontFallbackErrorModal(workspaceConfig.userInterface.fallbackConfiguration);
}

const notifyLaunchCancellation = (
	launchCancellationToken: string,
	cancellationUrl: string
) => {
	return post<any>(cancellationUrl, {
		LaunchCancellationToken: launchCancellationToken,
		CancellationReason: CancellationReason.UserCancellation,
		CancellationReasonText: 'User cancelled the launch from Workspace UI',
	}).catch(error => logError(error));
};

const generateLaunchProgressHandler = (
	operationId: string,
	resourceContext: ResourceContext,
	resourceId: string,
	onLaunchCancelled: () => void
) => {
	const cancellationUrl = resourceContext.resources.find(
		resource => resource.id === resourceId
	)?.cancellaunch;
	const launchCancellationToken = cancellationUrl ? v4() : undefined;
	let isCanceled = false;
	const onCancel = () => {
		if (cancellationUrl) {
			notifyLaunchCancellation(launchCancellationToken, cancellationUrl);
		}
		isCanceled = true;
		onLaunchCancelled();
	};
	const validateLaunchNextStep = (status: LaunchStatus) => {
		if (isCanceled) {
			return false;
		}
		resourceContext.updateSession(state => ({
			launchProgress: {
				...state.launchProgress,
				[operationId]: { status, onCancel, resourceId },
			},
		}));
		return true;
	};
	const launchProgressHandler = {
		validateLaunchNextStep,
		launchCancellationToken,
	};
	return launchProgressHandler;
};

export async function launchResource(params: LaunchParams) {
	const { resource, workspaceConfiguration, resourceContext, ...launchParams } = params;
	if (resourceContext.isInProgress(resource.id)) {
		return Promise.resolve();
	}
	resourceContext.updateSession(state => ({
		launchInProgressIds: [...state.launchInProgressIds, resource.id],
	}));

	if (isCitrixBrowserApp(resource)) {
		await environment.launchCitrixWorkspaceBrowserApp({ resourceID: resource.id });
		postLaunchStep(resourceContext, resource.id);
	} else if (resource.type === localAppResourceType) {
		try {
			_aot.info(
				AotStrings.launch.category,
				AotStrings.launch.localAppLaunch,
				resource.name
			);
			await environment.launchLocalApp(resource, {
				path: resource.launchurl,
			});
		} catch (error) {
			_aot.error(
				AotStrings.launch.category,
				AotStrings.launch.localAppLaunchFailed,
				error,
				resource.name
			);
			if (error === 'ErrorFileNotFound') {
				await removeLocalApp(resource.id);
				const localAppsAfterRemove = await getLocalAppsFromStorage();
				updateResourceContextWithLocalApps(resourceContext, localAppsAfterRemove);
				notifyError(t('Workspace:localapp_launch_notfound'));
			}
		} finally {
			trackEvent('LocalAppLaunch');
			//Local apps launch immediately, but a short loading indicator provides more tactile feedback for app launch,
			//aligns with CVAD behavior, and helps prevent accidental double clicks
			await new Promise(resolve => {
				setTimeout(resolve, 1000);
			});
			resourceContext.updateSession(state => ({
				launchInProgressIds: state.launchInProgressIds.filter(id => id !== resource.id),
			}));
		}
	} else {
		_aot.info(AotStrings.launch.category, AotStrings.launch.icaAppLaunch, resource.name);
		const operationId = v4();

		const workspaceUser = workspaceUserAccessor.get();
		const eventPublisher = createCasPublisher(
			workspaceConfiguration,
			workspaceUser.userDetails
		);
		const {
			getStartEventPayload,
			getEndEventPayload,
			getErrorEventPayload,
			getCancelEventPayload,
		} = createResourceSessionCasReporter(
			operationId,
			resource.id,
			workspaceUser.userDetails.userId
		);

		const launchProgressHandler = generateLaunchProgressHandler(
			operationId,
			resourceContext,
			resource.id,
			() => eventPublisher.publishEvent(getCancelEventPayload())
		);
		const { validateLaunchNextStep } = launchProgressHandler;
		const preferLeaseLaunch = resourceContext.preferLeaseLaunchIds.includes(resource.id);
		if (
			getClientType() === clientType.browserextension &&
			(await resourceContext.shouldBrowserExtensionLaunchResource(resource)) === true
		) {
			try {
				await resourceContext.tryLaunchViaBrowserExtension({
					...launchParams,
					resource,
					workspaceConfiguration,
					preferLeaseLaunch,
					launchProgressHandler,
				});
				validateLaunchNextStep(LaunchStatus.Completed);
				postLaunchStep(resourceContext, resource.id);
				return;
			} catch (error) {
				_aot.error(
					AotStrings.launch.category,
					AotStrings.launch.icaAppLaunchFailedWithBE,
					error,
					resource.name
				);
				if (isBrowserExtensionError(error)) {
					logError(error, {
						tags: {
							feature: 'browserExtension',
							status: error['status'],
						},
					});

					if (!fallbackToPreviousClientType()) {
						// If there is no previous client type, we set it to native
						logError(new Error('No previous client type for fallback.'), {
							additionalContext: {
								error,
							},
							tags: {
								feature: 'browserExtension',
							},
						});
					}
					trackBrowserExtensionError(error);
				} else {
					trackBrowserExtensionError(error);
					return handleLaunchFailures(params, workspaceConfiguration, error).then(() =>
						postLaunchStep(resourceContext, resource.id)
					);
				}
			} finally {
				trackAnalyticsEvent(
					browserExtensionAnalyticsReporter.getBrowserExtensionAppLaunchEvent()
				);
			}
		} else if (
			getClientType() === clientType.chromeapp &&
			(await resourceContext.shouldChromeAppLaunchResource(resource)) === true
		) {
			try {
				await resourceContext.tryLaunchViaChromeApp({
					...launchParams,
					resource,
					workspaceConfiguration,
					preferLeaseLaunch,
					operationId,
					launchProgressHandler,
				});
				validateLaunchNextStep(LaunchStatus.Completed);
				postLaunchStep(resourceContext, resource.id);
				return;
			} catch (error) {
				_aot.error(
					AotStrings.launch.category,
					AotStrings.launch.icaAppLaunchFailedWithChromeApp,
					error,
					resource.name
				);
				if (isChromeAppError(error)) {
					logError(error, {
						tags: {
							feature: 'chromeapp',
							status: error['status'],
						},
					});

					if (!fallbackToPreviousClientType()) {
						// If there is no previous client type, we set it to native
						logError(new Error('No previous client type for fallback.'), {
							additionalContext: {
								error,
							},
							tags: {
								feature: 'chromeapp',
							},
						});
					}
				} else {
					return handleLaunchFailures(params, workspaceConfiguration, error).then(() =>
						postLaunchStep(resourceContext, resource.id)
					);
				}
			} finally {
				trackEvent('ChromeAppLaunch');
			}
		} else if (isHTML5ShieldEnabled(workspaceConfiguration)) {
			if (
				!environment.citrixCloudConnected ||
				checkLaunchPreference(resource, LaunchPreference.LeaseOnly)
			) {
				try {
					await leaseLaunchForHTML5({
						...launchParams,
						resource,
						workspaceConfiguration,
						preferLeaseLaunch,
						launchProgressHandler,
					});
					validateLaunchNextStep(LaunchStatus.Completed);
					postLaunchStep(resourceContext, resource.id);
					return;
				} catch (error) {
					_aot.error(
						AotStrings.launch.category,
						AotStrings.launch.shieldLaunchFailed,
						error,
						resource.name
					);
					return handleLaunchFailures(params, workspaceConfiguration, error).then(() =>
						postLaunchStep(resourceContext, resource.id)
					);
				} finally {
					trackEvent('Html5ShieldLaunch');
				}
			}
		}

		eventPublisher.publishEvent(getStartEventPayload());
		return environment
			.launchResource({
				...launchParams,
				resource,
				workspaceConfiguration,
				preferLeaseLaunch,
				operationId,
				launchProgressHandler,
				...(resourceContext.citrixBrowserResourceID && {
					citrixBrowserResourceID: resourceContext.citrixBrowserResourceID,
				}),
			})
			.then(() => {
				const isValid = launchProgressHandler.validateLaunchNextStep(
					LaunchStatus.Completed
				);
				if (isValid) {
					eventPublisher.publishEvent(getEndEventPayload());
				}
			})
			.catch(async error => {
				_aot.error(
					AotStrings.launch.category,
					AotStrings.launch.icaAppLaunchFailed,
					error,
					resource.name
				);
				eventPublisher.publishEvent(getErrorEventPayload());
				return handleLaunchFailures(params, workspaceConfiguration, error, operationId);
			})
			.then(() => {
				postLaunchStep(resourceContext, resource.id);
			});
	}
}

function postLaunchStep(context: ResourceContext, resourceId: string) {
	if (!IS_ON_PREM) {
		context.makeResourceMostRecent(resourceId);
	}
	updateMachinePowerStatus(resourceId);
	context.updateSession(state => ({
		launchInProgressIds: state.launchInProgressIds.filter(id => id !== resourceId),
		launchProgress: Object.keys(state.launchProgress).reduce(
			(launchProgress, operationId) => {
				if (state.launchProgress[operationId].resourceId !== resourceId) {
					launchProgress[operationId] = state.launchProgress[operationId];
				}
				return launchProgress;
			},
			{}
		),
	}));
}
