import { palette, PaletteName } from '@citrite/web-ui-component';
import {
	hasFeatureCanary,
	StoreBranding,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { UserBrandingFormat } from '../Workspace/UserContext/Context';

export interface BrandingStyles {
	AccentColor: string;
	HeaderBackgroundColor: string;
	HeaderTextColor: string;
}

export interface WorkspaceLinks {
	HeaderLogoUrl?: string;
	LoginLogoUrl?: string;
}

export interface AccountBranding {
	styles: BrandingStyles;
	links: WorkspaceLinks;
	paletteName: PaletteName;
}

const legacyDefaultBranding: StoreBranding = {
	backgroundColor: '#ffffff',
	linkColor: '#2071c5',
	textColor: '#333333',
};

const defaultCitrixCloudBranding: StoreBranding = {
	backgroundColor: palette.teal100,
	linkColor: palette.teal700,
	textColor: palette.teal900,
};

const defaultBranding: StoreBranding = {
	backgroundColor: palette.blue100,
	linkColor: palette.blue800,
	textColor: palette.blue900,
};

const getDefaultCitrixBranding = (workspaceConfiguration: WorkspaceConfiguration) =>
	hasFeatureCanary(workspaceConfiguration, FeatureFlag.AllowBlueTheme)
		? defaultBranding
		: defaultCitrixCloudBranding;
const brandingPolicyCountKey = 'brandingPolicyCount';

function canUseCitrixBrandTheme(workspaceConfiguration: WorkspaceConfiguration) {
	const workspaceBranding = workspaceConfiguration?.userInterface?.branding || {};
	const workspaceBrandingKeys = Object.keys(workspaceBranding).filter(
		key => key !== brandingPolicyCountKey
	);

	if (!workspaceBrandingKeys.length) {
		return true;
	}

	const isNeutralDefault = workspaceBrandingKeys.every(
		brandingKey => workspaceBranding[brandingKey] === legacyDefaultBranding[brandingKey]
	);

	//Customers with neutral default branding should be switched to rebranded default
	if (isNeutralDefault) {
		return true;
	}

	//Makes sure to set correct palette if customer has defaultCitrixBrand
	const defaultCitrixBranding = getDefaultCitrixBranding(workspaceConfiguration);
	return workspaceBrandingKeys.every(
		brandingKey => workspaceBranding[brandingKey] === defaultCitrixBranding[brandingKey]
	);
}

function fixWorkspaceBranding(workspaceBranding: StoreBranding) {
	const adjustedBranding = workspaceBranding;
	if (adjustedBranding.backgroundColor === legacyDefaultBranding.backgroundColor) {
		adjustedBranding.backgroundColor = palette.white;
	}
	if (adjustedBranding.linkColor === legacyDefaultBranding.linkColor) {
		adjustedBranding.linkColor = palette.blue800;
	}
	if (adjustedBranding.textColor === legacyDefaultBranding.textColor) {
		adjustedBranding.textColor = palette.grey900;
	}
	return adjustedBranding;
}

export function createWorkspaceBranding(
	userBranding: Partial<UserBrandingFormat> = {},
	workspaceConfiguration: WorkspaceConfiguration
): AccountBranding {
	const userPolicyBranding = userBranding || ({} as Partial<UserBrandingFormat>);
	const workspaceConfigBranding = workspaceConfiguration?.userInterface?.branding || {};
	const isCitrixBrandTheme = canUseCitrixBrandTheme(workspaceConfiguration);
	const allowBlueTheme = hasFeatureCanary(
		workspaceConfiguration,
		FeatureFlag.AllowBlueTheme
	);
	const workspaceBranding = allowBlueTheme
		? workspaceConfigBranding
		: isCitrixBrandTheme
		? getDefaultCitrixBranding(workspaceConfiguration)
		: fixWorkspaceBranding(workspaceConfigBranding);

	return {
		paletteName:
			allowBlueTheme || !isCitrixBrandTheme
				? PaletteName.neutral
				: PaletteName.citrixbrand,
		styles: {
			AccentColor:
				userPolicyBranding.linkColor ||
				workspaceBranding.linkColor ||
				(allowBlueTheme ? defaultBranding.linkColor : palette.blue800),
			HeaderBackgroundColor:
				userPolicyBranding.backgroundColor ||
				workspaceBranding.backgroundColor ||
				(allowBlueTheme ? defaultBranding.backgroundColor : palette.white),
			HeaderTextColor:
				userPolicyBranding.textColor ||
				workspaceBranding.textColor ||
				(allowBlueTheme ? defaultBranding.textColor : palette.grey900),
		},
		links: {
			HeaderLogoUrl: userPolicyBranding.headerLogo || workspaceBranding.headerLogo,
			LoginLogoUrl: userPolicyBranding.logonLogo || workspaceBranding.logonLogo,
		},
	};
}
