export interface SessionOperationResponse {
	results: SessionOperationResult[];
}

export interface SessionOperationResult {
	sessionId: string;
	isSuccess: boolean;
	failureReason?: string;
}

export interface MachineOperationResponse {
	continuationId: string;
	retryDelayHint?: string;
	actionStatus: ActionStatus;
	isSuccess: boolean;
	errorId?: string;
	errorValue?: string;
}
export interface LaunchOperationResponse {
	isSuccess: boolean;
}
export interface ResponseData {
	continuationId?: string;
	retryDelayHint?: string;
	actionStatus?: ActionStatus;
	isSuccess?: boolean;
	errorId?: string;
	errorValue?: string;
	results?: SessionOperationResult[];
}
export class SessionOperationRequestData {
	public SessionIds: string[];
	public constructor(resourceIds: string[]) {
		this.SessionIds = resourceIds;
	}
}

export enum ActionStatus {
	PENDING = 'Pending',
	STARTED = 'Started',
	COMPLETED = 'Completed',
	FAILED = 'Failed',
}

export enum SessionSupport {
	SINGLE_SESSION = 'SingleSession',
	MULTI_SESSION = 'MultiSession',
	UNKNOWN = 'Unknown',
}

export enum MachineRegistrationState {
	REGISTERED = 'Registered',
	UNREGISTERED = 'Unregistered',
	INITIALIZING = 'Initializing',
	AGENT_ERROR = 'AgentError',
}

export enum PowerState {
	UNMANAGED = 'Unmanaged',
	UNKNOWN = 'Unknown',
	UNAVAILABLE = 'Unavailable',
	OFF = 'Off',
	ON = 'On',
	SUSPENDED = 'Suspended',
	TURNING_ON = 'TurningOn',
	TURNING_OFF = 'TurningOff',
	SUSPENDING = 'Suspending',
	RESUMING = 'Resuming',
	NOT_SUPPORTED = 'NotSupported',
	VIRTUAL_MACHINE_NOT_FOUND = 'VirtualMachineNotFound',
	LOADING = 'Loading',
	STARTING = 'Starting',
}

export const contentTypeJson = {
	headers: {
		'Content-Type': 'application/json',
	},
};
