import { post } from '@citrite/http';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import Cookies from 'js-cookie';
import { GacsTicketFetcherBase } from 'GlobalAppConfigService/gacsTicketFetcherBase';
import {
	Dependencies,
	DSAuthSecondaryToken,
	GlobalAppConfigResponse,
} from 'GlobalAppConfigService/types';
import {
	CITRIX_CLIENT_URL,
	CSRF_TOKEN,
	X_CITRIX_ISUSINGHTTPS,
} from 'javascript/interceptors/Headers';

export class GacsTicketFetcherOnprem extends GacsTicketFetcherBase {
	private static instance: GacsTicketFetcherOnprem;

	private constructor(dependencies: Dependencies) {
		// Private constructor to prevent direct instantiation with 'new' keyword
		super(dependencies);
	}

	public static getInstance(dependencies: Dependencies): GacsTicketFetcherOnprem {
		if (!GacsTicketFetcherOnprem.instance) {
			GacsTicketFetcherOnprem.instance = new GacsTicketFetcherOnprem(dependencies);
		}
		return GacsTicketFetcherOnprem.instance;
	}

	public isGacsV2Enabled(workspaceConfig: WorkspaceConfiguration): boolean {
		return !!this.getGacsEndpointsUrl(workspaceConfig);
	}

	protected async fetchGacsTicketFromNetwork(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<GlobalAppConfigResponse> {
		const gacsEndpointUrl = this.getGacsEndpointsUrl(workspaceConfiguration);
		if (!gacsEndpointUrl) {
			return null;
		}

		const gacsTokenFetchUrl = await this.getGacsTokenEndpointUrl(gacsEndpointUrl);
		if (!gacsTokenFetchUrl) {
			return null;
		}

		const dsAuthSecondaryToken: DSAuthSecondaryToken = await this.getDsAuthSecondaryToken(
			this.getTokenCreationPath(workspaceConfiguration)
		);
		if (!dsAuthSecondaryToken) {
			return null;
		}

		const gacsToken = await post<GlobalAppConfigResponse>(gacsTokenFetchUrl, null, {
			headers: {
				[CITRIX_CLIENT_URL]: this.getEncodedStoreUrl(dsAuthSecondaryToken),
				Authorization: `CitrixAuth ${dsAuthSecondaryToken.token}`,
			},
			responseType: 'json',
		});

		return gacsToken;
	}

	private async getDsAuthSecondaryToken(tokenCreationUrl: string) {
		try {
			const secondaryToken = await post<DSAuthSecondaryToken>(tokenCreationUrl, null, {
				headers: {
					[CSRF_TOKEN]: Cookies.get('CsrfToken'),
					[X_CITRIX_ISUSINGHTTPS]: window.location.protocol === 'https:' ? 'Yes' : 'No',
				},
				responseType: 'json',
			});
			return secondaryToken;
		} catch (error) {
			this.dependencies.logError(error);
			return null;
		}
	}

	private getGacsEndpointsUrl(workspaceConfiguration: WorkspaceConfiguration): string {
		return workspaceConfiguration?.storeProxy?.gacs?.gacsEndpointsUrl;
	}

	private getTokenCreationPath(workspaceConfiguration: WorkspaceConfiguration): string {
		return `${location.origin}${window.location.pathname}${workspaceConfiguration?.storeProxy?.gacs?.createTokenPath}`;
	}

	private getEncodedStoreUrl(dsAuthSecondaryToken: DSAuthSecondaryToken): string {
		const jsonObject = {
			gatewayURL: dsAuthSecondaryToken.gatewayUrl,
			storefrontURL: dsAuthSecondaryToken.storeUrl,
		};

		return window.btoa(JSON.stringify(jsonObject));
	}

	private async getGacsTokenEndpointUrl(gacsEndpointUrl: string): Promise<string> {
		const endpointSupplier = this.dependencies.createEndpointSupplier(gacsEndpointUrl);

		const gacsTokenEndpointUrl = await endpointSupplier.getEndpoint(
			this.GACS_TOKEN_ENDPOINT_SERVICE
		);

		if (!gacsTokenEndpointUrl) {
			return null;
		}
		return gacsTokenEndpointUrl;
	}
}
