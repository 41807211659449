import * as React from 'react';
import { post } from '@citrite/http';
import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { SessionActivity } from 'App/Common/SessionActivity';
import { Detect } from 'Components/Detection/Detect';
import { Download } from 'Components/Detection/Download';
import { Validate } from 'Components/Detection/Validate';
import { Welcome } from 'Components/Detection/Welcome';
import { FullscreenCard } from 'Components/FullscreenCard/FullscreenCard';
import { getDownloadLink } from 'Components/MandateNativeAppSupport/utils';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	clientType,
	getClientType,
	getClientVersion,
	setClientType,
	setPreviousClientType,
} from 'Environment/getClientType';
import {
	canDownloadWorkspace,
	canUseProtocolHandler,
	isHTML5Available,
	isHTML5Mandatory,
} from 'Environment/launchResource/clientManager';
import {
	currentPlatform,
	isCitrixChromeApp,
	isMac,
	isWindowsPlatform,
} from 'Environment/launchResource/device';
import { launchProtocolHandler } from 'Environment/launchResource/resourceLaunch';
import { monitoring } from 'MonitoringAnalytics';
import { canHaveEmptyResources } from 'utils/emptyResourcesCheck';
import { BrowserExtensionContext } from 'Workspace/BrowserExtension/Context';
import { fallbackToPreviousClientType } from 'Workspace/BrowserExtension/fallbackToPreviousClientType';
import { useBrowserExtension } from 'Workspace/BrowserExtension/useBrowserExtension';
import { ChromeAppContext } from 'Workspace/ChromeApp/ChromeAppContext';
import { useChromeApp } from 'Workspace/ChromeApp/useChromeApp';
import { InitialLoading } from 'Workspace/InitialLoading';
import {
	ResourceContextProvider,
	useLoadableResourceContext,
} from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const pollInterval = 1000;

const Context = React.createContext<DetectionContext>({
	initiateDetection: () => {},
	supportsProtocolHandler: false,
	hasIcaResources: false,
});

interface Props {
	workspaceConfiguration: WorkspaceConfiguration;
	loadableResourceContext: ResourceContextProvider;
	browserExtensionContext: BrowserExtensionContext;
	chromeAppContext: ChromeAppContext;
	shouldDetectCWA: boolean;
	detectClientBeforeAuthentication?: boolean;
}

export enum screenType {
	validate,
	download,
	detect,
	welcome,
}

export interface State {
	currentScreen: screenType;
	clientTypeDetected: boolean;
	waitOnBrowserExtensionCheck: boolean;
	waitOnChromeAppCheck: boolean;
}

export interface DetectionContext {
	initiateDetection(): void;
	supportsProtocolHandler: boolean;
	hasIcaResources: boolean;
}

function didNotDetectHTML5Client() {
	return getClientType() !== 'html5';
}

class _Detection extends React.Component<Props, State> {
	private pollTimer: number;

	public constructor(props: Props) {
		super(props);
		let clientTypeDetected = !!getClientType();
		if (!this.supportsProtocolHandler() && !getClientType()) {
			setClientType(clientType.native);
			clientTypeDetected = true;
		}

		this.state = {
			currentScreen: screenType.welcome,
			clientTypeDetected,
			waitOnBrowserExtensionCheck:
				didNotDetectHTML5Client() &&
				props.browserExtensionContext.isSupportedCustomerRuntime,
			waitOnChromeAppCheck:
				didNotDetectHTML5Client() &&
				props.chromeAppContext.isSupportedCustomerRuntime &&
				hasFeatureCanary(this.props.workspaceConfiguration, FeatureFlag.ChromeAppShield),
		};
	}

	public render() {
		if (
			(this.props.loadableResourceContext.loading &&
				!environment.isNative &&
				!this.state.clientTypeDetected &&
				!this.props.detectClientBeforeAuthentication) ||
			(this.state.clientTypeDetected &&
				(this.state.waitOnBrowserExtensionCheck || this.state.waitOnChromeAppCheck))
		) {
			return <InitialLoading showLoadingMessages />;
		}

		if (!this.needsDetection()) {
			return (
				<Context.Provider
					value={{
						initiateDetection: this.initiateDetection,
						supportsProtocolHandler: this.supportsProtocolHandler(),
						hasIcaResources: this.hasIcaResources(),
					}}
				>
					{this.props.children}
				</Context.Provider>
			);
		} else {
			monitoring.setSessionAttribute('clientDetectionRequired', true);
		}

		return (
			<FullscreenCard>
				{(() => {
					switch (this.state.currentScreen) {
						case screenType.validate:
							return (
								<Validate
									detect={this.detect}
									alreadyInstalled={() => this.useNative()}
									getDownloadUrl={this.getDownloadUrl}
									useHtml5={this.useHtml5}
									disallowAlreadyInstalled={this.disallowAlreadyInstalled()}
									isHtml5Enabled={isHTML5Available(this.props.workspaceConfiguration)}
								/>
							);
						case screenType.download:
							return (
								<Download
									showValidate={this.showValidate}
									getDownloadUrl={this.getDownloadUrl}
									useHtml5={this.useHtml5}
									isHtml5Enabled={isHTML5Available(this.props.workspaceConfiguration)}
								/>
							);
						case screenType.detect:
							return (
								<Detect
									detect={this.detect}
									useHtml5={this.useHtml5}
									alreadyInstalled={() => this.useNative()}
									showDownload={this.showDownload}
									isHtml5Enabled={isHTML5Available(this.props.workspaceConfiguration)}
									disallowAlreadyInstalled={this.disallowAlreadyInstalled()}
									canDownloadWorkspace={canDownloadWorkspace(
										this.props.workspaceConfiguration
									)}
									downloadUrl={this.getDownloadUrl()}
								/>
							);
						case screenType.welcome:
						default:
							return (
								<Welcome
									showDetect={this.showDetect}
									useHtml5={this.useHtml5}
									isHtml5Enabled={isHTML5Available(this.props.workspaceConfiguration)}
								/>
							);
					}
				})()}
				{!environment.isNative && <SessionActivity />}
			</FullscreenCard>
		);
	}

	public componentDidMount() {
		if (this.state.clientTypeDetected) {
			if (isCitrixChromeApp()) {
				this.setState({
					waitOnBrowserExtensionCheck: false,
				});
				if (this.state.waitOnChromeAppCheck) {
					this.checkChromeApp();
				}
			} else {
				this.setState({
					waitOnChromeAppCheck: false,
				});
				if (this.state.waitOnBrowserExtensionCheck) {
					this.checkBrowserExtension();
				}
			}
		}
	}

	public componentDidUpdate(_: Props, prevState: State) {
		if (
			!this.needsDetection() &&
			this.state.currentScreen !== screenType.welcome &&
			!this.state.clientTypeDetected
		) {
			this.showWelcome();
		}
		// This is not a primary use case since browserextension enablement is primarily handled in the
		// ResiliencyBrowserExtensionContextProvider component, but initiateDetection is a method on context
		// that could trigger the detection screen.
		if (!prevState.clientTypeDetected && this.state.clientTypeDetected) {
			if (isCitrixChromeApp()) {
				this.setState({
					waitOnBrowserExtensionCheck: false,
				});
				if (this.state.waitOnChromeAppCheck) {
					this.checkChromeApp();
				}
			} else {
				this.setState({
					waitOnChromeAppCheck: false,
				});
				if (this.state.waitOnBrowserExtensionCheck) {
					this.checkBrowserExtension();
				}
			}
		}
	}

	private showWelcome = () => {
		this.setState({ currentScreen: screenType.welcome });
	};

	private showDetect = () => {
		this.setState({ currentScreen: screenType.detect });
	};

	private showDownload = () => {
		this.setState({ currentScreen: screenType.download });
	};

	private showValidate = () => {
		this.setState({ currentScreen: screenType.validate });
	};

	private async checkBrowserExtension() {
		const extensionConfiguration =
			await this.props.browserExtensionContext.getExtensionConfiguration();
		if (extensionConfiguration.isActive) {
			this.useBrowserExtension();
		} else {
			if (getClientType() === clientType.browserextension) {
				fallbackToPreviousClientType();
			}
			this.setState({
				waitOnBrowserExtensionCheck: false,
			});
		}
	}

	private async checkChromeApp() {
		const chromeAppConfiguration =
			await this.props.chromeAppContext.getChromeAppConfiguration();
		if (chromeAppConfiguration.isActive) {
			this.useChromeApp();
		} else {
			if (getClientType() === clientType.chromeapp) {
				fallbackToPreviousClientType();
			}
			this.setState({
				waitOnChromeAppCheck: false,
			});
		}
	}

	private needsDetection() {
		/* 
		OnPrem specific prevent ICA download check should not be done here since in case of onprem
		we want to show a warning dialog to the end user explaining for prevent ICA download when user launches and app,
		instead of relaunching the Detection flow which is a clunky experience.
		*/
		return (
			!environment.isNative &&
			((this.isClientTypeNativeWithoutVersion() && this.reInitDetectionFlow()) ||
				(this.shouldDetect() &&
					((!getClientType() &&
						(this.hasIcaResources() ||
							canHaveEmptyResources(this.props.workspaceConfiguration))) ||
						(this.props.detectClientBeforeAuthentication &&
							!this.state.clientTypeDetected))))
		);
	}

	private isClientTypeNativeWithoutVersion() {
		return getClientType() === clientType.native && !getClientVersion();
	}

	private reInitDetectionFlow(): boolean {
		/*
			This feature flag will be enabled after the PM confirms the flow required and 
			the detetcion flow is fixed in WSUI. For now we will use the existing experience similar to onprem
		*/
		return hasFeatureCanary(
			this.props.workspaceConfiguration,
			FeatureFlag.ReInitDetectionFlow
		);
	}

	private disallowAlreadyInstalled() {
		return (
			this.props?.workspaceConfiguration?.pluginAssistant?.allowSkipNativeAppDetection ===
			'false'
		);
	}

	private hasIcaResources() {
		if (
			this.props.loadableResourceContext.loading ||
			!this.props.loadableResourceContext.value
		) {
			return false;
		}
		const { resources } = this.props.loadableResourceContext.value;
		return resources && !resources.every(this.isContent);
	}

	private shouldDetect() {
		return (
			this.props.shouldDetectCWA &&
			this.supportsProtocolHandler() &&
			!isHTML5Mandatory(this.props.workspaceConfiguration)
		);
	}

	private isContent(resource: Resource) {
		return resource.clienttypes.includes('content');
	}

	private getDownloadUrl = () => {
		const { workspaceConfiguration } = this.props;
		const { pluginAssistant } = workspaceConfiguration;

		if (isWindowsPlatform() && pluginAssistant?.win32?.path) {
			return pluginAssistant.win32.path;
		}

		if (isMac() && pluginAssistant?.macOS?.path) {
			return pluginAssistant.macOS.path;
		}

		return getDownloadLink(workspaceConfiguration, currentPlatform);
	};

	private detect = () => {
		this.cancelPolling();
		post<any>(
			this.props.workspaceConfiguration.storeProxy.clientAssistantProxy
				.getDetectionTicketURL
		)
			.then(data => {
				launchProtocolHandler({
					action: 'detect',
					url: data.postbackUrl,
					staTicket: data.clientDetectionStaTicket,
					serverProtocolVersion: data.serverProtocolVersion,
					ticket: data.clientDetectionTicket,
				});
				this.poll(data.clientDetectionTicket);
			})
			.catch(() => {
				this.useHtml5();
			});
	};

	private poll(ticket: string) {
		const { workspaceConfiguration } = this.props;

		this.pollTimer = window.setTimeout(() => {
			post<any>(
				workspaceConfiguration.storeProxy.clientAssistantProxy.getDetectionStatusURL,
				{
					ticket,
				}
			)
				.then(data => {
					if (data.status === 'Waiting') {
						// The helper hasn't yet registered the detection result at the Web Proxy, poll again.
						this.poll(ticket);
					} else if (data.status === 'Success') {
						if (
							hasFeatureCanary(
								workspaceConfiguration,
								FeatureFlag.SaasAppLaunchUsingNative
							)
						) {
							this.useNative(data.hdxVersion, data.supportsSaasLaunch);
						} else {
							this.useNative(data.hdxVersion);
						}
					} else {
						this.cancelPolling();
					}
				})
				.catch(() => {
					this.useHtml5();
				});
		}, pollInterval);
	}

	private cancelPolling() {
		if (this.pollTimer) {
			clearTimeout(this.pollTimer);
		}
	}

	private useHtml5 = () => {
		setClientType(clientType.html5);
		this.cancelPolling();
		this.setState({
			clientTypeDetected: true,
			// Ignore Extension, even if enabled.
			waitOnBrowserExtensionCheck: false,
			waitOnChromeAppCheck: false,
		});
	};

	private useNative = (version?: string, supportsSaasLaunch?: string) => {
		setClientType(clientType.native, version, supportsSaasLaunch);
		this.cancelPolling();
		this.setState({
			clientTypeDetected: true,
		});
	};

	private useBrowserExtension = () => {
		const currentClientType = getClientType();
		if (currentClientType === clientType.native) {
			setPreviousClientType(currentClientType);
		}
		setClientType(clientType.browserextension);
		this.cancelPolling();
		this.setState({
			clientTypeDetected: false,
			waitOnBrowserExtensionCheck: false,
		});
	};

	private useChromeApp = () => {
		const currentClientType = getClientType();
		if (currentClientType === clientType.native) {
			setPreviousClientType(currentClientType);
		}
		setClientType(clientType.chromeapp);
		this.cancelPolling();
		this.setState({
			clientTypeDetected: false,
			waitOnChromeAppCheck: false,
		});
	};

	private supportsProtocolHandler = () => {
		return (
			this.props.shouldDetectCWA &&
			canUseProtocolHandler(this.props.workspaceConfiguration)
		);
	};

	private initiateDetection = async () => {
		if (isCitrixChromeApp()) {
			const chromeAppConfiguration =
				await this.props.chromeAppContext.getChromeAppConfiguration();
			if (chromeAppConfiguration.isActive) {
				this.useChromeApp();
			} else if (this.supportsProtocolHandler()) {
				setClientType(null);
				this.showDetect();
			} else {
				this.useNative();
			}
		} else {
			const extensionConfiguration =
				await this.props.browserExtensionContext.getExtensionConfiguration();
			if (extensionConfiguration.isActive) {
				this.useBrowserExtension();
			} else if (this.supportsProtocolHandler()) {
				setClientType(null);
				this.showDetect();
			} else {
				this.useNative();
			}
		}
	};
}

export const Detection: React.FC<
	Pick<Props, 'shouldDetectCWA' | 'detectClientBeforeAuthentication'>
> = props => {
	const { workspaceConfiguration } = useConfigurationContext();
	const resourceContext = useLoadableResourceContext();
	const browserExtensionContext = useBrowserExtension();
	const chromeAppContext = useChromeApp();
	return (
		<_Detection
			{...props}
			workspaceConfiguration={workspaceConfiguration}
			loadableResourceContext={resourceContext}
			browserExtensionContext={browserExtensionContext}
			chromeAppContext={chromeAppContext}
		/>
	);
};

export interface WithDetectionProps {
	detection: DetectionContext;
}

export function withDetection<T extends WithDetectionProps>(
	Component: React.ComponentType<T>
): React.FC<Omit<T, keyof WithDetectionProps>> {
	return props => {
		const detection = React.useContext(Context);
		return <Component {...(props as T)} detection={detection} />;
	};
}
