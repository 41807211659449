import { NativePlatform } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment/Environment';

export function shouldEnableSiri() {
	return (
		environment.nativePlatform === NativePlatform.iOS &&
		!environment.nativeCapabilities?.clientSettings?.disableSiri
	);
}

export const reconnectAppsEvent = 'reconnectApps';
