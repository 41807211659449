import * as React from 'react';
import { postWithFullResponse } from '@citrite/http';
import { microAppIntegration } from '@citrite/workspace-ui-microapps';
import {
	AuthChallengeHandler,
	hasEndpointService,
	hasFeatureCanary,
	NativePlatform,
	UserAccountData,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent, trackEvent } from 'analytics';
import { History, Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import { logError } from 'remoteLogging';
import { v4 } from 'uuid';
import { performance } from 'Components/performance';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { cwaUsername } from 'Environment/callbacks';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { clientType, getClientType } from 'Environment/getClientType';
import { isOfflineResourceAvailableForHtml5Shield } from 'Environment/Html5/Html5LeaseLaunch';
import {
	isCitrixChromeApp,
	userAgentContainsIWSEnabled,
} from 'Environment/launchResource/device';
import { isIwsEnabledInVanityDomain } from 'Environment/vanityDomain';
import {
	FeatureRolloutClient,
	FeatureRolloutFeatures,
	logFeatureRolloutError,
	useFeatureRolloutClient,
} from 'FeatureRolloutService';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import { isHTML5ShieldEnabled } from 'LeaseWorkflow';
import { useAuthentication } from 'Login/useAuthentication';
import { useNativeLogoutBehavior } from 'Logout/useNativeLogoutBehavior';
import { PolicyBrandingService, usePolicyBrandingService } from 'policyBrandingService';
import {
	UserPreferenceService,
	useUserPreferenceService,
} from 'userPersonalizationService';
import { isAdvancedWorkspaceResiliencyEnabled } from 'Workspace/advancedWorkspaceResiliency';
import { BrowserExtensionContext } from 'Workspace/BrowserExtension/Context';
import { useBrowserExtension } from 'Workspace/BrowserExtension/useBrowserExtension';
import { InitialLoading } from 'Workspace/InitialLoading';
import { browserExtensionAnalyticsReporter } from 'Workspace/TelemetryEvents/browserExtension/createBrowserExtensionAnalyticsReporter';
import { reloadIfHtmlResponseInUsername } from 'Workspace/UserContext/invalidUserNameResponseHandler';
import {
	getUserDetailsFromCacheFlagRecord,
	isPerformanceOptimizationEnabled,
} from 'Workspace/utils';
import { ChromeAppContext } from '../ChromeApp/ChromeAppContext';
import { useChromeApp } from '../ChromeApp/useChromeApp';
import { lastUserDetailsKey, tempUserIdKey } from './constants';
import { useUserContext } from './Consumer';
import { UserContext } from './Context';

type UserDetailsCacheEntry = Pick<UserAccountData, 'userId' | 'userDisplayName'>;

interface Props {
	location: Location;
	history: History;
	workspaceConfiguration: WorkspaceConfiguration;
	userContext: UserContext;
	handleAuthChallengeIfNecessary: AuthChallengeHandler;
	userPreferenceService: UserPreferenceService;
	policyBrandingService: PolicyBrandingService;
	featureRolloutClient: FeatureRolloutClient;
	browserExtensionContext: BrowserExtensionContext;
	chromeAppContext: ChromeAppContext;
}

class _UserContextLoader extends React.Component<Props, never> {
	private initialUserDetailsFromLocalStorage =
		getFromLocalStorage<UserDetailsCacheEntry>(lastUserDetailsKey);
	private prefetchUserDetailsFromCacheAllowed = isPerformanceOptimizationEnabled(
		this.props.workspaceConfiguration,
		getUserDetailsFromCacheFlagRecord
	);

	public render() {
		if (
			this.props.userContext.loading ||
			(!this.prefetchUserDetailsFromCacheAllowed &&
				this.props.userContext.userBranding?.loading)
		) {
			return <InitialLoading />;
		}

		return <>{this.props.children}</>;
	}

	public componentDidMount() {
		const userDetailsLoader = this.getUserDetails();
		this.props.userContext.update({ userDetailsLoader });
	}

	public componentDidUpdate(prevProps: Props) {
		// With class components, we can't use hooks like useEffect to handle side effects.
		// We can use componentDidUpdate to handle side effects.
		// We have to manually check if the props have changed and then call the side effect.
		// This is a bit more verbose than using hooks, but it's the only way to handle side effects with class components.
		// Earlier user context was loaded with GetUserDetails, with performance optimization, we are loading user context with cache.
		// So, we need to check if user details(userThumbprint) is loaded from network and then fetch user specific application data.
		if (
			(prevProps.userContext.loading && !this.props.userContext.loading) ||
			this.hasUserThumbprintChanged(prevProps, this.props)
		) {
			this.fetchUserSpecificApplicationDataAfterLogin();
		}

		if (
			prevProps.userContext.userPreferences !== this.props.userContext.userPreferences
		) {
			environment.setUserPreferences(this.props.userContext.userPreferences);
		}

		const hasResilientWorkspace = isAdvancedWorkspaceResiliencyEnabled(
			this.props.workspaceConfiguration
		);

		if (this.props.userContext.userDetails) {
			setInLocalStorage(lastUserDetailsKey, {
				userId: this.props.userContext.userDetails.userId,
				userDisplayName:
					hasResilientWorkspace && environment.isNative
						? this.props.userContext.userDetails.userDisplayName
						: null,
			});
		}
	}

	private async fetchUserSpecificApplicationDataAfterLogin() {
		const hasUserLoggedIn = await this.props.userContext.hasLoggedIn();
		if (hasUserLoggedIn) {
			this.checkFeatureEnablement();
			this.getUserBranding();
			this.getPreferences();

			performance.homePageReady();
		}
	}

	private getUsernameResponse(getUsernameURL: string) {
		return postWithFullResponse<string>(getUsernameURL)
			.then(resp => {
				reloadIfHtmlResponseInUsername(resp);
				return resp;
			})
			.catch(e => {
				if (!cwaUsername) {
					throw e;
				}
				return { data: cwaUsername };
			});
	}

	private getTempUserId() {
		// As userId and userThumbprint is not available for OnPrem, tempId required in case username is not available
		let tempUserId: string = getFromLocalStorage(tempUserIdKey);
		if (!tempUserId) {
			const newTempUserId = v4();
			setInLocalStorage(tempUserIdKey, newTempUserId);
			tempUserId = newTempUserId;
		}
		return tempUserId;
	}

	private async getUserDetailsOnPremResponse(getUsernameURL: string) {
		const displayNameResponse = await this.getUsernameResponse(getUsernameURL);
		const username = displayNameResponse?.data ? displayNameResponse.data : '';
		let userId = username;
		let userThumbprint = username;
		if (username === '') {
			const tempUserId = this.getTempUserId();
			userId = tempUserId;
			userThumbprint = tempUserId;
		}
		return {
			data: {
				userId,
				userDisplayName: username,
				userThumbprint,
			},
		};
	}

	private async getUserDetailsOnCloudResponse(
		getUserDetailsURL: string,
		getUsernameURL: string
	) {
		const userDetailsCloudResponse = await postWithFullResponse<UserAccountData>(
			getUserDetailsURL,
			null,
			{ passthroughDSAuthChallenge: true }
		);

		const { handlingAuthChallenge } = this.props.handleAuthChallengeIfNecessary(
			userDetailsCloudResponse.headers
		);

		if (handlingAuthChallenge) {
			return undefined;
		}

		if (!userDetailsCloudResponse.data.userDisplayName) {
			const displayNameResponse = await this.getUsernameResponse(getUsernameURL);

			userDetailsCloudResponse.data.userDisplayName = displayNameResponse.data;
		}

		this.validateUserId(userDetailsCloudResponse.data);

		return userDetailsCloudResponse;
	}

	private validateUserId = (userData: UserAccountData) => {
		const { userId } = userData;

		if (!userId) {
			const errorMessage = 'User ID not available in network response of user-details';
			logError(new Error(errorMessage));
		}
	};

	private getUserDetails = async () => {
		const hasCachedUserDetails =
			this.prefetchUserDetailsFromCacheAllowed && this.getLastUserDetailsFromCache();
		if (!IS_ON_PREM && !environment.citrixCloudConnected) {
			if (
				hasCachedUserDetails ||
				(!this.prefetchUserDetailsFromCacheAllowed && this.getLastUserDetailsFromCache())
			) {
				return;
			}
		}
		const { getUserDetailsURL, getUsernameURL } =
			this.props.workspaceConfiguration.authManager;

		try {
			let userDetailsResponse: { data: UserAccountData };

			if (IS_ON_PREM) {
				userDetailsResponse = await this.getUserDetailsOnPremResponse(getUsernameURL);
			} else {
				userDetailsResponse = await this.getUserDetailsOnCloudResponse(
					getUserDetailsURL,
					getUsernameURL
				);
				if (!userDetailsResponse) {
					return;
				}
			}

			userDetailsResponse.data.isValidUserDisplayName =
				userDetailsResponse.data.userDisplayName !== userDetailsResponse.data?.authAlias;

			const existingUserName = this.props.userContext.userDetails?.userDisplayName;
			this.props.userContext.update({
				isValidatedReturnUser:
					userDetailsResponse.data.userId ===
					this.initialUserDetailsFromLocalStorage?.userId,
				userDetails: {
					...userDetailsResponse.data,
					userDisplayName: userDetailsResponse.data.userDisplayName || existingUserName,
				},
			});
		} catch (e) {
			if (this.initialUserDetailsFromLocalStorage?.userId) {
				if (getClientType() !== clientType.html5) {
					const browserExtension =
						await this.props.browserExtensionContext.getExtensionConfiguration();
					if (
						browserExtension.isActive &&
						browserExtension.allowAccessToOfflineResources
					) {
						await environment.setCitrixCloudConnectedStatus(false);
						trackAnalyticsEvent(
							browserExtensionAnalyticsReporter.getCloudOfflineEventWhenGetUserDetailsFailed()
						);
						this.getLastUserDetailsFromCache();
						return;
					}
					const chromeappConfiguration =
						await this.props.chromeAppContext.getChromeAppConfiguration();
					if (
						chromeappConfiguration.isActive &&
						chromeappConfiguration.allowAccessToOfflineResources
					) {
						await environment.setCitrixCloudConnectedStatus(false);
						trackEvent('CloudOfflineWithChromeApp_GetUserDetailsFailed');
						this.getLastUserDetailsFromCache();
						return;
					}
				} else if (
					isHTML5ShieldEnabled(this.props.workspaceConfiguration) &&
					isOfflineResourceAvailableForHtml5Shield()
				) {
					await environment.setCitrixCloudConnectedStatus(false);
					trackEvent('CloudOfflineWithHtml5_GetUserDetailsFailed');
					this.getLastUserDetailsFromCache();
					return;
				}
			}

			this.props.userContext.update({
				error: new Error('get user details failed'),
			});
		}
	};

	private getLastUserDetailsFromCache(): boolean {
		if (!this.initialUserDetailsFromLocalStorage) {
			return false;
		}

		this.props.userContext.update({
			isValidatedReturnUser: true,
			userDetails: this.initialUserDetailsFromLocalStorage,
		});

		return true;
	}

	private fetchFeatures = async (): Promise<FeatureRolloutFeatures[]> => {
		let features: FeatureRolloutFeatures[] = [];

		if (!this.props.featureRolloutClient) {
			return features;
		}
		performance.mark(performance.events.IWS_FRS_Start);

		try {
			features = await this.props.featureRolloutClient.fetchFeaturesCacheFirst();
		} catch (e) {
			logFeatureRolloutError(e);
		} finally {
			performance.mark(performance.events.IWS_FRS_Finish);
		}

		return features;
	};

	private checkFeatureEnablement = async () => {
		const features = await this.fetchFeatures();
		this.checkIWSEnablement(features);
		this.checkDaasVisionEnablement(features);
		this.checkActivityManagerEnablement(features);
		this.updatePowerManagementControls(features);
	};

	private checkIWSEnablement = async (features: FeatureRolloutFeatures[]) => {
		if (
			features?.includes(FeatureRolloutFeatures.DaasUI) ||
			!hasEndpointService(
				this.props.workspaceConfiguration,
				microAppIntegration.requiredEndpointServiceName
			) ||
			((isCitrixChromeApp() || environment.nativePlatform === NativePlatform.Linux) &&
				!userAgentContainsIWSEnabled()) ||
			!isIwsEnabledInVanityDomain(this.props.workspaceConfiguration)
		) {
			this.props.userContext.update({ isIWSEnabled: false });
			return;
		}

		this.props.userContext.update({
			isIWSEnabled: features && features.includes(FeatureRolloutFeatures.IWS),
		});
	};

	private checkDaasVisionEnablement = async (features: FeatureRolloutFeatures[]) => {
		const daasVisionFeatureAdded =
			IS_ON_PREM || (features && features.includes(FeatureRolloutFeatures.DaasUI));

		const forceEnableDaasVision = hasFeatureCanary(
			this.props.workspaceConfiguration,
			FeatureFlag.DaasVisionForceEnabled
		);
		this.props.userContext.update({
			isDaasVisionEnabled: daasVisionFeatureAdded || forceEnableDaasVision,
		});
	};

	private checkActivityManagerEnablement = async (
		frsFeatures: FeatureRolloutFeatures[]
	) => {
		if (IS_ON_PREM) {
			// since FRS is not available for on-prem returning earlry. Keeping this check instead of frscleint null check while calling checkFeatureEnablement because a lot of integration test
			// depens upon isDaasVisionEnabled to be false. so if don't call checkDaasVisionEnablement while running Integration test then isDaasVisionEnabled will be true.
			return;
		}
		const isPowerManagementControlsFeatureEnabled = hasFeatureCanary(
			this.props.workspaceConfiguration,
			FeatureFlag.WSUIPowerManagementControls
		);
		if (!isPowerManagementControlsFeatureEnabled) {
			const activityManagerForceEnabled = hasFeatureCanary(
				this.props.workspaceConfiguration,
				FeatureFlag.ActivityManagerForceEnabled
			);
			this.props.userContext.update({
				isActivityManagerEnabled:
					frsFeatures?.includes(FeatureRolloutFeatures.ActivityManager) ||
					activityManagerForceEnabled,
			});
		}
	};

	private updatePowerManagementControls = async (
		frsFeatures: FeatureRolloutFeatures[]
	) => {
		const isPowerManagementControlsFeatureEnabled = hasFeatureCanary(
			this.props.workspaceConfiguration,
			FeatureFlag.WSUIPowerManagementControls
		);
		//Currently we're using exisitng activity manager value to enable power management controls
		if (
			isPowerManagementControlsFeatureEnabled &&
			frsFeatures?.includes(FeatureRolloutFeatures.ActivityManager)
		) {
			this.props.userContext.update({
				powerManagementControls: {
					shutdown: true,
					hibernate: true,
					powerOff: true,
					restart: true,
				},
			});
		}
	};

	private getPreferences = async () => {
		if (!this.props.userPreferenceService) {
			return;
		}
		try {
			const preferences = await this.props.userPreferenceService.getUserPreferences();
			this.props.userContext.update({
				userPreferences: preferences,
			});
		} catch (error) {
			logError(error, { tags: { feature: 'userPreferences' } });
		}
	};

	private getUserBranding = async () => {
		try {
			if (
				!Number(
					this.props.workspaceConfiguration.userInterface.branding?.brandingPolicyCount
				)
			) {
				this.props.userContext.update({ userBranding: { loading: false, value: null } });
				return;
			}

			if (!this.props.policyBrandingService) {
				this.props.userContext.update({
					userBranding: { loading: false, value: null },
				});
				return;
			}

			const branding = await this.props.policyBrandingService.getUserBranding();

			this.props.userContext.update({
				userBranding: { loading: false, value: branding },
			});
		} catch (error) {
			this.props.userContext.update({
				userBranding: { loading: false, value: null },
			});
			logError(error, { tags: { feature: 'policyBranding' } });
		}
	};

	private hasUserThumbprintChanged = (prevProps: Props, currentProps: Props) => {
		const prevUserDetails = prevProps.userContext?.userDetails;
		const currentUserDetails = currentProps.userContext?.userDetails;

		return prevUserDetails?.userThumbprint !== currentUserDetails?.userThumbprint;
	};
}

export const UserContextLoader = (props: { children?: React.ReactNode }) => {
	useNativeLogoutBehavior();
	const contexts: Props = {
		featureRolloutClient: useFeatureRolloutClient(),
		userPreferenceService: useUserPreferenceService(),
		policyBrandingService: usePolicyBrandingService(),
		workspaceConfiguration: useConfigurationContext().workspaceConfiguration,
		location: useLocation(),
		history: useHistory(),
		userContext: useUserContext(),
		handleAuthChallengeIfNecessary: useAuthentication().handleAuthChallengeIfNecessary,
		browserExtensionContext: useBrowserExtension(),
		chromeAppContext: useChromeApp(),
	};
	return <_UserContextLoader {...contexts}>{props.children}</_UserContextLoader>;
};
