export interface PrepopulatedCredentials {
	username: string;
	password: string;
	domain: string;
}

export interface FallbackConfiguration extends FallbackConfigurationAddressAndTitle {
	altStore?: FallbackConfigurationAddressAndTitle[];
}

export interface FallbackConfigurationAddressAndTitle {
	address: string;
	title?: string;
}

export interface PinnedLink {
	hyperlink: string;
	displayText: string;
	iconUrl?: string;
	toolTipText?: string;
}

export interface Plugin {
	name: string;
	src: string;
	params?: {
		param: PluginParameter[];
	};
}

export interface PluginParameter {
	name: string;
	value: string;
}

interface PinnedLinks {
	pinnedLink: PinnedLink[];
}

interface UserNotifications {
	url: string;
}

export enum LandingPage {
	Auto = 'auto',
	Home = 'home',
	Apps = 'apps',
	Desktops = 'desktops',
}

export interface WorkspaceConfiguration {
	session: {
		timeout: number;
		userLanguages: string;
	};
	pinnedLinks?: PinnedLinks;
	prePopulatedCredentials?: PrepopulatedCredentials;
	authManager: {
		getUserDetailsURL: string;
		getUsernameURL: string;
		logonURL?: string;
		logoffURL: string;
		changeCredentialsURL: string;
		loginFormTimeout: string;
		webviewReturnURL: string;
		webviewResumeURL: string;
		allowSelfServiceAccountManagementURL: string;
		proxyUrl?: string;
		authDomain?: string;
		idpConfigId?: string;
	};
	storeProxy: {
		keepAliveURL: string;
		resourcesProxy: {
			listURL: string;
			resourceDetails: string;
			multipleSubscriptionsURL?: string;
		};
		sessionsProxy: {
			listAvailableURL: string;
			listURL?: string;
			resourceDetails?: string;
			logoffURL: string;
			disconnectURL: string;
		};
		clientAssistantProxy: {
			getDetectionTicketURL: string;
			getDetectionStatusURL: string;
		};
		sessionControlProxy?: {
			listSessionsURL: string;
			logoffSessionsURL: string;
			disconnectSessionsURL: string;
		};
		machinesProxy?: {
			listMachinesURL?: string;
			powerOffMachineURL: string;
			shutDownMachineURL: string;
			suspendMachineURL?: string;
		};
		requestCasTicket: string;
		validateConnectionURL?: string;
		notifications: {
			blockingNotificationURL?: string;
		};
		gacs?: {
			gacsUrl: string;
			gacsEndpointsUrl: string;
			createTokenPath: string;
		};
	};
	storeIdentifiers: {
		customerId: string;
		storeGuid: string;
	};
	pluginAssistant: {
		enabled: string;
		upgradeAtLogin: string;
		showAfterLogin: string;
		showOnlyIfRequiredByApps?: string;
		requireNativeAppUse?: string;
		downloadPageUrl?: string;
		win32: {
			path: string;
			version?: string;
		};
		macOS: {
			path: string;
			minimumSupportedOSVersion: string;
			version?: string;
		};
		html5: {
			enabled: string;
			platforms: string;
			launchURL: string;
			preferences: string;
			singleTabLaunch: string;
			chromeAppOrigins: string;
			chromeAppPreferences: string;
			thirdPartyNotices: string;
			workspaceWebExtensionPrompt: string;
			useCitrixEnterpriseBrowser: string;
		};
		protocolHandler: {
			enabled: string;
			platforms: string;
			skipDoubleHopCheckWhenDisabled: string;
		};
		allowSkipNativeAppDetection?: string;
	};
	userInterface: {
		appShortcuts: any;
		autoLaunchDesktop: string;
		enableAppsFolderView: string;
		enableEnhancedCaching: string;
		enableWorkspaceHome: string;
		enableSimpleView?: string;
		multiClickTimeout: string;
		receiverConfiguration: {
			downloadURL: string;
			enabled?: string;
		};
		uiViews: {
			showAppsView: string;
			showDesktopsView: string;
			defaultView?: string;
			appsViewTab?: string;
			defaultCategoryName?: string;
		};
		workspaceControl?: {
			enabled?: string;
			autoReconnectAtLogon?: string;
			logoffAction?: string;
			showReconnectButton?: string;
			showDisconnectButton?: string;
		};
		branding: StoreBranding;
		/**
		 * Proactive change password option
		 */
		changePassword?: {
			/**
			 * Whether the admin has enabled the feature for this customer
			 */
			enabled?: string;
		};
		fallbackConfiguration?: FallbackConfiguration;
		showActivityManager?: string;
		showFirstTimeUse?: string;
		preventIcaDownloads?: string;
	};
	plugins: {
		plugin: Plugin[];
	};
	featureToggles: {
		feature: {
			name: string;
			enabled: string;
		}[];
	};
	featureCanaries: {
		canary: {
			name: string;
		}[];
	};
	uiProperties?: {
		property: Property[];
	};
	endpointsServices: {
		endpointsService: EndpointsService[];
	};
	userNotifications?: UserNotifications;
}

export interface OnPremSpecificAppProtectionPolicy {
	appProtection?: {
		enabled?: string; //This will be set by MMC. "on" means enabled, undefined  or "off" means disabled
	};
}

//Define this separately using interface merging instead of hardcoding it in the main interface
//To indicated that WorkspaceConfiguration for Cloud does not have this property by default
export interface WorkspaceConfiguration extends OnPremSpecificAppProtectionPolicy {}

export interface EndpointsService {
	name: string;
	discoveryUrl: string;
}

export interface Endpoint {
	id: string;
	url: string;
}

interface Property {
	name: string;
	value: string;
}

export interface StoreBranding {
	backgroundColor?: string;
	textColor?: string;
	linkColor?: string;
	headerLogo?: string;
	logonLogo?: string;
	brandingPolicyCount?: string;
}

export interface NativeAccounts {
	accounts: NativeAccount[];
}

export interface NativeAccount {
	displayName: string;
	actionId: string;
	enabled?: boolean;
	allAccounts?: boolean;
}

export interface SSOData {
	url: string;
	token: string;
	target: any;
	status: string;
	ssodata: string;
}

export interface AuthData {
	TicketUrl: string;
	DsAuthTokenUrl: string;
	CwcCustomer: {
		Id: string;
		GeoId: string;
	};
}

export interface UserAccountData {
	orgId?: string;
	accountType?: string;
	isPartner?: string;
	cloudServices?: CloudServices[];
	passwordPolicies?: string[];
	productionCloudServicesCount?: string;
	productionCloudServicesList?: string;
	productionTrialCloudServicesCount?: string;
	productionTrialCloudServicesList?: string;
	userId?: string;
	userDisplayName?: string;
	userThumbprint?: string;
	authAlias?: string;
	isValidUserDisplayName?: boolean;
}

export interface CloudServices {
	name?: string;
	type?: string;
}
