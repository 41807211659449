import React, { useCallback, useEffect, useState } from 'react';
import { t } from '@citrite/translate';
import { Breadcrumbs, layout, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { first, last } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { appsPagePath } from 'App/Apps';
import { useFormFactor } from 'App/Header/useFormFactor';
import { CategoryList } from 'App/Screen/CategoryList';
import { Container } from 'App/Screen/Container';
import { MobileFullScreen } from 'App/Screen/MobileFullScreen';
import { ResourceList } from 'App/Screen/ResourceList';
import {
	determinePathToNavigate,
	resolveCategoryForPath,
	ResourceFilter,
	translateCategoryForDisplay,
	validatedCategoriesToNavigate,
} from 'App/Screen/util';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

const StyledBreadcrumbContainer = styled.div`
	padding-top: 24px;
	padding-bottom: 16px;
	line-height: 0px;
`;

const StyledTitle = styled.div`
	font-size: ${typography.sizes.sml};
	margin: ${layout.tinySpace} 0;
`;

const StyledCategoryContainer = styled.div<{ isLargeFormFactor: boolean }>`
	${({ isLargeFormFactor }) =>
		isLargeFormFactor &&
		`
		max-width: 1060px;
		margin: 0 auto;
  `}
`;

export const Apps = () => {
	const history = useHistory();
	const location = useLocation();
	const [category, setCategory] = useState<string>();
	const [subCategoryPath, setSubCategoryPath] = useState([]);
	const [breadcrumbList, setBreadcrumbList] = useState([]);
	const { workspaceConfiguration } = useConfigurationContext();
	const { value: resourceContext } = useLoadableResourceContext();
	const { isSmallFormFactor, isLargeFormFactor } = useFormFactor();

	const makeHistoryPush = useCallback(
		(path: string) => {
			path !== history.location.pathname && history.push(path);
		},
		[history]
	);

	const setCategoryPath = useCallback((categories: string[]) => {
		const firstParentCategory = first(categories) || t('Workspace:all');
		setCategory(firstParentCategory);
		setSubCategoryPath(categories?.length > 1 ? categories : [firstParentCategory]);
	}, []);

	const onBackSubcategoryNavigationAction = useCallback(() => {
		const tempStack = [...subCategoryPath];
		tempStack.pop();
		setCategoryPath(tempStack);
	}, [setCategoryPath, subCategoryPath]);

	useEffect(() => {
		if (subCategoryPath.length > 0) {
			const subCategoriesList = subCategoryPath.map((subCategory, index) => {
				return {
					onClick: () => {
						setSubCategoryPath(subCategoryPath.slice(0, index + 1));
					},
					title: <StyledTitle>{subCategory}</StyledTitle>,
					id: index + subCategory,
				};
			});
			setBreadcrumbList(subCategoriesList);
		}
	}, [subCategoryPath]);

	useEffect(() => {
		if (subCategoryPath.length > 0) {
			const path =
				appsPagePath + '/' + subCategoryPath.map(resolveCategoryForPath).join('/');
			makeHistoryPush(path);
		}
	}, [subCategoryPath, makeHistoryPush]);

	useEffect(() => {
		const pathFromURL = location.pathname;
		const appsViewTab = workspaceConfiguration?.userInterface?.uiViews?.appsViewTab;
		const pathFromConfig =
			workspaceConfiguration?.userInterface?.uiViews?.defaultCategoryName;
		const pathToNavigate = determinePathToNavigate(
			pathFromURL,
			appsViewTab,
			pathFromConfig
		);
		const categoriesToNavigate = pathToNavigate
			.replace(`${appsPagePath}/`, appsPagePath)
			.replace(appsPagePath, '')
			.split('/');
		const validCategoriesToNavigate: string[] = validatedCategoriesToNavigate(
			categoriesToNavigate,
			resourceContext.resources
		).map(translateCategoryForDisplay);
		setCategoryPath(validCategoriesToNavigate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, resourceContext]);

	const shouldDisplayMobileSubcategoryList = () => {
		return (
			isSmallFormFactor &&
			subCategoryPath.length > 0 &&
			first(subCategoryPath) !== t('Workspace:all')
		);
	};

	const resourceList = () => {
		return (
			<ResourceList
				filter={ResourceFilter.Applications}
				category={category}
				setSubCategoryPath={setSubCategoryPath}
				subCategoryPath={subCategoryPath}
			/>
		);
	};
	return (
		<Container id="apps-screen">
			{shouldDisplayMobileSubcategoryList() ? (
				<MobileFullScreen
					title={last(subCategoryPath)}
					onBackButtonClick={onBackSubcategoryNavigationAction}
				>
					<Container>{resourceList()}</Container>
				</MobileFullScreen>
			) : (
				<>
					<StyledCategoryContainer isLargeFormFactor={isLargeFormFactor}>
						<CategoryList
							chooseCategory={setCategory}
							selectedCategory={category}
							setSubCategoryPath={setSubCategoryPath}
						/>
						{isLargeFormFactor && breadcrumbList.length > 0 && (
							<StyledBreadcrumbContainer data-testid="category-path-container">
								<Breadcrumbs breadcrumbs={breadcrumbList} maxItems={3} />
							</StyledBreadcrumbContainer>
						)}
					</StyledCategoryContainer>

					{resourceList()}
				</>
			)}
		</Container>
	);
};
