import { isPreviousLogoutExplicit } from 'Logout/previousLogoutConfig';
import { ExtensionConfigurationState } from 'Workspace/BrowserExtension/Context';

export interface LeasingProperties {
	/**
	 * By enabling this property, end-user is granted unrestricted access to offline UI.
	 */
	alwaysBypassAuthForCachedResources?: boolean;
	/**
	 * By enabling this property, end-user is granted access to offline UI only when previous active-session was not explicitly logged-out by end-user.
	 */
	bypassAuthForCachedResources?: boolean;
	telemetryHeadlessLaunchEnabled?: boolean;
	telemetryLaunchMinTimeIntervalMins?: number;
	telemetryLaunchShadowDelayMins?: number;
	resourceLeasingEnabled?: boolean;
}

interface CustomerConfiguration {
	leasingProperties?: LeasingProperties;
	isAuthenticationTimerSupported?: boolean;
	isModernOfflineFlowSupported?: boolean;
}

function allowAccessToOfflineResources(config: CustomerConfiguration) {
	if (config?.leasingProperties?.alwaysBypassAuthForCachedResources) {
		return true;
	}

	if (
		config?.leasingProperties?.bypassAuthForCachedResources &&
		!isPreviousLogoutExplicit()
	) {
		return true;
	}

	return false;
}

export function parseExtensionConfiguration(config: CustomerConfiguration) {
	const allowAccessToOfflineResourcesVal = allowAccessToOfflineResources(config);
	const result: ExtensionConfigurationState = {
		isActive: true,
		allowAccessToOfflineResources: allowAccessToOfflineResourcesVal,
		useAuthenticationTimer:
			allowAccessToOfflineResourcesVal && config.isAuthenticationTimerSupported,
		isModernOfflineFlowSupported: config.isModernOfflineFlowSupported,
	};
	return result;
}
