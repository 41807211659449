import { getWithFullResponse } from '@citrite/http';
import { Endpoint } from '@citrite/workspace-ui-platform';
import { ParseXMLFunction } from 'Environment/EndpointSupplier';

interface EndpointsDocument {
	endpoints: {
		endpoint: Endpoint[];
	};
}

/**
 * Using a workspace platform endpoints service discovery url, fetch and parse the list of service endpoints.
 */
export function fetchEndpoints(
	discoveryUrl: string,
	parseXML: ParseXMLFunction
): Promise<Endpoint[]> {
	return getWithFullResponse<string | EndpointsDocument>(discoveryUrl).then(response => {
		const { data } = response;
		const isJSONContent =
			response.headers['content-type'] &&
			response.headers['content-type'].includes('application/json');
		const endpointsDocument = isJSONContent
			? (data as EndpointsDocument)
			: parseXML<EndpointsDocument>(data as string);

		if (!Array.isArray(endpointsDocument.endpoints.endpoint)) {
			endpointsDocument.endpoints.endpoint = [endpointsDocument.endpoints.endpoint];
		}
		return endpointsDocument.endpoints.endpoint.filter(x => !!x);
	});
}
