import { GacsTicketFetcherCloud } from 'GlobalAppConfigService/gacsTicketFetcher';
import { GacsTicketFetcherOnprem } from 'GlobalAppConfigService/gacsTicketFetcherOnprem';
import { wsuiDependencies } from 'GlobalAppConfigService/wsuiDependencies';

// allow-unused-export: needed for unit tests
export { GacsTicketFetcherCloud, GacsTicketFetcherOnprem };
export { GlobalAppConfigResponse } from 'GlobalAppConfigService/types';

export const gacsTicketFetcher = IS_ON_PREM
	? GacsTicketFetcherOnprem.getInstance(wsuiDependencies)
	: GacsTicketFetcherCloud.getInstance(wsuiDependencies);
