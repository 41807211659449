class EventObserver {
	private events: Record<
		string,
		{ listener: (...args: any[]) => void; emitted: boolean }
	> = {};

	// Subscribe to an event, only one listener per event.
	public on(event: string, listener: (...args: any[]) => void): void {
		this.events[event] = { listener, emitted: false };
	}

	// Subscribe to an event, but run only once
	public once(event: string, listener: (...args: any[]) => void): void {
		const wrapper = (...args: any[]) => {
			this.off(event); // Remove the wrapper listener after it's called
			listener(...args); // Call the original listener
		};
		this.on(event, wrapper); // Add the wrapper as a listener
	}

	// Remove a listener for an event
	public off(event: string): void {
		if (this.events[event]) {
			this.events[event].emitted = true;
		}
	}

	// Emit an event
	public emit(event: string, ...args: any[]): void {
		if (this.events[event] && !this.events[event].emitted) {
			this.events[event].listener(...args);
		}
	}

	private constructor() {}

	private static instance: EventObserver;

	public static getInstance(): EventObserver {
		if (!this.instance) {
			this.instance = new EventObserver();
		}
		return this.instance;
	}
}

export const eventObserver = EventObserver.getInstance();
