import { EndpointSupplier, UnencryptedCacheBucket } from '@citrite/workspace-ui-platform';
import { parseXML } from 'Components/parseXML';
import { _EndpointSupplier } from 'Environment/EndpointSupplier/endpointSupplierCommon';
import { BucketManifest } from 'Workspace/Cache';
import { createCacheBucket } from 'Workspace/Cache/createCacheBucket';

export function createEndpointSupplier<T extends string>(
	serviceEndpointsDiscoveryUrl: string
): EndpointSupplier<T> {
	return new _EndpointSupplier<T>(
		serviceEndpointsDiscoveryUrl,
		endpointCacheBucket,
		parseXML
	);
}

const endpointCacheBucket: UnencryptedCacheBucket = createCacheBucket({
	bucketId: BucketManifest.endpoints,
});
