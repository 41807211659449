import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import { getEndpointsService } from 'Environment/configSelectors';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { urlSafeBase64Encode } from 'Environment/launchResource/launchUtil';
import { GlobalAppConfigResponse } from 'GlobalAppConfigService';
import { GacsTicketFetcherBase } from 'GlobalAppConfigService/gacsTicketFetcherBase';
import { Dependencies } from 'GlobalAppConfigService/types';
import { getStoreUrl } from 'Workspace/SchemeCallHandler/schemeCallHandler';

export class GacsTicketFetcherCloud extends GacsTicketFetcherBase {
	private static instance: GacsTicketFetcherCloud;
	private GACS_ENDPOINT_SERVICE = 'GlobalAppConfigService';

	private constructor(dependencies: Dependencies) {
		// Private constructor to prevent direct instantiation with 'new' keyword
		super(dependencies);
	}

	public static getInstance(dependencies: Dependencies): GacsTicketFetcherCloud {
		if (!GacsTicketFetcherCloud.instance) {
			GacsTicketFetcherCloud.instance = new GacsTicketFetcherCloud(dependencies);
		}
		return GacsTicketFetcherCloud.instance;
	}

	protected async fetchGacsTicketFromNetwork(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<GlobalAppConfigResponse> {
		const tokenEndpointUrl = await this.getGacsTokenEndpointUrl(workspaceConfiguration);
		if (!tokenEndpointUrl) {
			return null;
		}

		const citrixAuthDomain = workspaceConfiguration?.authManager?.authDomain;

		const ssoProxyClient = environment.createSSOProxyClient(
			workspaceConfiguration.authManager.proxyUrl
		);

		return await ssoProxyClient.post<GlobalAppConfigResponse>(tokenEndpointUrl, null, {
			headers: {
				'Citrix-Client-Url': this.getEncodedStoreUrl(),
				'Citrix-AuthDomain': citrixAuthDomain,
			},
			responseType: 'text',
		});
	}

	private async getGacsTokenEndpointUrl(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<string> {
		const endpointService = getEndpointsService(
			workspaceConfiguration,
			this.GACS_ENDPOINT_SERVICE
		);

		if (!endpointService) {
			return null;
		}

		const endpointSupplier = this.dependencies.createEndpointSupplier(
			endpointService.discoveryUrl
		);
		const gacsTokenEndpointUrl = await endpointSupplier.getEndpoint(
			this.GACS_TOKEN_ENDPOINT_SERVICE
		);

		if (!gacsTokenEndpointUrl) {
			return null;
		}
		return gacsTokenEndpointUrl;
	}

	public isGacsV2Enabled(workspaceConfig: WorkspaceConfiguration): boolean {
		/* Check if GACS V2 is enabled and GACS endpoint (GACS_ENDPOINT_SERVICE) is available in the workspace configuration,
		 * if endpoint is available then we will always have token endpoint (GACS_TOKEN_ENDPOINT_SERVICE) as well.
		 */

		const isGacsEndpointAvailable = !!getEndpointsService(
			workspaceConfig,
			this.GACS_ENDPOINT_SERVICE
		);
		return (
			!!workspaceConfig &&
			hasFeatureCanary(workspaceConfig, FeatureFlag.EnableGlobalAppConfigV2) &&
			isGacsEndpointAvailable
		);
	}

	private getEncodedStoreUrl(): string {
		const storeUrl = getStoreUrl();
		return urlSafeBase64Encode(JSON.stringify({ workspaceURL: storeUrl }));
	}
}
