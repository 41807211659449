import { isNativeWidget } from 'Environment/launchResource/device';
import {
	ActivityManagerEventType,
	EventSite,
} from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_ACTIVITY_MANAGER';
const preparePayload = (
	type: ActivityManagerEventType,
	isRemoteSessionOperation: string,
	eventSite = EventSite.ActitivityManager
) => ({
	type,
	code,
	eventSite,
	isNativeWidget: isNativeWidget(),
	isRemoteOperation: isRemoteSessionOperation,
});
const createActivityManagerCASReporter = () => {
	return {
		getActivityManagerInitEvent: () =>
			preparePayload(ActivityManagerEventType.ActivityManager_Init, 'NA'),
		getActivityManagerLogoutEvent: (isRemoteOperation: boolean) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Logout,
				isRemoteOperation.toString()
			),
		getActivityManagerDisconnectEvent: (isRemoteOperation: boolean) =>
			preparePayload(
				ActivityManagerEventType.ActivityManage_Disconnect,
				isRemoteOperation.toString()
			),
		getActivityManagerTerminateEvent: (isRemoteOperation: boolean) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Terminate,
				isRemoteOperation.toString()
			),
		getActivityManagerRestartEvent: (isRemoteOperation: boolean, eventSite: EventSite) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Restart,
				isRemoteOperation.toString(),
				eventSite
			),
		getActivityManagerPoweroffEvent: (isRemoteOperation: boolean, eventSite: EventSite) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Poweroff,
				isRemoteOperation.toString(),
				eventSite
			),
		getActivityManagerShutdownEvent: (isRemoteOperation: boolean, eventSite: EventSite) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Shutdown,
				isRemoteOperation.toString(),
				eventSite
			),
		getActivityManagerHibernateEvent: (
			isRemoteOperation: boolean,
			eventSite: EventSite
		) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Hibernate,
				isRemoteOperation.toString(),
				eventSite
			),
		getActivityManagerResumeEvent: (isRemoteOperation: boolean, eventSite: EventSite) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Resume,
				isRemoteOperation.toString(),
				eventSite
			),
		getActivityManagerReconnectEvent: (isRemoteOperation: boolean) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Reconnect,
				isRemoteOperation.toString()
			),
		getActivityManagerSessionHandoffEvent: (isRemoteOperation: boolean) =>
			preparePayload(
				ActivityManagerEventType.ActivityManager_Transfer,
				isRemoteOperation.toString()
			),
	};
};
export const activityMangerCASReporter = createActivityManagerCASReporter();
