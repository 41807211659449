                                  
import { faro } from './faroMonitoring';
          
import { Monitoring, MoreInfo } from './monitoringTypes';

function getFaro(): Monitoring | null {
	return !IS_ON_PREM && !IS_FEDRAMP ? faro : null;
}

export const monitoring: Monitoring = {
	initialize(...args) {
		try {
			getFaro()?.initialize(...args);
		} catch {}
	},
	setSessionAttribute(name: string, value: string | number | boolean) {
		try {
			getFaro()?.setSessionAttribute(name, value);
		} catch {}
	},
	setCurrentRouteName(name: string) {
		try {
			getFaro()?.setCurrentRouteName(name);
		} catch {}
	},
	addMeasure(name: string, start: number, end: number, moreInfo: MoreInfo = {}) {
		try {
			getFaro()?.addMeasure(name, start, end, moreInfo);
		} catch {}
	},
};
