import { ActiveMachineAction } from 'App/Activity/ResourceManagerContext';
import { updateMachineListCache } from 'App/Activity/Utility';

export const handleMachineActionCompletion = (
	machineAction: ActiveMachineAction,
	options: {
		isMachinePowerStateEnabled: boolean;
		onRefresh: (isSilentLoad: boolean) => void;
		refreshMachinesFromCache: () => boolean;
		sessionRefreshFlag: React.MutableRefObject<boolean>;
	}
) => {
	const {
		isMachinePowerStateEnabled,
		onRefresh,
		refreshMachinesFromCache,
		sessionRefreshFlag,
	} = options;
	if (!isMachinePowerStateEnabled) {
		onRefresh(true);
	}
	const isMachineListCacheUpdated = updateMachineListCache(machineAction);
	if (isMachineListCacheUpdated) {
		/**
		 * refresh the machine list from cache as we know the shutdown completion status and cache is the most updated status of the machine, so we can ignore the network call
		 */
		const isMachineRefresh = refreshMachinesFromCache();
		if (isMachineRefresh && !sessionRefreshFlag.current) {
			onRefresh(true);
			sessionRefreshFlag.current = true;
		}
	}
};
