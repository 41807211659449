import { environment } from 'Environment';
import { NativeStorageType } from 'Environment/storageUtils';

// allow-unused-export: for unit tests
export class NativeStorage {
	private readonly storageType: NativeStorageType;

	public constructor(storageType: NativeStorageType) {
		this.storageType = storageType;
	}

	protected stringify(value: any): string {
		return JSON.stringify(value);
	}

	protected parseToJson(value: string): any {
		return this.safeParseJson(value);
	}

	public async saveToNativeStorage(key: string, value: any): Promise<void> {
		if (this.storageType === NativeStorageType.Sync) {
			environment.sync_setItem(key, this.stringify(value));
		} else {
			await environment.setItem(key, this.stringify(value));
		}
	}

	public async getFromNativeStorage(key: string): Promise<any> {
		const value =
			this.storageType === NativeStorageType.Sync
				? environment.sync_getItem(key)
				: await environment.getItem(key);
		return this.parseToJson(value);
	}

	public async removeFromNativeStorage(key: string): Promise<void> {
		if (this.storageType === NativeStorageType.Sync) {
			environment.sync_setItem(key, null);
		} else {
			await environment.setItem(key, null);
		}
	}

	protected safeParseJson(str: string): any {
		try {
			return JSON.parse(str);
		} catch (e) {
			return str;
		}
	}
}
