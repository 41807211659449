import { LaunchResourceOptions } from 'Environment';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export type ExtensionCommunicationAdapter = (
	workload: any,
	type: BrowserExtensionMessageType
) => Promise<BrowserExtensionResponse>;

export interface BrowserExtensionMessage {
	header: {
		type: BrowserExtensionMessageType;
	};
	payload: {
		correlationId: string;
		[key: string]: any;
	};
}

export interface BrowserExtensionWrapperMessage {
	workload?: any;
	type: BrowserExtensionMessageType;
	timeout?: number;
	retries?: number;
}

export type BrowserExtensionMessageType =
	| 'icaLaunch'
	| 'leaseLaunch'
	| 'setCapabilities'
	| 'logOff'
	| 'initiateStore'
	| 'getStoreHealth'
	| 'getLeasingProperties'
	| 'notifyAuthenticationTimer'
	| 'getAADSSOInfo'
	| 'sendAADSSOAccessToken'
	| 'version'
	| 'getAndResetShieldStateInformationForAnalytics';

export type AuthenticationTimerOperationType = 'start' | 'stop';

export interface BrowserExtensionResponse {
	status: string;
	errorCode?: number;
	errorReason?: BrowserExtensionErrorReason;
	result?: any;
	correlationId?: string;
}

export enum BrowserExtensionErrorReason {
	Unavailable = 'Unavailable',
	TimeOut = 'TimeOut',
	Unsuccessful = 'Unsuccessful',
	PipeRenegotiated_TryAgain = 'PipeRenegotiated_TryAgain',
	Chrome_Runtime_SendMessage_Failure = 'Chrome_Runtime_SendMessage_Failure',
	Chrome_Runtime_Not_Available = 'Chrome_Runtime_Not_Available',
}

export interface BrowserExtensionLaunchResourceOptions extends LaunchResourceOptions {
	onIcaDownload: (resource: Resource) => void;
}

export type AADSSOAuthReqPayload = {
	resourceId: string;
	transactionId: string;
	correlationId: string;
	azureDeviceId: string;
	accessToken: string;
	clientNonce: string;
};
