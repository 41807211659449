import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { appsPagePath } from 'App/Apps';

export const useUserAppsPath = () => {
	const location = useLocation();
	const [userAppsPath, setUserAppsPath] = useState(`${appsPagePath}/All`);

	useLayoutEffect(() => {
		if (location.pathname.startsWith(appsPagePath)) {
			setUserAppsPath(location.pathname);
		}
	}, [location.pathname]);

	return userAppsPath;
};
