import { t } from '@citrite/translate';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent, trackEvent } from 'analytics';
import { logError } from 'remoteLogging';
import { v4 } from 'uuid';
import { environment } from 'Environment';
import { getCVADAppIconDataUrl } from 'Environment/getCVADAppIconDataUrl';
import { HTML5Client } from 'Environment/launchResource/html5Client';
import { errorWithCopyTransactionIdButtonforHtml5LeaseLaunch } from 'Environment/launchResource/launchResourceErrors';
import { parseIcaData } from 'Environment/launchResource/resourceLaunch';
import { detectLanguage } from 'javascript/sf/detectLanguage';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { leaseCallHomeInfo } from 'LeaseWorkflow/CallHomeService';
import { ClmFunctionWrap } from 'LeaseWorkflow/WasmFunctionWrap';
import { traceManager } from 'Tracing';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { ShieldHealthCheckPayload } from 'Workspace/TelemetryEvents/shieldHealthCheck/ShieldHealthCheckPayload';

interface LeaseLaunchPropeties {
	sessionWindow: Window;
	isVsrReceived: boolean;
	isFinalIcaReceived: boolean;
	leaseLaunchId: number;
	iconUrl: string;
	uiLocale: string;
}

interface EventHubData {
	customerId?: string;
	eventHubToken?: string;
	eventHubEndpoint?: string;
	expiry?: string;
}

const interfaceConsumedByClmWasm = {
	miniIcaGeneratedHandler: 'miniIcaGenerated',
	finalIcaGeneratedHandler: 'finalIcaGenerated',
	clErrorHandler: 'HandleCLError',
};

const correlationIdLeaseLauncPropertieshMap = new Map<string, LeaseLaunchPropeties>();
let clmWasmModule: Object = null;
let workspaceConfiguration: WorkspaceConfiguration = null;
let launchSessionViaCLFunc: Function = null;
let handleValidateSessionResultFunc: Function = null;
let clxmtpEndpointPublicKeyStr: string = null;
let casEventHubData: EventHubData = null;
self[interfaceConsumedByClmWasm.miniIcaGeneratedHandler] = miniIcaGeneratedHandler;
self[interfaceConsumedByClmWasm.finalIcaGeneratedHandler] = finalIcaGeneratedHandler;
self[interfaceConsumedByClmWasm.clErrorHandler] = clErrorHandler;

export async function initConnectionLeaseManager() {
	//clmgr is Connection Lease Manager, It is used to mount indexedDB, and convert lease file to ica file for lease launch
	const { default: clmModule } = await import('@shieldh5/clmgr');
	clmWasmModule = await Promise.resolve(clmModule());
	launchSessionViaCLFunc = clmWasmModule['cwrap'](
		...ClmFunctionWrap.launchSessionViaCLFuncWrap
	);
	handleValidateSessionResultFunc = clmWasmModule['cwrap'](
		...ClmFunctionWrap.handleValidateSessionResultWrap
	);
	clmWasmModule[ClmFunctionWrap.mountIDBFSWrap]();
	clmWasmModule[ClmFunctionWrap.initCLMWrap]();
}

export function resyncShieldLeaseFiles() {
	clmWasmModule[ClmFunctionWrap.reSyncIDBFSWrap]();
}

async function getIconDataUrl(resource: Resource): Promise<string> {
	let iconUrl = '';
	try {
		iconUrl = await getCVADAppIconDataUrl(resource);
	} catch (error) {
		logError(error);
	}
	return iconUrl;
}
export function setEventHubData(data: {
	customerId?: string;
	eventHubToken?: string;
	eventHubEndpoint?: string;
	expiry?: string;
}) {
	casEventHubData = data;
}

export async function launchSessionViaCL(
	resource: Resource,
	config: WorkspaceConfiguration,
	targetWindow: Window
) {
	trackAnalyticsEvent(ShieldHealthCheckPayload.createHealthCheckCLMLaunchStartPayload());
	traceManager.logInfo(
		`CLMSerivce: Launching resource ${resource.name} via Connection Lease Manager`
	);
	const connectionLeaseRequestData = prepareConnectionLeaseRequest(resource);
	const connectionLeaseRequest = JSON.stringify(connectionLeaseRequestData);
	workspaceConfiguration = config;
	try {
		const leaseLaunchProperties: LeaseLaunchPropeties = {
			sessionWindow: !!targetWindow ? targetWindow : null,
			isFinalIcaReceived: null,
			isVsrReceived: null,
			leaseLaunchId: null,
			iconUrl: await getIconDataUrl(resource),
			uiLocale: detectLanguage(),
		};
		correlationIdLeaseLauncPropertieshMap.set(
			connectionLeaseRequestData.correlationid,
			leaseLaunchProperties
		);
		traceManager.logInfo(
			'CLMSerivce: Invoking launchSessionViaCL API with connectionLeaseRequest data',
			{ connectionLeaseRequestData }
		);
		await launchSessionViaCLFunc(connectionLeaseRequest);
		return Promise.resolve();
	} catch (error) {
		trackEvent('html5LeaseLaunchFailed');
		return Promise.reject(new Error('Launch Session via clm wasm fails.'));
	}
}

function miniIcaGeneratedHandler(data: Object) {
	trackAnalyticsEvent(
		ShieldHealthCheckPayload.createHealthCheckCLMLaunchMiniIcaGeneratedPayload()
	);
	triggerCLXMTPConnectionInIFrame(data);
}

function finalIcaGeneratedHandler(data: Object) {
	trackAnalyticsEvent(
		ShieldHealthCheckPayload.createHealthCheckCLMLaunchFinalIcaGeneratedPayload()
	);
	data['clxmtpEndpointPublicKeyStr'] = clxmtpEndpointPublicKeyStr;
	const correlationId = JSON.parse(data['jsonParamData'])['CLXMTP_CorrelationId'];
	traceManager.logInfo(
		`CLMSerivce: finalIcaGenerated for correlationId ${correlationId}`
	);
	const leaseLaunchPropeties = correlationIdLeaseLauncPropertieshMap.get(correlationId);
	const sessionWindowInstance = leaseLaunchPropeties?.sessionWindow;
	data['iconUrl'] = leaseLaunchPropeties?.iconUrl;
	data['uiLocale'] = leaseLaunchPropeties?.uiLocale;
	data['eventHubData'] = casEventHubData;
	if (!!sessionWindowInstance) {
		sessionWindowInstance.postMessage(
			{
				cmd: 'FinalIcaGenerated',
				data,
			},
			sessionWindowInstance.location.origin
		);
		const html5Client = new HTML5Client(sessionWindowInstance);
		html5Client.sendSuccess(data['icafile'], leaseLaunchPropeties?.leaseLaunchId);
		traceManager.logInfo(
			`CLMSerivce: Final ica content sent to html5 client for ${correlationId}, leaseLaunchId: ${leaseLaunchPropeties?.leaseLaunchId}`
		);
		if (leaseLaunchPropeties?.isVsrReceived) {
			correlationIdLeaseLauncPropertieshMap.delete(correlationId);
		} else {
			correlationIdLeaseLauncPropertieshMap.set(correlationId, {
				...leaseLaunchPropeties,
				isFinalIcaReceived: true,
			});
		}
	} else {
		traceManager.logInfo(
			`CLMSerivce: Session window is not available to send final ica data to html5 client for ${correlationId}, leaseLaunchId: ${leaseLaunchPropeties?.leaseLaunchId}`
		);
	}
}

interface CLErrorHandlerData {
	transactionID?: string;
	errorCode?: string;
}

function clErrorHandler(data: CLErrorHandlerData) {
	const transactionId = data['transactionID'];
	const errorCode = data['errorCode'];
	trackAnalyticsEvent(
		ShieldHealthCheckPayload.createHealthCheckCLMLaunchErrorPayload({
			transactionId,
			errorCode,
		})
	);
	traceManager.logError('CLMSerivce: clErrorHandler invoked with data', {
		additionalContext: data,
	});

	// close spinnder window if any for lease launch failure
	if (errorCode !== '0') {
		correlationIdLeaseLauncPropertieshMap.get(transactionId)?.sessionWindow?.close();
	}
	// show error code and message
	const errorCodeStr = errorCode.toString();
	if (errorCodeStr !== '0') {
		let errorText = t(`Workspace:lease_launch_failures.${errorCodeStr}`);
		if (errorText === 'lease_launch_failures.' + errorCodeStr) {
			errorText = '';
		}
		errorWithCopyTransactionIdButtonforHtml5LeaseLaunch(
			errorText,
			errorCodeStr,
			transactionId
		);
	}
	correlationIdLeaseLauncPropertieshMap.delete(transactionId);
}

class SessionWindowMessageHandler {
	public channel: BroadcastChannel;

	public constructor(id: string) {
		const channel_Id = 'channel_' + id;
		this.channel = new BroadcastChannel(channel_Id);
		this.channel.onmessage = this.broadcastMessageHandler.bind(this);
	}

	private broadcastMessageHandler(event: any) {
		if (event.data['cmd'] === 'HandleValidateSessionResult') {
			const correlationId = event.data['correlationId'];
			traceManager.logInfo(`CLMSerivce: VSR received for ${correlationId}`);
			this.handleValidateSessionResult(event.data);
			const leaseLaunchPropeties =
				correlationIdLeaseLauncPropertieshMap.get(correlationId);
			const sessionWindowInstance = leaseLaunchPropeties?.sessionWindow;
			sessionWindowInstance?.postMessage(
				{ cmd: 'VSRGenerated' },
				sessionWindowInstance.location.origin
			);
			if (leaseLaunchPropeties?.isFinalIcaReceived) {
				correlationIdLeaseLauncPropertieshMap.delete(correlationId);
			} else {
				correlationIdLeaseLauncPropertieshMap.set(correlationId, {
					...leaseLaunchPropeties,
					isVsrReceived: true,
				});
			}
		}
	}

	private handleValidateSessionResult(data: Object) {
		//data struct: {"cl_sessionid": 0, "vsrEpkGctData": {"buffer": uint8array, "bufferLen": 30, "clxmtp_endpointpublickeystr": "mock"}}
		trackAnalyticsEvent(
			ShieldHealthCheckPayload.createHealthCheckCLMLaunchHandleVSRResultPayload()
		);
		const cl_sessionid = data['cl_sessionid'];
		const vsrEpkGctData = data['vsrEpkGctData'];
		if (vsrEpkGctData) {
			clxmtpEndpointPublicKeyStr = vsrEpkGctData['clxmtp_endpointpublickeystr'];
			const vsrDataLen = vsrEpkGctData['bufferLen'];
			const vsrDataBuffer = vsrEpkGctData['buffer'];
			const buf = clmWasmModule[ClmFunctionWrap.mallocWrap](
				vsrDataLen * Uint8Array.BYTES_PER_ELEMENT
			);
			clmWasmModule[ClmFunctionWrap.HEAPU8Wrap][ClmFunctionWrap.setWrap](
				vsrDataBuffer,
				buf
			);
			traceManager.logInfo(
				`CLMSerivce: invoking handleValidateSessionResultFunc with vsrDataLen: ${vsrDataLen}, cl_sessionid: ${cl_sessionid}`
			);
			handleValidateSessionResultFunc(buf, vsrDataLen, cl_sessionid);
			clmWasmModule[ClmFunctionWrap.freeWrap](buf);
		} else {
			traceManager.logInfo(
				`CLMSerivce: invoking handleValidateSessionResultFunc with vsrDataLen: 0, cl_sessionid: ${cl_sessionid}`
			);
			handleValidateSessionResultFunc(null, 0, cl_sessionid);
		}
	}
}

function triggerCLXMTPConnectionInIFrame(data: Object) {
	try {
		const correlationId = JSON.parse(data['jsonParamData'])['correlationId'];
		traceManager.logInfo(
			`CLMSerivce: received miniIca data triggering CLXMTP connection for connectionLeaseId: ${correlationId}`
		);
		const leaseLaunchId = new Date().getTime();
		const leaseLaunchIdStr = leaseLaunchId.toString();
		const miniIcaFileData = parseIcaData(data['miniIcaFileStr']);
		miniIcaFileData['channelId'] = leaseLaunchIdStr;
		data['miniIcaFileData'] = miniIcaFileData;

		const url =
			environment.store.baseUri +
			workspaceConfiguration.pluginAssistant.html5.launchURL +
			'?launchid=' +
			leaseLaunchIdStr;

		const leaseLaunchPropeties = correlationIdLeaseLauncPropertieshMap.get(correlationId);
		let sessionWindowHandle = leaseLaunchPropeties?.sessionWindow;
		if (!!sessionWindowHandle) {
			traceManager.logInfo(
				`CLMSerivce: Reusing existing session window for connectionLeaseId: ${correlationId}, leaseLaunchId: ${leaseLaunchId}`
			);
			sessionWindowHandle.location.assign(url);
		} else {
			traceManager.logInfo(
				`CLMSerivce: Creating a new session window for connectionLeaseId: ${correlationId}, leaseLaunchId: ${leaseLaunchId}`
			);
			sessionWindowHandle = window.open(url);
		}
		correlationIdLeaseLauncPropertieshMap.set(correlationId, {
			...leaseLaunchPropeties,
			sessionWindow: sessionWindowHandle,
			leaseLaunchId,
		});
		traceManager.logInfo(
			`CLMSerivce: correlationIdLeaseLauncPropertieshMap: ${JSON.stringify(
				correlationIdLeaseLauncPropertieshMap
			)}`
		);
		data['iconUrl'] = leaseLaunchPropeties?.iconUrl;
		data['uiLocale'] = leaseLaunchPropeties?.uiLocale;
		data['eventHubData'] = casEventHubData;
		new SessionWindowMessageHandler(leaseLaunchIdStr);
		waitForHtml5ConfigCompleted(sessionWindowHandle, data, 30);
	} catch (error) {
		logError(error, {
			tags: {
				feature: 'html5-shield',
			},
		});
	}
}

function prepareConnectionLeaseRequest(resource: Resource) {
	if (!leaseCallHomeInfo.leaseFilePath) {
		const wsuiConfig = getFromLocalStorage(
			leaseCallHomeInfo.WSUI_LAST_KNOWN_CONFIGURATION
		);
		const userDetails = getFromLocalStorage(leaseCallHomeInfo.LAST_USER_DETAILS);
		leaseCallHomeInfo.leaseFilePath =
			wsuiConfig[leaseCallHomeInfo.CONF_ITEM_STORE_IDENTIFIERS][
				leaseCallHomeInfo.CONF_ITEM_STORE_GUID
			] +
			'/' +
			userDetails['userId'] +
			'/';
	}
	const connectionLeaseSyncFolder =
		'/CL_ROOT/ConnectionLeases/' +
		leaseCallHomeInfo.leaseFilePath.substring(
			0,
			leaseCallHomeInfo.leaseFilePath.length - 1
		);
	return {
		resourcekey: resource.clmetadata.resourcekey,
		resourceid: resource.id,
		correlationid: v4(),
		launchpreference: resource.clmetadata.launchpreference,
		displayname: resource.name,
		clsyncfolder: connectionLeaseSyncFolder,
	};
}

function waitForHtml5ConfigCompleted(
	sessionWindowHandle: Window,
	data: Object,
	secondsWaited: number
) {
	const intervalTimer = setInterval(() => {
		try {
			if (sessionWindowHandle.closed) {
				clearInterval(intervalTimer);
			} else if (!!sessionWindowHandle['HTML5_CONFIG']) {
				traceManager.logInfo(
					`CLMService: HTML5_CONFIG is available for location ${sessionWindowHandle.location}, setting shield information`
				);
				sessionWindowHandle['HTML5_CONFIG']['ShieldJsonParam'] = data;
				sessionWindowHandle.postMessage(
					{ cmd: 'START_CLXMTP_SESSION' },
					self.location.origin
				);
				clearInterval(intervalTimer);
			} else if (secondsWaited <= 0) {
				throw new Error('session window is not initialized sucessfully');
			} else {
				secondsWaited--;
			}
		} catch (error) {
			clearInterval(intervalTimer);
			throw error;
		}
	}, 500);
}
