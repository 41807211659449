import {
	MACHINE_LIST_CACHE_KEY,
	MachineListResponseData,
} from 'App/Activity/Network/MachineList';
import { Machine, MachineData, MachineError } from 'App/Activity/ResourceManagerContext';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';

type MachineIdentifier = {
	machineId?: string;
	resourceId?: string;
};

type MachineUpdateData = Partial<MachineData>;

//allow-unused-export
export class MachineStateManager {
	private getCacheData(): MachineListResponseData {
		return getFromLocalStorage(MACHINE_LIST_CACHE_KEY) || { machines: [] };
	}

	private saveCacheData(data: MachineListResponseData): void {
		setInLocalStorage(MACHINE_LIST_CACHE_KEY, data);
	}

	private isValidMachineIdentifier(identifier: MachineIdentifier): boolean {
		return !!(identifier?.machineId || identifier?.resourceId);
	}

	private isValidCacheData(cachedData: MachineListResponseData): boolean {
		return !!cachedData?.machines?.length;
	}

	private machineIdMatches(
		identifier: MachineIdentifier,
		machine: Machine | MachineError
	): boolean {
		const isDefined = (val: string) => !!val;
		const isSame = (mId: string, rId: string) => mId === rId;
		const matchesResourceId =
			isDefined(machine?.resourceId) &&
			isSame(machine.resourceId, identifier?.resourceId);
		const matchesMachineId =
			isDefined(machine?.machineData?.machineId) &&
			isSame(machine.machineData?.machineId, identifier?.machineId);
		return matchesMachineId || matchesResourceId;
	}

	private getUpdatedMachineObject(
		machine: Machine | MachineError,
		updateData: MachineUpdateData
	): Machine | MachineError {
		return {
			...machine,
			machineData: machine.machineData
				? {
						...machine.machineData,
						...updateData,
						lastUpdatedTime: new Date().toISOString(),
				  }
				: undefined,
		};
	}

	/**
	 * Find a machine by either machine ID or resource ID
	 * @param identifier Object containing machineId or resourceId
	 * @returns The matching machine or null if not found
	 */
	public getMachine(identifier: MachineIdentifier): Machine | MachineError | null {
		if (!this.isValidMachineIdentifier(identifier)) {
			return null;
		}
		const cachedData = this.getCacheData();
		if (!this.isValidCacheData(cachedData)) {
			return null;
		}

		return (
			cachedData.machines.find(machine => this.machineIdMatches(identifier, machine)) ||
			null
		);
	}

	/**
	 * Update a machine in the cache by either machine ID or resource ID
	 * @param identifier Object containing machineId or resourceId
	 * @param updateData Data to update in the machine
	 * @returns Boolean indicating if update was successful
	 */

	public updateMachine(
		identifier: MachineIdentifier,
		updateData: MachineUpdateData
	): boolean {
		if (!this.isValidMachineIdentifier(identifier)) {
			return false;
		}
		const cachedData = this.getCacheData();
		if (!this.isValidCacheData(cachedData)) {
			return false;
		}
		let machineFound = false;
		const updatedMachines = cachedData.machines.map((machine: Machine | MachineError) => {
			if (this.machineIdMatches(identifier, machine)) {
				machineFound = true;
				return this.getUpdatedMachineObject(machine, updateData);
			}
			return machine;
		});
		if (machineFound) {
			this.saveCacheData({ machines: updatedMachines });
			return true;
		}
		return false;
	}

	/**
	 * Get all machines from cache
	 * @returns Array of all machines in cache
	 */
	public getAllMachines(): MachineListResponseData {
		return this.getCacheData();
	}

	/**
	 * Update the entire machine list cache
	 * @param machines New machine list to set in cache
	 */
	public updateMachineList(machines: MachineListResponseData): void {
		this.saveCacheData(machines);
	}
}

export const machineListCache = new MachineStateManager();
