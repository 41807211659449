import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { platform } from 'Environment/launchResource/constants';
import {
	getCurrentPlatform,
	isCitrixChromeApp,
	isNativeClient,
} from 'Environment/launchResource/device';
import { isGatewaySession } from 'Login/DSAuth/authentication';

export function isPerformanceOptimizationEnabled(
	workspaceConfiguration: WorkspaceConfiguration,
	flagMap: Record<string, string>
): boolean {
	const isGlobalFeatureFlagEnabled = hasFeatureCanary(
		workspaceConfiguration,
		flagMap.feature
	);
	return (
		IS_ON_PREM ||
		(isGlobalFeatureFlagEnabled &&
			(!isNativeClient() ||
				isFeatureEnabledOnNativePlatform(workspaceConfiguration, flagMap)))
	);
}

function isFeatureEnabledOnNativePlatform(
	workspaceConfiguration: WorkspaceConfiguration,
	flagMap: Record<string, string>
): boolean {
	const currentPlatformFlag = flagMap[getCurrentPlatform()];
	return currentPlatformFlag
		? hasFeatureCanary(workspaceConfiguration, currentPlatformFlag)
		: false;
}

export const staleWhileRevalidateStrategyFlagRecord: Record<string, string> = {
	feature: FeatureFlag.EnableStaleWhileRevalidate,
	[platform.windows]: FeatureFlag.EnableStaleWhileRevalidateForWindows,
	[platform.mac]: FeatureFlag.EnableStaleWhileRevalidateForMac,
};

export const getUserDetailsFromCacheFlagRecord: Record<string, string> = {
	feature: FeatureFlag.EnablePrefetchUserDetailsFromCache,
	[platform.windows]: FeatureFlag.EnablePrefetchUserDetailsFromCacheForWindows,
	[platform.mac]: FeatureFlag.EnablePrefetchUserDetailsFromCacheForMac,
};

export const shouldNativeAppBeMandated = (
	workspaceConfiguration: WorkspaceConfiguration
) => {
	return (
		!isNativeClient() &&
		!isCitrixChromeApp() &&
		workspaceConfiguration?.pluginAssistant?.requireNativeAppUse?.toLowerCase() === 'true'
	);
};

/*
  For WSUI Onpremise use cases like GACs, we are required to make network calls 
  to gacs the api-endpoints which are different from the store-url\gateway-url.
  In cases when WSUI is running under NSG, the isGatewaySessionExpired will assume
  that gateway session is expired and will reload the page based on the response
  which was never sent by the gateway. To avoid this, we are checking if the request
  was made to the gateway api-endpoint or not. 
*/
// allow-unused-export: for unit test
export function isRemoteApiCall(config: AxiosRequestConfig): boolean {
	try {
		const requestUrl = new URL(config?.url);
		const isApiHostSameAsCurrentHost = requestUrl?.host === window.location.host;
		return !isApiHostSameAsCurrentHost;
	} catch (e) {}
	return false;
}

const citrixApplicationHeaderKey = 'x-citrix-application';
const citrixApplicationHeader = 'Receiver for Web';

export function isGatewaySessionExpired(
	result: AxiosResponse,
	requestConfig: AxiosRequestConfig
): boolean {
	if (
		IS_ON_PREM &&
		isSuccessfulResponse(result) &&
		missingCitrixApplicationHeader(result) &&
		isGatewaySession()
	) {
		return !isRemoteApiCall(requestConfig);
	}

	return false;
}

function isSuccessfulResponse(result: AxiosResponse): boolean {
	return result.status === 200 && !!result.headers;
}

function missingCitrixApplicationHeader(result: AxiosResponse): boolean {
	const citrixApplicationHeaderValue = result.headers?.[citrixApplicationHeaderKey];
	return (
		citrixApplicationHeaderValue?.toLowerCase() !== citrixApplicationHeader.toLowerCase()
	);
}
