import { NativeStorage } from 'Environment/NativeStorage';
import { NativeStorageType } from 'Environment/storageUtils';

/*
  	The native storage functions on Linux dont work correctly when the strings given for storage
	containg escape characters. This so we convert tyhe string to base64 before storing it in the
	storage and then convert it back to string when we retrieve it.
*/
// allow-unused-export: for unit tests
export class LinuxOnpremNativeStorage extends NativeStorage {
	public constructor(storageType: NativeStorageType) {
		super(storageType);
	}

	protected stringify(value: any): string {
		const strJson = JSON.stringify(value);
		try {
			return btoa(strJson);
		} catch (e) {
			return strJson;
		}
	}

	protected parseToJson(value: string): any {
		try {
			return super.safeParseJson(atob(value));
		} catch (e) {
			return value;
		}
	}
}
