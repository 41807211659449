import * as React from 'react';
import { Nullable } from '@citrite/utility-types';
import {
	PowerManagementControls,
	UserAccountData,
	UserPreferences,
} from '@citrite/workspace-ui-platform';

export interface Loadable<T> {
	loading: boolean;
	value: Nullable<T>;
}

export interface UserBranding {
	branding: Nullable<UserBrandingFormat>;
}

export interface UserBrandingFormat {
	name: string;
	backgroundColor: string;
	headerLogo: Nullable<string>;
	logonLogo: Nullable<string>;
	textColor: string;
	linkColor: string;
}

export type UserDetailsLoader = Promise<void> | null;

export interface UserContext {
	userDetails: UserAccountData;
	userPreferences: UserPreferences;
	userBranding: Loadable<UserBranding>;
	/**
	 * Initial value will be null
	 */
	isValidatedReturnUser: boolean | null;
	/**
	 * Initial value will be null
	 */
	isIWSEnabled: boolean | null;
	isDaasVisionEnabled: boolean | null;
	isActivityManagerEnabled: boolean | null;
	powerManagementControls: PowerManagementControls | null;
	error: any;
	loading: boolean;
	clear(): void;
	update(
		context: Partial<
			Pick<
				UserContext,
				| 'userDetails'
				| 'userPreferences'
				| 'userBranding'
				| 'isIWSEnabled'
				| 'isDaasVisionEnabled'
				| 'isActivityManagerEnabled'
				| 'error'
				| 'isValidatedReturnUser'
				| 'userDetailsLoader'
				| 'powerManagementControls'
			>
		>
	): void;
	logOff(sessionExpired?: boolean): void;
	/**
	 * UserDetailsLoader is promise which will be resolved when userDetails are loaded. This is needed for Encryption mechanism to work as its relies on userThumbprint which is part of userDetails.
	 */
	userDetailsLoader: UserDetailsLoader;
	hasLoggedIn(): Promise<boolean>;
}

const defaultContext: UserContext = {
	userDetails: null,
	userPreferences: null,
	userBranding: { loading: true, value: null },
	isValidatedReturnUser: false,
	isIWSEnabled: false,
	isDaasVisionEnabled: true,
	isActivityManagerEnabled: false,
	powerManagementControls: null,
	error: false,
	loading: true,
	clear: () => null,
	update: () => null,
	logOff: () => null,
	userDetailsLoader: null,
	hasLoggedIn: () => Promise.resolve(false),
};

export const Context = React.createContext<UserContext>(defaultContext);
